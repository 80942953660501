import React from 'react';
import { Routes } from '../routes';
import { getRoles } from '../Utils/session';
import { Roles } from './Roles'
import { DeviceOrgTable, DeviceBrandTable } from '../Pages/Content/Device/DeviceTable';
import { DeviceOrgReportTab, DeviceBrandReportTab } from '../Pages/Content/Device/DeviceReportTab';
import UploadTable from '../Pages/Content/Device/UploadTable';
import LogDataTable from '../Pages/Content/Device/LogDataTable';
import AbnormalDataTable from '../Pages/Content/Device/AbnormalDataTable';
import { PublicRootRecipeTable, PrivateRootRecipeTable, HistoryRootRecipeTable, PublicRecipeTable, PrivateRecipeTable, HistoryRecipeTable } from '../Pages/Content/Recipe/RecipeTable';
import BranchTable from '../Pages/Content/Branch/BranchTable';
import { GeneralCustomerTable, AdvancedCustomerTable } from '../Pages/Content/Customer/CustomerTable';
import AccountBrandTable from '../Pages/Content/Account/AccountBrandTable';
import AccountOrgTable from '../Pages/Content/Account/AccountOrgTable';
import Report from '../Pages/Content/Report/Report';
import SalesReport from '../Pages/Content/Report/SalesReport';
import RecipeReport from '../Pages/Content/Report/RecipeReport';

import { PowerBIReportUrl } from '../CoffeeCloudConfig';
import ServiceListTable from '../Pages/Content/ServiceRequest/ServiceListTable';
import ServiceSiteTable from '../Pages/Content/ServiceRequest/ServiceSiteTable';
import LabTable from '../Pages/Content/Lab/LabTable';
import ChangePassword from '../Pages/Content/Setting/ChangePassword';
import NotificationManagementTable from '../Pages/Content/System/NotificationManagementTable';

const TabContent = (activeKey, Components, prop) => {
    return (<Components activeKey={activeKey} {...prop} />)
};

//分頁表單
const Tabs = {
    //Recipe 設定相關 Tab (原廠＆總店)
    RecipeTab: [
        {
            //原廠
            key: "recipe-root-public",
            title: "Public Recipe",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, PublicRootRecipeTable))
        },
        {
            //原廠
            key: "recipe-root-private",
            title: "Private Recipe",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, PrivateRootRecipeTable))
        },
        {
            //原廠
            key: "recipe-root-history",
            title: "History Recipe",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, HistoryRootRecipeTable))
        },
        {
            //原廠
            key: "recipe-root-report",
            title: "Report",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, Report, { url: PowerBIReportUrl.recipeOrgReport }))
        },
        {
            //總店
            key: "recipe-brand-public",
            title: "Public Recipe",
            requiredRoles: [Roles.Barista, Roles.BrandOperationMgr],
            content: (activeKey) => (TabContent(activeKey, PublicRecipeTable))
        },
        {
            //總店
            key: "recipe-brand-private",
            title: "Private Recipe",
            requiredRoles: [Roles.Barista, Roles.BrandOperationMgr],
            content: (activeKey) => (TabContent(activeKey, PrivateRecipeTable))
        },
        {
            //總店
            key: "recipe-brand-history",
            title: "History Recipe",
            requiredRoles: [Roles.Barista, Roles.BrandOperationMgr],
            content: (activeKey) => (TabContent(activeKey, HistoryRecipeTable))
        },
        {
            //總店
            key: "recipe-brand-report",
            title: "Report",
            requiredRoles: [Roles.Barista, Roles.BrandOperationMgr],
            content: (activeKey) => (TabContent(activeKey, RecipeReport)),
        },
    ],
    //Device 機台相關資訊 Tab (原廠＆總店＆Agent)
    DeviceTab: [
        {
            //總店
            key: "device-brand-device",
            title: "Device",
            requiredRoles: [Roles.DeviceAdmin, Roles.BranchAdmin, Roles.BrandOperationMgr, Roles.BrandAdmin],
            content: (activeKey) => (TabContent(activeKey, DeviceBrandTable))
        },
        {
            //總店
            key: "device-brand-report",
            title: "Report",
            requiredRoles: [Roles.DeviceAdmin, Roles.BranchAdmin, Roles.BrandOperationMgr, Roles.BrandAdmin],
            content: (activeKey) => (TabContent(activeKey, DeviceBrandReportTab))
        },
        {
            //原廠
            key: "device-root-device",
            title: "Device",
            requiredRoles: [Roles.RootAdmin, Roles.Agent],
            content: (activeKey) => (TabContent(activeKey, DeviceOrgTable))
        },
        {
            //原廠
            key: "device-root-report",
            title: "Report",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, DeviceOrgReportTab))
        },
        {
            //原廠
            key: "device-root-reportAgent",
            title: "Report",
            requiredRoles: [Roles.Agent],
            content: (activeKey) => (TabContent(activeKey, DeviceOrgReportTab, { isAgent: true }))
        },
        {
            //原廠
            key: "device-root-upload",
            title: "Firmware Upload",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, UploadTable))
        },
        {
            //原廠
            key: "device-root-logdata",
            title: "Log Data",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, LogDataTable))
        },
        {
            //原廠
            key: "device-root-abnormalData",
            title: "Abnormal Data",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, AbnormalDataTable))
        },
        {
            //原廠
            key: "device-root-deviceReport",
            title: "Device Report",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, Report, { url: PowerBIReportUrl.deviceReport }))
        },
    ],
    //分店設定 (總店)
    BranchTab: [
        {
            key: "branch-branch",
            title: "Branch",
            requiredRoles: [Roles.BrandOperationMgr, Roles.BrandAdmin],
            content: (activeKey) => (TabContent(activeKey, BranchTable))
        },
    ],
    //顧客清單 (原廠)
    CustomerTab: [
        {
            key: "customer-root-GeneralCustomerTable",
            title: "General Customer",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, GeneralCustomerTable))
        },
        {
            key: "customer-root -AdvancedCustomerTable",
            title: "Advanced Customer",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, AdvancedCustomerTable))
        },
    ],
    //Account 設定 (原廠)
    AdvancedCustomerTab: [
        {
            //原廠
            key: "advanced-customer-accountOrg",
            title: "Advanced Customer",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, AccountOrgTable))
        },
    ],
    //Account 設定 (總店)
    AccountTab: [
        {
            //總店
            key: "account-accountBrand",
            title: "Account",
            requiredRoles: [Roles.BrandOperationMgr, Roles.BrandAdmin],
            content: (activeKey) => (TabContent(activeKey, AccountBrandTable))
        },
    ],
    //Sales 銷售報表 (總店)
    ReportTab: [
        {
            key: "report-salesReport",
            title: "Sales Report",
            requiredRoles: [Roles.BrandOperationMgr],
            content: (activeKey) => (TabContent(activeKey, SalesReport))
        },
    ],
    //報修管理 (原廠＆Agent)
    ServiceRequestTab: [
        {
            key: "service-ServiceList",
            title: "Service List",
            requiredRoles: [Roles.RootAdmin, Roles.Agent],
            content: (activeKey) => (TabContent(activeKey, ServiceListTable))
        },
        {
            key: "service-SNListServiceSite",
            title: "SN List & Service Site",
            requiredRoles: [Roles.RootAdmin, Roles.Agent],
            content: (activeKey) => (TabContent(activeKey, ServiceSiteTable))
        },
    ],
    //實驗室輸入資料 (原廠)
    LabTab: [
        {
            key: "lab-Lab",
            title: "Lab",
            requiredRoles: [Roles.CoffeeLab, Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, LabTable))
        },
    ],
    //系統其他服務(原廠)
    SystemTab: [
        {
            key: "system-notificationManagement",
            title: "Notification Management",
            requiredRoles: [Roles.RootAdmin],
            content: (activeKey) => (TabContent(activeKey, NotificationManagementTable))
        },
    ],
    //設定服務 (All)
    SettingTab: [
        {
            key: "setting-ChangePassword",
            title: "ChangePassword",
            requiredRoles: Object.keys(Roles),//All
            content: (activeKey) => (TabContent(activeKey, ChangePassword))
        },
    ],
}

//All Menu HeaterItem
export const Items = [
    {
        HeaterItem: "Recipe",
        HeaterItemPath: Routes.Recipe.path,
        Tabs: Tabs.RecipeTab,
        display: true
    },
    {
        HeaterItem: "Device",
        HeaterItemPath: Routes.Device.path,
        Tabs: Tabs.DeviceTab,
        display: true
    },
    {
        HeaterItem: "Branch",
        HeaterItemPath: Routes.Branch.path,
        Tabs: Tabs.BranchTab,
        display: true
    },
    {
        HeaterItem: "Customer",
        HeaterItemPath: Routes.Customer.path,
        Tabs: Tabs.CustomerTab,
        display: true
    },
    {
        HeaterItem: "Account",
        HeaterItemPath: Routes.Account.path,
        Tabs: Tabs.AccountTab,
        display: true
    },
    {
        HeaterItem: "Advanced Customer",
        HeaterItemPath: Routes.Account.path,
        Tabs: Tabs.AdvancedCustomerTab,
        display: true
    },
    {
        HeaterItem: "Report",
        HeaterItemPath: Routes.Report.path,
        Tabs: Tabs.ReportTab,
        display: true
    },
    {
        HeaterItem: "Service Request",
        HeaterItemPath: Routes.ServiceRequest.path,
        Tabs: Tabs.ServiceRequestTab,
        display: true
    },
    {
        HeaterItem: "Lab",
        HeaterItemPath: Routes.Lab.path,
        Tabs: Tabs.LabTab,
        display: true
    },
    {
        HeaterItem: "System",
        HeaterItemPath: Routes.System.path,
        Tabs: Tabs.SystemTab,
        display: true
    },
    {
        HeaterItem: "",
        HeaterItemPath: Routes.Setting.path,
        Tabs: Tabs.SettingTab,
        display: false
    },
]

export const GetMenuItems = () => {
    const roles = getRoles();

    if (roles === null) {
        return [];
    }

    let menu = [];
    Items.forEach(item => {
        let tabs = [];

        // check sub menu permission
        item.Tabs.forEach(tab => {
            if (tab.requiredRoles.some(role => roles.indexOf(role) >= 0)) {
                tabs.push(tab);
            }
        });

        // if we have sub menu items, add sub menu to main menu
        if (tabs.length > 0) {
            menu.push({
                HeaterItem: item.HeaterItem,
                HeaterItemPath: item.HeaterItemPath,
                Tabs: tabs,
                display: item.display,
            });
        }
    });
    console.debug('menu:', menu);

    return roles === null ? [] : menu;
};

export default GetMenuItems;
