import React, { useState } from 'react';
import { Layout, Row, Col, Button, Form, Modal } from 'antd';
import { PasswordInput, NewPasswordInput, ConfirmPasswordInput } from '../../../Components/PasswordInput';
import * as ApiProvider from '../../../Api/ApiProvider';

const { Content } = Layout;

function success() {
    Modal.success({
        closeIcon: true,
        title: <div style={{ textAlign: 'center', color: '#0A496A', fontSize: '22px', margin: '10px 0px' }}>Updated Successful</div>,
        icon: <></>,
        content: <div style={{ textAlign: 'center', color: '#707070', fontSize: '18px', margin: '10px 0px' }}>You have successfully updated your password</div>,
        okText: 'Close',
        cancelText: '',
        width: 600,
        centered: true
    });
}

function error(msg) {
    Modal.error({
        closeIcon: true,
        title: <div style={{ textAlign: 'center', color: '#A24F62', fontSize: '22px', margin: '10px 0px' }}>Fail</div>,
        content: <div style={{ textAlign: 'center', color: '#707070', fontSize: '18px', margin: '10px 0px' }}>{msg}</div>,
        okText: 'Close',
        cancelText: '',
        width: 600,
        centered: true
    });
}

const formlayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
    style: { padding: '60px 40px 10px 40px', float: 'center', height: '100vh', width: '100%' }
};

function ChangePassword(props) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);
        ApiProvider.ChangePassword({ oldPassword: values.oldPassword, newOnePassword: values.newPassword, newSecondPassword: values.confirmPassword })
            .then((result) => {
                success()
                form.resetFields();
                setLoading(false);
            })
            .catch(err => {
                error(err.response.data.msg)
                setLoading(false);
            });
    };

    return (
        <Layout>
            <Content>
                <Row >
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} >
                        <Form
                            form={form}
                            requiredMark={false}
                            size='middle'
                            onFinish={onFinish}
                            {...formlayout}
                        >
                            <PasswordInput
                                name="oldPassword"
                                label="Please enter your old password" />
                            <NewPasswordInput
                                name="newPassword"
                                label="Please enter your new password" />
                            <ConfirmPasswordInput
                                name="confirmPassword"
                                label="Please confirm your new password"
                                checkname="newPassword"
                            />
                            <Form.Item style={{ fontSize: '22px', color: '#404040', margin: '50px 0 0 0 ' }} >
                                <Button type="primary" htmlType="submit" loading={loading} >Send</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={15} xl={15}>

                    </Col>
                </Row>
            </Content>
        </Layout>);
}

export default ChangePassword;