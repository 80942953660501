import React, { useState, useEffect } from 'react';
import { Modal, Button, Descriptions, Form } from 'antd';
import { isEmpty } from 'lodash';
import * as ApiProvider from '../../../Api/ApiProvider';
import { DescriptionsSelet } from '../../../Components/Descriptions';
import * as Message from '../../../Components/Message';

const NotificationManagementPush = ({ open, setOpen, setReload, selected, saveService, title }) => {
    const [loading, setloading] = useState(false);
    const [filteredOptions, setUserList] = useState([]);

    const [form] = Form.useForm();

    useEffect(() => {
        async function fetchData() {
            fetchAccountData()
        }
        fetchData();
    }, []);

    const fetchAccountData = () => {
        //等API
        ApiProvider.GetAccountList().then(res => {
            setUserList(res.data.map(i => ({
                value: i.accountId,
                label: i.account,
            })))
        });
    }

    useEffect(() => {
        form.resetFields();
        if (!isEmpty(selected)) {
            const fieldsValue = Deserialization(selected);
            form.setFieldsValue(fieldsValue)

        }
    }, [form, open, selected]);

    const handleCancel = () => {
        setOpen(false)
    };

    const Deserialization = (row) => {
        return (
            {
                ...row
            }
        );
    }
    const Serialization = (fieldValue) => {
        return (
            {
                ...selected,
                ...fieldValue

            });
    }

    const handleOk = async () => {
        try {
            await form.validateFields();

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return
        }
        const fieldValue = form.getFieldValue();

        setloading(true);
        async function saveData() {
            saveService(Serialization(fieldValue))
                .then(res => {
                    Message.success(`${title} success`)
                    setOpen(false);
                    setloading(false);
                    setReload(true)
                })
                .catch(err => {
                    Message.error({
                        message: err.response.data.msg,
                        placement: 'topRight'
                    })
                    setloading(false);
                });
        }
        saveData()
    };

    return (
        <Modal
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                <Button key="OK" type="primary" loading={loading} onClick={handleOk}  >Save</Button>,
            ]}
        >
            <Form
                form={form}
                requiredMark={false}
                size='middle'
                onFinish={handleOk}
            >
                <Descriptions title={title} layout="vertical" column={12} >
                    <Descriptions.Item span={6} label="*Push To">
                        <DescriptionsSelet
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                            showSearch
                            required={true}
                            name="pushAccount"
                            label="Push To"
                            list={filteredOptions}
                            dropdownMatchSelectWidth={false} />
                    </Descriptions.Item>
                    <Descriptions.Item span={6}>
                    </Descriptions.Item>
                </Descriptions>
            </Form>
        </Modal>
    )
}

export default NotificationManagementPush;