import React, {useEffect, useState} from 'react'
import machine_abnormal from '../../../image/DeviceIcons/ic_machine_abnormal.svg';
import machine_normal from '../../../image/DeviceIcons/ic_machine_normal.svg';
import machine_non_use from '../../../image/DeviceIcons/ic_machine_non_use.svg';
import error from '../../../image/DeviceIcons/ic_error.svg';
import * as ApiProvider from '../../../Api/ApiProvider';
import {Badge} from 'antd';

//[Device] 依 sn/abnormalList/connect 回傳咖啡機 Icon 顏色 紅/藍/灰
export const DeviceStatus = ({ sn, abnormal, connect }) => {
    return GetDeviceStatus(abnormal, connect)[1];
};

//[Branch] 依 sn 決定回傳包含狀態與機台數量的咖啡機 Icon
export const DeviceListStatus = ({ deviceIdList }) => {
    const [errorCount, setErrorCount] = useState(0);
    const [onlineCount, setOnlineCount] = useState(0);
    const [offlineCount, setOfflineCount] = useState(0);

    useEffect(() => {
        deviceIdList.forEach(sn => {
            // get device abnormal list
            (async () => {
                ApiProvider.GetDeviceAbnrmalList({ sn: sn })
                    .then(res => {
                        const abnormalList = res.data;
                        if (abnormalList.length > 0 || HasRedError(abnormalList)) {
                            setErrorCount(errorCount + 1);
                        } else {
                            ApiProvider.CheckDeviceIsOnline(sn)
                                .then(isOnlineResult => {
                                    if (isOnlineResult.data.connect) {
                                        setOnlineCount(onlineCount + 1);
                                    } else {
                                        setOfflineCount(offlineCount + 1);
                                    }
                                });
                        }
                    });
            })();
        });
    }, [deviceIdList]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <DeviceBadge Color={StatusColor.Error} count={errorCount}>{Status.RedErrorImg}</DeviceBadge>
            <DeviceBadge Color={StatusColor.Online} count={onlineCount}>{Status.OnlineImg}</DeviceBadge>
            <DeviceBadge Color={StatusColor.Offline} count={offlineCount}>{Status.OfflineImg}</DeviceBadge>
        </>
    );
};

const DeviceBadge = (props) => {
    return props.count === 0 ? <></> : <Badge count={props.count} size='small' style={{ backgroundColor: props.Color }} offset={[-10,6]}>{props.children}</Badge>
};

//[主邏輯] 依照 abnormalList, connect 判斷邏輯回傳 Status.RedError/Online/Offline
export const GetDeviceStatus = (abnormalList, connect) => {
    //先檢查是否有重大異常
    if (abnormalList.length > 0 && HasRedError(abnormalList)) {

        return [Status.RedError, Status.RedErrorImg];
    }
    //再檢查是否在線上
    return connect ? [Status.Online, Status.OnlineImg] : [Status.Offline, Status.OfflineImg];
};

//依 abnormalList 決定是否顯示警示
export const DeviceStatusAlert = ({ abnormalList }) => {
    //檢查是否有重大異常
    return (abnormalList.length > 0 && !HasRedError(abnormalList)) ? Status.AlertImg : <></>;
};

//從不重複的 abnormalList 判斷是否存在重大異常(存在 RedError List 內)
export const HasRedError = (abnormalList) => {
    return GetDistinctErrorCodeList(abnormalList).filter(i => RedError.includes(i)).length > 0;
};

//傳入 abnormalList 回傳 result 整理過的資料 (array) (不重複)
export const GetDistinctErrorCodeList = (abnormalList) => {
    let list = [];
    abnormalList.forEach(i => {
        list = list.concat(i.result.split(';').filter(j => j !== ''))
    });
    return Array.from(new Set(list));
};

const StatusColor = {
    Error: '#A24F62',
    Offline: '#BFBFBF',
    Online: '#0A496A',
};

//狀態分類
export const Status = {
    //驚嘆號:有異常
    Alert: 'Alert',
    AlertImg: <img src={error} alt="error" />,
    //紅色機台:無論是否在線上，(曾/正在)發生重大異常
    RedError: 'Error',
    RedErrorImg: <img src={machine_abnormal} alt="Error" />,
    RedErrorIconImg: <svg width="2em" height="1.1em" fill={StatusColor.Error}> <circle cx="10" cy="10" r="5" /></svg>,
    //灰色機台:不在線上，無重大異常
    Offline: 'Offline',
    OfflineImg: <img src={machine_non_use} alt="Offline" />,
    OfflineIconImg: <svg width="2em" height="1.1em" fill={StatusColor.Offline}> <circle cx="10" cy="10" r="5" /></svg>,
    //藍色機台:連線中，無重大異常
    Online: 'Online',
    OnlineImg: <img src={machine_normal} alt="Online" />,
    OnlineIconImg: <svg width="2em" height="1.1em" fill={StatusColor.Online}> <circle cx="10" cy="10" r="5" /></svg>,
};

//重大異常 ErrorCode 列表
export const RedError = [
    'CoverError',
    'EswError',
    'GapVacuumError',
    'LiftMotorError',
    'LiftPlateformDownError',
    'LiftPlateformUpError',
    'LowerPotError',
    'PumpMotorError',
    'PumpMotorError',
    'StirMotorError',
    'UpperPotError',
];
