import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Select, Space } from 'antd';
import * as ApiProvider from '../Api/ApiProvider';
import { isEmpty } from 'lodash';
const { Option } = Select;

//Brand and Branch 連動下拉選單
const BrandandBranchDropdownList = ({ first, second }) => {
    const [firstValue, setFirstValue] = useState({ value: null });
    const [secondValue, setSecondValue] = useState({ value: null });
    const [firstDropdownDataList, setFirstDropdownDataList] = useState([]);
    const [secondDropdownDataList, setSecondDropdownDataList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            ApiProvider.GetBrandSelectItem()
                .then(res => {
                    setFirstDropdownDataList(res.data)
                });
        }
        fetchData()
    }, []);

    const handleFirstChange = value => {
        const id = value?.value;
        first(id)
        setFirstValue({ value: id })
        if (isEmpty(id)) {
            setFirstValue({ value: null })
            setSecondValue({ value: null })
            setSecondDropdownDataList([])
            return
        }
        async function fetchData() {
            ApiProvider.GetBranchSelectItemByBrand({ brandId: id })
                .then(res => {
                    setSecondDropdownDataList(res.data)
                });
        }
        fetchData()
    };

    const handleSecondChange = value => {
        setSecondValue({ value: value?.value })
        second(value?.value)
    };

    return (

        <Space>
            <div>
                <Select dropdownMatchSelectWidth={false} labelInValue allowClear={true} placeholder="Select a brand" onChange={handleFirstChange} value={firstValue}>
                    {firstDropdownDataList.map(data => (
                        <Option key={data.val} value={data.val}>{data.text}</Option>
                    ))}
                </Select>
            </div>
            <div>
                <Select dropdownMatchSelectWidth={false} labelInValue allowClear={true} placeholder="Select a branch" onChange={handleSecondChange} value={secondValue}>
                    {secondDropdownDataList.map(data => (
                        <Option key={data.val} value={data.val}>{data.text}</Option>
                    ))}
                </Select>
            </div>
        </Space>

    )
}
export default BrandandBranchDropdownList;