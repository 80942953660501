import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Modal, Space } from 'antd';
import { WelcomeContent } from './WelcomeContent';
import { Brand, Origin } from './WelcomeContentSetting';
import { getRoles, getUserName } from '../../Utils/session';

const Welcome = ({ open, setOpen }) => {
    const [count, setCount] = useState(1);
    const [data, setData] = useState([])
    useEffect(() => {
        setCount(1)
    }, [open])
    useEffect(() => {
        if (getRoles()?.includes('RootAdmin')) {
            setData(Origin)
        } else {
            setData(Brand)
        }

    }, [])
    return (
        <Modal
            bodyStyle={{ padding: '0px' }}
            footer={null}
            width={1100}
            open={open}
            onCancel={() => setOpen(false)}
        >
            <Row style={{ height: '100%', textAlign: 'center', backgroundColor: "#fffaf3" }}>
                <Col span={6} style={{ padding: '50px', textAlign: 'center', fontSize: '18px', color: 'white', backgroundColor: "#0A496A" }}>
                    <div>Welcome</div>
                    <div>{getUserName()}</div>
                    <div style={{ padding: '70px 0', fontSize: '30px' }}>Getting Started</div>
                </Col>
                <Col span={18} style={{ padding: '20px 30px' }}>
                    <WelcomeContent id={count} data={data}></WelcomeContent>
                    <Space style={{float: 'right' }}>
                        {/* <Button style={{ display: count > 1 ? '' : 'none'}} key="Previous" onClick={() => setCount(count - 1)}  >Previous</Button> */}
                        <Button style={{ display: count < data.length ? '' : 'none',width:'120px' }} key="Skip" onClick={() => setOpen(false)} >Skip</Button>
                        <Button style={{ display: count < data.length ? '' : 'none',width:'120px'}} key="Next" type="primary" onClick={() => setCount(count + 1)} >Next</Button>
                        <Button style={{ display: count === data.length ? '' : 'none' ,width:'120px'}} key="Close" type="primary" onClick={() => setOpen(false)} >Close</Button>
                    </Space>

                </Col>
            </Row>
        </Modal>
    );
}

export default Welcome;