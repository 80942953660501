import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Descriptions, Form, Input, Modal, notification} from 'antd';
import {isEmpty} from 'lodash';
import * as ApiProvider from '../../../Api/ApiProvider';
import {
    DescriptionsDetailsTitle,
    DescriptionsEmail,
    DescriptionsInput,
    DescriptionsSelet
} from '../../../Components/Descriptions';
import * as Message from '../../../Components/Message'
import {CountryStateCityFormSelector} from '../../../Components/CountryStateCitySelector';
import {AccountType, OriginalAccountTypeList} from '../../../CoffeeCloudConfig';
import {isPossiblePhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import {postcodeValidator, postcodeValidatorExistsForCountry} from "postcode-validator";


const AccountOrgDetails = ({ open, setOpen, setReload, selected, saveService, title, isEdit }) => {
    const [loading, setloading] = useState(false);
    const [openReason, setOpenReason] = useState(false);
    const [form] = Form.useForm();
    const [reasonForm] = Form.useForm();
    const formRef = useRef(null);
    const [countryMapping, setCountryMapping] = useState({});
    const [countryCode, setCountryCode] = useState("");
    const postCodeDefaultCountry = 'INTL';

    const ifEmptyOrNull = (value) => {
        return isEmpty(value) ? '' : value
    };

    useEffect(() => {
        // process country mapping
        ApiProvider.GetAllLocation()
            .then(res => {
                const _mapping = {};

                try {
                    Array.from(res.data.country).forEach(
                        item => {
                            _mapping[item.text] = item.val;
                        }
                    );
                    setCountryMapping(_mapping);
                } catch (e) {
                    console.error('not able to process country mapping');
                    console.debug(e);
                }
            });
    }, []);

    useEffect(() => {
        // isEmpty(selected) ? form.resetFields() : formRef.current.setFieldsValue(Deserialization(selected));
        if (isEmpty(selected)){
            form.resetFields();
        }else{
            const selectedAccount = Deserialization(selected);

            // set form values
            formRef.current.setFieldsValue(selectedAccount);

            // set country code by selected country
            setCountryCode(countryMapping[selectedAccount?.address?.country] || "");
        }
    }, [open, selected, form, countryMapping]);

    const countryStateCityChangeHandler = (value, selectedOptions) => {
        if (!value || !value[0]){
            return setCountryCode("");
        }

        const countryName = value[0];

        if (!!countryMapping[countryName]){
            return setCountryCode(countryMapping[countryName]);
        }

        return setCountryCode("");
    };

    const phoneNumberValidator = (_, value) => {
        if (!value){
            return Promise.resolve();
        }

        // if (!!countryCode) {
        //     // parse phone number with country code, might be error if the country code is invalid
        //     try {
        //         if (parsePhoneNumber(value, countryCode).isPossible()) {
        //             return Promise.resolve();
        //         }
        //     } catch (e) {}
        // } else {
        //     try {
        //         if (isPossiblePhoneNumber(value)) {
        //             return Promise.resolve();
        //         }
        //     } catch (e) {}
        // }
        if (/^(\+?)(\d+)$/.test(value) === true) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Phone Number is invalid!'));
    };

    const postCodeValidator = (_, value) => {
        if (!value){
            return Promise.resolve();
        }

        const country = postcodeValidatorExistsForCountry(countryCode) ? countryCode : postCodeDefaultCountry;
        if (postcodeValidator(value, country)){
            return Promise.resolve();
        }

        return Promise.reject(new Error('Post Code is invalid!'));
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handlePreSaveCheck = async () => {
        try {
            await form.validateFields();
        } catch (errorInfo) {
            console.error('form has invalid field');
            console.debug('errorInfo:', errorInfo);
            return false;
        }

        if (isEdit){
            await handleOK();
            return true;
        }

        setloading(true);

        const fieldsValue = form.getFieldsValue(true);
        ApiProvider.CheckAccountEmail(fieldsValue.email)
            .then(res => {
                // if the data status is "warning", that means you are trying to upgrade existing account
                if (res.data.status === "warning") {
                    confirm(fieldsValue.email);
                    return true;
                }

                // email does not exist, create a new advanced customer
                handleOK();
            })
            .finally(()=>{
                setloading(false);
            });
    };

    function confirm(email) {
        Message.confirm({
            title: `一般用戶升級提示`,
            confirmMsg: `請問您是否是要將${email}升級為進階用戶？`,
            okText: "OK",
            onOk: handleOK,
        });
    }

    const handleOK = async () => {
        const fieldsValue = form.getFieldsValue(true);
        await (async function saveData() {
            saveService(Serialization(fieldsValue))
                .then(res => {
                    Message.success(`${title} success`);
                    setOpen(false);
                    setloading(false);
                    setReload(true);
                })
                .catch(err => {
                    notification.error({
                        message: err.response.data.msg,
                        placement: 'topRight',
                    })
                    setloading(false);
                });
        })();
        // saveData();
    };

    const Deserialization = (row) => {
        return (
            {
                ...row,
                countryStateCity: [
                    row.address?.country,
                    row.address?.state,
                    row.address?.city,
                ],
            }
        );
    }
    const Serialization = (fieldValue) => {
        return (
            {
                ...fieldValue,
                address: {
                    country: ifEmptyOrNull(fieldValue.countryStateCity[0]),
                    state: ifEmptyOrNull(fieldValue.countryStateCity[1]),
                    city: ifEmptyOrNull(fieldValue.countryStateCity[2]),
                    zipCode: ifEmptyOrNull(fieldValue.address.zipCode),
                },
            }
        );
    };

    const handleChangeSubscribe = e => {
        if (!e.target.checked){
            setOpenReason(true);
            reasonForm.resetFields();
        }
    };

    const onOk = async () => {
        await reasonForm.validateFields();

        ApiProvider.CancelSubscription({
            brandId: selected.userId,
            reason: reasonForm.getFieldValue('reason'),
        })
            .then(res => {
                Message.success(`unsubscribe success`);
                setOpenReason(false);
                form.setFieldsValue({ 'subscription': false });
            });
    };

    const onCancel = () => {
        form.setFieldsValue({ 'subscription': true });
        setOpenReason(false);
    };

    return (
        <>
            <Modal
                width={800}
                open={open}
                onCancel={() => handleCancel()}
                footer={[
                    <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                    <Button key="OK" type="primary" loading={loading} onClick={handlePreSaveCheck}>Save</Button>,
                ]}
            >
                <Form
                    form={form}
                    size='middle'
                    ref={formRef}
                    initialValues={{ subscription: true }}
                    onFinish={handlePreSaveCheck}
                    validateMessages={{ required: label => `${label} is required` }}
                >
                    <Descriptions title={title} layout="vertical" column={12} >
                        <Descriptions.Item label="User Name" span={6}>
                            <DescriptionsInput name='userName' label="User Name" required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item span={6}>&nbsp;</Descriptions.Item>
                        <Descriptions.Item span={12}>
                            <Form.Item name="subscription" valuePropName="checked">
                                <Checkbox onChange={handleChangeSubscribe} disabled={!isEdit}>Subscribe</Checkbox>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="*Phone Number" span={6}>
                            <DescriptionsInput name="tel" type="tel" label="Phone Number" required={true} rules={[{'validator': phoneNumberValidator}]} />
                        </Descriptions.Item>
                        <Descriptions.Item label="*Email" span={6}>
                            <DescriptionsEmail name="email" label="Email" required={true} readOnly={isEdit} />
                        </Descriptions.Item>
                        <Descriptions.Item label="*Type" span={6}>
                            <DescriptionsSelet
                                name="type"
                                label="Type"
                                list={OriginalAccountTypeList.map(i => ({ value: i.value, label: i.text, disabled: (i.type === AccountType.agent && isEdit) }))}
                                dropdownMatchSelectWidth={false}
                                showSearch
                                allowClear={true}
                                required={true}
                            >
                            </DescriptionsSelet>
                        </Descriptions.Item>
                        <Descriptions.Item label="*Company/Brand Name" span={6}>
                            <DescriptionsInput name='brandName' label="Company/Brand Name" required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item span={12}>
                            <DescriptionsDetailsTitle></DescriptionsDetailsTitle>
                        </Descriptions.Item>
                        <Descriptions.Item label="*Country/Region" span={6}>
                            <CountryStateCityFormSelector onChange={countryStateCityChangeHandler} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Post Code" span={6}>
                            <DescriptionsInput name={["address", "zipCode"]} label="Post Code" rules={[{'validator': postCodeValidator}]} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Street" span={12}>
                            <DescriptionsInput name={["address", "street"]} label="Street" />
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
            </Modal>
            <Modal title="Are you sure you want to unsubscribe?"
                open={openReason}
                onOk={onOk}
                onCancel={onCancel}
            >
                <Form
                    form={reasonForm}
                    size='small'
                    initialValues={{ reason: '' }}
                    validateMessages={{ required: label => `${label} is required` }}
                >
                    <Form.Item label="reason" name="reason" rules={[{ required: true }]}>
                        <Input placeholder="Please Input unsubscribe reason" ></Input>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AccountOrgDetails;
