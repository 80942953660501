import { useEffect, useRef, useState, useCallback } from 'react'
import { isEqual } from 'lodash'

const useService = (service, params, reload, setReload) => {
  const prevParams = useRef(null);
  const [callback, { loading, error, response }] = useServiceCallback(service);

  useEffect(() => {
    if (!isEqual(prevParams.current, params) || reload) {
      prevParams.current = params;
      setReload(false);
      callback(params);
    }
  });

  return { loading, error, response };
}

export const useServiceCallback = (service) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const callback = useCallback(
    params => {
      setLoading(true)
      setError(null)
      service(params)
        .then(res => {
          console.debug(res);
          setLoading(false);
          setResponse(res.data);
        })
        .catch(err => {
          setLoading(false);
          setError(err);
        })
    },
    [service]
  );

  return [callback, { loading, error, response }];
}

export default useService;
