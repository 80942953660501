import React from 'react';
import { Result,Button } from 'antd';
import { ActionContext } from '../../../Context/ActionContext';

const ServerBusy = () => {
    const { GoHome } = React.useContext(ActionContext);
        return <Result
        status="500"
        title="502"
        subTitle="因系統忙碌，請稍後再嘗試登入 Sorry! Our system is currently busy, please try again later."
        extra={<Button type="primary" onClick={GoHome}>Back Home</Button>}
      />
    
}
export default ServerBusy;