import React, { useState, useEffect } from 'react';
import * as ApiProvider from '../Api/ApiProvider';
import { Select } from 'antd';

const { Option } = Select;
//select Branch 
export const ServicSiteSelect = (props) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            fetchBranchData()
        }
        fetchData();
    }, []);

    const fetchBranchData = () => {
        //等API
        ApiProvider.GetServiceSiteKeyValue().then(res => {
            setList(res.data)
        });
    }

    return (
        <Select
            {...props}
            dropdownMatchSelectWidth={false}
            labelInValue
            showSearch
            allowClear={true}
            disabled={list.length === 0}
            placeholder={list.length === 0 ? <>Loading....</> : <>{`Select ${props.label}`}</>}
        >
            {list.map(data => (
                <Option key={data.val}>{data.text}</Option>
            ))}
        </Select>
    )
}