import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import RecipeDetailInfo from './RecipeDetailInfo';
import RecipeBaseInfo from './RecipeBaseInfo';
import RecipeUsedStore from './RecipeUsedStore';
import * as ApiProvider from '../../Api/ApiProvider'

const RecipeCard = ({ recipe }) => {
    const [recipeInfo, setRecipeInfo] = useState({});
    const [assignbranch, setAssignbranch] = useState([]);

    useEffect(() => {
        if (recipe) {
            async function fetchData() {
                ApiProvider.GetRecipeDetails(recipe.val)
                    .then(res => {
                        setRecipeInfo(res.data)

                    });
            }
            async function fetchBranchDeviceData() {
                ApiProvider.getDeviceByRecipe(recipe.val).then(res => {
                    setAssignbranch(res.data.filter((r) => r.used).map(item => ({
                        key: item.deviceId,  
                        value: `${item.brandName}｜${item.branchName}｜${item.deviceName} `
                    })))
        
                });
            }
            fetchBranchDeviceData()
            fetchData();
        }
    }, [recipe]);

    return (
        <div>
            <RecipeBaseInfo recipeInfo={recipeInfo} />
            <RecipeDetailInfo recipeInfo={recipeInfo} />
            <RecipeUsedStore assignbranch={assignbranch} />
        </div>

    );
}


export default RecipeCard;