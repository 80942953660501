import React, { useState } from 'react';
import { Space, Row, Col, Select, Tabs, Button } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { CommonTable, columnFilterList } from '../../../Components/Table';
import SearchInput from '../../../Components/SearchInput';
import ServiceFormDetails from './ServiceFormDetails';
import { formatDate, dateDiff } from '../../../Utils/Utils';
import { ServiceStatus, ServiceStatusTagSetting } from '../../../Utils/Enum';
import { MailOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

const ServiceListTable = () => {
    const [activeKey, setActiveKey] = useState('processing');
    function callback(key) {
        setActiveKey(key);
    }
    return (
        <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Processing" key="processing">
                <ServiceTab activeKey={activeKey} />
            </TabPane>
            <TabPane tab="Closed" key="Closed">
                <ServiceTab activeKey={activeKey} />
            </TabPane>
        </Tabs>
    )
}


const ServiceTab = ({ activeKey }) => {
    const [reload, setReload] = useState(false);
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [open, setOpen] = useState(false);
    //#region  condition
    const [search, setSearch] = useState({
        searchColumn: null,
        searchWord: ''
    });
    const handleClickName = (e) => {
        async function saveData() {
            ApiProvider.UpdateRepairStatus({
                repairId: e.row.repairId,
                status: "Open",
            }).then(res => {
                row.status = 'Open'
                openDetails(row)
            })
        }
        const row = e.row;
        //未讀狀態要先更新 status
        if (row.status === ServiceStatus.New) {
            saveData();
        }
        openDetails(e.row)

    }
    const openDetails = (row) => {
        setOpen(true)
        setSelectSingleRow(row)

    }
    const columns = [
        {

            title: '',
            dataIndex: 'status',
            render: (value, row, index) => {
                return (row.status === ServiceStatus.New ? <MailOutlined /> : <></>)
            }

        },
        {
            isFilter: true,
            title: 'Status',
            dataIndex: 'status',
            render: (value, row, index) => {
                return ServiceStatusTagSetting[row.status]
            }

        },
        {
            title: 'Days',
            sorter: true,
            dataIndex: 'createdDate',
            render: (value, row, index) => <div>{dateDiff(row.createdDate, new Date())}</div>,
        },
        {
            isFilter: true,
            title: 'Type',
            dataIndex: 'issueType',
        },
        {
            title: 'SN',
            dataIndex: 'deviceSn',
            render: (value, row, index) => {
                return (<Button type="link" onClick={() => handleClickName({ row })}>{value}</Button>)
            }
        },
        {
            isFilter: true,
            title: 'Applied By',
            dataIndex: 'createdUserName',
        },
        {
            title: 'Occurred Date',
            dataIndex: 'happenDate',
            sorter: true,
            render: (value, row, index) => <div>{formatDate(value)}</div>,
        },
        {
            title: 'Applied Date',
            dataIndex: 'reportDate',
        },
        {
            title: 'Service Site',
            dataIndex: 'vendorName',
            sorter: true,
        },
        {
            title: 'Service PIC',
            dataIndex: '',
            render: (value, row, index) => {
                if (row.principal) {
                    return (<>{row.principal[0]?.name}</>)
                }
            }
        },
        {
            title: 'Agent',
            dataIndex: 'agentName',
        },
        {
            title: 'Closed Date',
            dataIndex: 'finishDate',
            display: activeKey !== 'processing',
            sorter: true,
            render: (value, row, index) => <div>{formatDate(value)}</div>,
        }
    ];
    //#endregion

    //#region table 上方 Row 相關
    function handleMenuClick(e) {
        setSearch({
            ...search,
            searchColumn: e
        })
    }
    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select dropdownMatchSelectWidth={false} placeholder='Select filter column' defaultValue={search.searchColumn} options={columnFilterList(columns)} onSelect={(e) => handleMenuClick(e)}>
                        </Select>
                    </div>
                    <div>
                        <SearchInput onPressEnter={(value) => setSearch({ ...search, searchWord: value })} />
                    </div>
                </Space>
            </Col>
        </Row>
    )
    //#endregion
    const tableProps = {
        condition: { type: activeKey },
        rowKey: 'repairId',
        ApiProviderUrl: ApiProvider.GetRepairList,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        setReload: setReload,
        setSelectedRows: (e) => { },
        setSelectSingleRow: (e) => setSelectSingleRow(e),
        setSelectedRowKeys: (e) => { },
    }
    return (
        <div>
            <CommonTable {...tableProps} />
            <ServiceFormDetails title="update" open={open} setOpen={(isOpen) => setOpen(isOpen)} selected={selectSingleRow} setReload={setReload} />
            <div style={{ fontSize: '12px' }}></div>
        </div>)
}
export default ServiceListTable