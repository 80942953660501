import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Descriptions, Col, Space, Select, Tag, Divider } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { formatDate, formatTime, formatDateTimeHasSec } from '../../../Utils/Utils'
import RecipeSearchInfo from '../../../Components/RecipeDetailCard/RecipeSearchInfo';
import { isEmpty } from 'lodash';
import { CommonTable } from '../../../Components/Table';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { BranchTreeSelect } from '../../../Components/BranchSelect';
import ic_edit from '../../../image/ic_edit.svg';
import * as Message from '../../../Components/Message';
import { Roles, hasRoles } from '../../../Settings/Roles';
const { Option } = Select;

//品牌開啟 Details
export const DeviceBranchDetails = ({ open, setOpen, selectRow, setReload }) => {
    const [loading, setloading] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [n, setN] = useState(3);
    const [store, setStore] = useState({
        value: '',
        label: ''
    });

    useEffect(() => {
        setErrorList(selectRow?.abnormal.slice(0, n))
        if (!open) {
            setN(3)
        }
        setStore({
            value: selectRow?.branchId,
            label: selectRow?.branchName
        })

    }, [selectRow, n, open])

    const onClickSave = (e) => {
        setloading(true)
        ApiProvider.UpdateDeviceDetail({
            deviceId: selectRow?.sn,
            branchId: store.value,
            branchName: store.value === '' ? '' : store.label,
        })
            .then(res => {
                Message.success(`Update success`)
                setOpen(false)
                setReload(true)
            })
            .finally(() => {
                setloading(false)
            })
    }
    const onChange = (value, selectedOptions) => {
        const select = selectedOptions[selectedOptions.length - 1];
        setStore(select)
    };
    const DeviceInformation = () => {
        return (
            <>
                <Descriptions title="Device Information" layout="vertical" column={12} >
                    <Descriptions.Item label="Device Name" span={4}>{selectRow.machineName}</Descriptions.Item>
                    <Descriptions.Item label="Store" span={4} >
                        {store.label}
                        <BranchTreeSelect name="Store" label="Store" onChange={onChange} >
                            <Button type="link" style={{ display: hasRoles(Roles.BrandOperationMgr) ? '' : 'none' }}> <img src={ic_edit} alt="" /></Button>
                        </BranchTreeSelect>

                    </Descriptions.Item>
                    <Descriptions.Item label="Device Admin" span={4}>
                        {selectRow?.deviceAdmin?.map(i => <Tag key={i}>{i}</Tag>)}
                    </Descriptions.Item>
                    <Descriptions.Item label="SN NO." span={3}>{selectRow.sn}</Descriptions.Item>
                    <Descriptions.Item label="Register Date" span={3}>{formatDate(selectRow.registerDate)}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="Device Error Information" layout="vertical" column={12} >
                    {errorList?.map(error => {
                        return (
                            <Descriptions.Item label="" span={12}>
                                <div style={{ fontSize: '14px', color: "#845D66" }}>{formatDateTimeHasSec(error.startTime)}</div>
                                {error.descriptionENUS === null ? error.result : error.descriptionENUS}
                                <Divider style={{ margin: '5px 0' }} />
                            </Descriptions.Item>
                        )
                    })}
                    <Descriptions.Item span={12} style={{ textAlign: "center", display: errorList?.length > 0 ? '' : 'none' }}>
                        <Button type="link" icon={<UpOutlined />} onClick={() => setN(3)} />
                        <Button style={{ display: n >= errorList?.length ? '' : 'none' }} type="link" icon={<DownOutlined />} onClick={() => setN(n + 10)} />
                    </Descriptions.Item>
                </Descriptions>


            </>
        )
    }
    return <DeviceDetails isOpen={open} selectRow={selectRow} setOpen={setOpen} DeviceInformation={DeviceInformation} onClickSave={onClickSave} loading={loading} showSave={true} />
}
//原廠開啟 Details 
export const DeviceOrgDetails = ({ open, selectRow, setOpen }) => {
    const [filterType, setFilterType] = useState();
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState({
        filterType: 'ALL',
        sn: selectRow?.sn,
        preDate: new Date(new Date().setMonth(new Date().getMonth() - 2)), //兩個月的資料
        postDate: new Date(new Date().setDate(new Date().getDate() + 1)) //今天
    });
    const [openRecord, setOpenRecord] = useState(true);
    useEffect(() => {
        async function fetchData() {
            if (selectRow) {
                setSearch({
                    filterType: 'ALL',
                    sn: selectRow.sn,
                    preDate: new Date(new Date().setMonth(new Date().getMonth() - 2)), //兩個月的資料
                    postDate: new Date(new Date().setDate(new Date().getDate() + 1)) //今天
                })
            }
        }
        fetchData();
        setOpenRecord(true)
    }, [selectRow, open]);
    //#region Column
    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            render: (Text) => <div>{formatDate(Text)}</div>,
            width: 100
        },
        {
            title: '',
            dataIndex: 'createdAt',
            render: (Text) => <div>{formatTime(Text)}</div>,
            width: 70
        },
        {
            title: 'Record',
            dataIndex: 'record',
        },
        {
            title: '',
            dataIndex: 'createdBy',
            width: 180
        },
    ];
    //Filter下拉選單
    const filterList = [
        { text: 'All', value: 'ALL' },
        { text: 'By Recipe Change', value: 'RECIPE_CHANGE' },
        { text: 'By Clean Record', value: 'CLEAN_RECORD' },
        { text: 'By Abnormal Status', value: 'ABNORMAL' },
        { text: 'By Service Request', value: 'SERVICEREQUEST' },
        { text: 'By Setting Change', value: 'SETTING' },
    ]

    //Filter下拉選單 onChange
    function handleMenuSelect(e) {
        setFilterType(e)
        //清空查詢條件
        setSearch({
            ...search,
            filterType: e,
        })
    }
    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select dropdownMatchSelectWidth={false} onSelect={handleMenuSelect} placeholder="Please select record type !" value={filterType}>
                            {filterList.map(list => (
                                <Option key={list.value} >{list.text}</Option>
                            ))}
                        </Select>
                    </div>
                </Space>
            </Col>
            <Col>
                <Space>
                    <Button type="link" icon={openRecord ? <DownOutlined /> : <UpOutlined />} onClick={() => setOpenRecord(!openRecord)} />
                </Space>
            </Col>
        </Row>
    )
    const tableProps = {
        rowKey: 'sn',
        ApiProviderUrl: ApiProvider.GetDeviceRecordData,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        setReload: setReload,
        setSelectleRow: (e) => { },
        style: { display: openRecord ? '' : 'none', width: '100%' }
    }
    const DeviceInformation = () => {
        return (
            <div>
                <Descriptions title="Device Information" layout="vertical" column={12} >
                    <Descriptions.Item label="Device Name" span={4}>{isEmpty(selectRow.machineName) ? 'No Name' : selectRow.machineName}</Descriptions.Item>
                    <Descriptions.Item label="Brand" span={4}>{selectRow.brandName}</Descriptions.Item>
                    <Descriptions.Item label="Branch" span={4}>{selectRow.branchName}</Descriptions.Item>
                    <Descriptions.Item label="SN NO." span={3}>{selectRow.sn}</Descriptions.Item>
                    <Descriptions.Item label="Register Date" span={4}>{formatDate(selectRow.registerDate)}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="Device Record" layout="vertical" column={12} >
                    <Descriptions.Item>
                        <CommonTable  {...tableProps} />
                    </Descriptions.Item>
                </Descriptions>
            </div>
        )
    }
    return <DeviceDetails isOpen={open} selectRow={selectRow} setOpen={setOpen} DeviceInformation={DeviceInformation} showSave={false} onClickSave={() => { }} />
}


//外框  DeviceInformation給原廠和品牌做客製
const DeviceDetails = ({ isOpen, selectRow, setOpen, DeviceInformation, onClickSave, showSave }) => {
    return (
        <Modal
            width={880}
            open={isOpen}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>Close</Button>,
                <Button key="Save" type="primary" onClick={onClickSave} style={{ display: showSave ? '' : 'none' }}>Save</Button>
            ]}
        >
            <Row>
                <DeviceInformation />
            </Row>
            <Row>
                <RecipeSearchInfo sn={selectRow?.sn} />
            </Row>
        </Modal>
    )
}