import React, { useState, useEffect } from 'react';
import { Modal, Button, Descriptions, Form, Checkbox, notification } from 'antd';
import { isEmpty } from 'lodash';
import { BranchDeviceTree, BranchMutiSelect, BranchFormSelect } from '../../../Components/BranchSelect';
import { RolesList, RolesDetails, hasRoles, Roles } from '../../../Settings/Roles';
import { DescriptionsDetailsTitle, DescriptionsInput, DescriptionsEmail } from '../../../Components/Descriptions';
import * as Message from '../../../Components/Message';
import PopoverInfo from '../../../Components/PopoverInfo';

const AccountBrandDetails = ({ open, setOpen, setReload, selected, saveService, title, isEdit }) => {
    const [loading, setloading] = useState(false);
    const [deviceRequired, setDeviceRequired] = useState(false);
    const [branchRequired, setBranchRequired] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        setDeviceRequired(false)
        setBranchRequired(false)
        if (!isEmpty(selected)) {
            console.log(selected)
            const fieldsValue = Deserialization(selected);
            console.log(fieldsValue)
            form.setFieldsValue(fieldsValue)
        }
    }, [form, open, selected]);

    const handleCancel = () => {
        setOpen(false)
    };

    //下方權限所有設定
    const RoleCheckBoxProps =
        [
            {
                required: branchRequired,
                name: RolesDetails.BranchAdmin.name,
                label: RolesDetails.BranchAdmin.label,
                infoContent: <>{RolesDetails.BranchAdmin.details}</>,
                onChange: (e) => {
                    setBranchRequired(e.target.checked)
                    if (!e.target.checked) {
                        form.setFieldsValue({ 'branchAdminStores': [] })
                    }
                },
                Ohters:
                    <BranchMutiSelect
                        name="branchAdminStores"
                        label="Branch Store"
                        required={branchRequired}
                        onChange={(e) => {
                            setBranchRequired(e.length > 0)
                            form.setFieldsValue({ 'BranchAdmin': e.length > 0 })
                        }
                        }
                    />
            },
            {
                required: deviceRequired,
                name: RolesDetails.DeviceAdmin.name,
                label: RolesDetails.DeviceAdmin.label,
                infoContent: <>{RolesDetails.DeviceAdmin.details}</>,
                onChange: (e) => {
                    setDeviceRequired(e.target.checked)
                    if (!e.target.checked) {
                        form.setFieldsValue({ 'deviceAdminDevices': [] })
                    }
                },
                Ohters:
                    <BranchDeviceTree
                        name="deviceAdminDevices"
                        label="Branch Device"
                        required={deviceRequired}
                        onChange={(e) => {
                            setDeviceRequired(e.length > 0)
                            form.setFieldsValue({ 'DeviceAdmin': e.length > 0 })
                        }
                        }
                    />

            },
            {
                name: RolesDetails.Barista.name,
                label: RolesDetails.Barista.label,
                infoContent: <>{RolesDetails.Barista.details}</>,
            },
            {
                name: RolesDetails.BrandOperationMgr.name,
                label: RolesDetails.BrandOperationMgr.label,
                infoContent: <>{RolesDetails.BrandOperationMgr.details}</>,
            }
        ]

    const Deserialization = (row) => {
        const roleList = {};
        row.roleList.forEach(function (i) {
            Object.assign(roleList, { [i]: true })
        })
        return (
            Object.assign({
                userName: row.userName,
                email: row.email,
                locatedStore:
                {
                    value: row.branchId,
                    label: row.branchName,
                },
                branchAdminStores: row.branchAdmin?.map(i => ({ value: i })),
                deviceAdminDevices: row.deviceAdmin?.map(i => ({ value: i })),
            }, roleList)
        );
    }
    const Serialization = (fieldValue) => {
        return (
            {
                userId: selected.userId,
                userName: fieldValue.userName,
                email: fieldValue.email,
                branchId: fieldValue.locatedStore.value,
                branchName: fieldValue.locatedStore.label,
                roleList: RolesList.filter(i => fieldValue[i]),
                branchAdmin: fieldValue.branchAdminStores?.map(i => i.value), //branchId,
                deviceAdmin: fieldValue.deviceAdminDevices?.map(i => i.value)

            });
    }

    const handleOk = async () => {
        try {
            await form.validateFields();

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return
        }
        const fieldValue = form.getFieldValue();

        setloading(true);
        async function saveData() {
            saveService(Serialization(fieldValue))
                .then(res => {
                    Message.success(`${title} success`)
                    setOpen(false);
                    setloading(false);
                    setReload(true)
                })
                .catch(err => {
                    notification.error({
                        message: err.response.data.msg,
                        placement: 'topRight'
                    })
                    setloading(false);
                });
        }
        saveData()
    };

    const DescriptionsCheckBox = (props) => (
        <Form.Item name={props.name} valuePropName="checked" style={{ width: '100%' }} >
            <Checkbox {...props}>{props.label}
                {(props.infoContent) ? (<PopoverInfo {...props}></PopoverInfo>) : <></>}
            </Checkbox>
        </Form.Item>
    )

    return (
        <Modal
            width={800}
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                <Button key="OK" type="primary" loading={loading} onClick={handleOk}  >Save</Button>,
            ]}
        >
            <Form
                form={form}
                requiredMark={false}
                size='middle'
                onFinish={handleOk}
            >
                <Descriptions title={title} layout="vertical" column={12} >
                    <Descriptions.Item span={12}><DescriptionsDetailsTitle title="BASIC INFO" /></Descriptions.Item>
                    <Descriptions.Item label="*User Name" span={6} >
                        <DescriptionsInput name='userName' label="User Name" required={true} />
                    </Descriptions.Item>
                    <Descriptions.Item label={<>*Located Store<PopoverInfo infoContent={<>Located Store</>}></PopoverInfo></>} span={6}>
                        <BranchFormSelect name="locatedStore" label="Located Store" required={true} />
                    </Descriptions.Item>
                    <Descriptions.Item label="*Email" span={6}>
                        <DescriptionsEmail name="email" label="*Email" required={true} readOnly={isEdit} />
                    </Descriptions.Item>
                    <Descriptions.Item span={6}></Descriptions.Item>
                    <Descriptions.Item span={12}><DescriptionsDetailsTitle title="AUTHORITY" /></Descriptions.Item>
                    {/* BrandOperationMgr 不能設定 SystemAdmin */}
                    <Descriptions.Item span={12} style={{ background: '#E2D6CA 0% 0% no-repeat padding-box', display: hasRoles(Roles.BrandOperationMgr) ? 'none' : '' }} >
                        <DescriptionsCheckBox name={RolesDetails.SystemAdmin.name} label={RolesDetails.SystemAdmin.label} style={{ fontSize: '16px' }} showInfo={false} />
                        <div style={{ fontSize: '12px', margin: '0 0 10px 25px' }}>{RolesDetails.SystemAdmin.details}</div>
                    </Descriptions.Item>
                    {RoleCheckBoxProps.map(i => {
                        return (
                            <>
                                <Descriptions.Item span={12} >
                                    <DescriptionsCheckBox {...i} />
                                </Descriptions.Item>
                                <Descriptions.Item span={12}>
                                    {i.Ohters}
                                </Descriptions.Item>
                            </>
                        )
                    })}
                </Descriptions>
            </Form>
        </Modal>
    )
}

export default AccountBrandDetails;