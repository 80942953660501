import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { RecipeBoxesContent, ContentBoxes, BoxesContent } from './ResultBoxes';
import { ActionContext } from '../../../Context/ActionContext';

//Assign 後的結果視窗
const AssignResult = ({ open, setOpen, setReload, assignbody }) => {
    const [loading, setLoading] = useState(false)
    const { updateCount } = React.useContext(ActionContext);
    const Close = () => {
        setOpen(false)
        setReload(true)
        updateCount()
    }
    const [offlineList, setOfflineList] = useState([]);
    const [assignbodyWithOnline, setAssignbodyWithOnline] = useState([]);


    useEffect(() => {
        if (assignbody && assignbody.deviceConnected && assignbody.bodies) {
            const disConnectedSnList = assignbody.deviceConnected?.filter(i => !i.isConnect)?.map(i => i.deviceSn);

            setOfflineList(disConnectedSnList);
            setAssignbodyWithOnline(assignbody.bodies?.map(body =>
            ({
                ...body,
                deviceIdList: body.deviceIdList.filter(i => !disConnectedSnList.includes(i)),
                deviceList: body.deviceList.filter(i => !disConnectedSnList.includes(i.value))
            })//排除offline 機台
            ))

        }
    }, [assignbody]);
    useEffect(() => {
        //有上線中的機台 關閉的按鈕需loading
        const hasOnlineDevice = assignbodyWithOnline.some(i => i.deviceIdList.length > 0)
        setLoading(hasOnlineDevice)
    }, [assignbodyWithOnline]);
    return (
        <Modal
        maskClosable={true}
            closable={false}
            title='　'
            open={open}
            onCancel={Close}
            footer={[
                <Button key="close" type="primary" onClick={Close} loading={loading}>Close</Button>
            ]}
        >
            {assignbodyWithOnline?.map(body => {
                if (body.deviceIdList.length === 0) {
                    return <></>
                }
                return (
                    <ContentBoxes
                        visible={body.deviceList.length > 0}
                        key={body.recipeName}
                        title={body.recipeName}
                        subTitle={<>Recipe {body.recipeIndex}</>}
                        content={<RecipeBoxesContent assignbody={body} setClosedLoading={setLoading} />}
                    />
                )
            })}
            {/* Offline Device */}
            <ContentBoxes
                visible={offlineList?.length > 0}
                title="Offline Device"
                subTitle={"Below \"book\" devices cannot be assigned remotely, please check wifi connection of devices and assign again."}
                content={<BoxesContent success={[]} fail={[]} offline={offlineList} />}
            />
        </Modal>

    )
}

export default AssignResult;
