import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Form, Divider } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import RecipeBaseInfo from '../../../Components/RecipeDetailCard/RecipeBaseInfo';
import RecipeDetailInfo from '../../../Components/RecipeDetailCard/RecipeDetailInfo';
import RecipeUsedStore from '../../../Components/RecipeDetailCard/RecipeUsedStore';
import BranchTreeAndRecipe from '../../../Components/BranchTreeAndRecipe';

const SingleAssign = ({ open, setOpen, selected, readonly, handleAssignResult }) => {
    const [RecipeInfo, setRecipeInfo] = useState({});
    //Dropdown DataSource
    const [unassignbranch, setUnassignbranch] = useState([]);
    const [assignbranch, setAssignbranch] = useState([]);
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        async function fetchData() {
            if (selected) {
                fetchRecipeDetails(selected)
                fetchBranchDeviceData(selected)
            }
        }
        fetchData();
    }, [selected, open]);


    const fetchRecipeDetails = (row) => {
        ApiProvider.GetRecipeDetails(row.recipeId)
            .then(res => {
                setRecipeInfo(res.data)

            });
    }
    const fetchBranchDeviceData = (row) => {
        ApiProvider.getDeviceByRecipe(row.recipeId).then(res => {
            setUnassignbranch(res.data.filter((r) => !r.used).map(item => ({
                groupId: item.branchId,
                groupName: item.branchName,
                key: item.deviceId,
                value: item.deviceId,
                title: `${item.branchName}｜${item.deviceName} `
            })))
            setAssignbranch(res.data.filter((r) => r.used).map(item => ({
                key: item.deviceId,
                value: `${item.brandName}｜${item.branchName}｜${item.deviceName} `
            })))

        });

    }

    const handleCancel = () => {
        setOpen(false)
        form.resetFields();
    };

    const handleOk = async () => {
        try {
            await form.validateFields();

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return
        }
        const fieldValue = form.getFieldValue();
        setloading(true);
        const body = {
            recipeName: selected.recipeName,
            recipeId: selected.recipeId,
            recipeIndex: fieldValue[selected.recipeId].recipeIndex,
            deviceIdList: fieldValue[selected.recipeId].deviceIdList.map(i => i.value),
            deviceList: fieldValue[selected.recipeId]?.deviceIdList,//deviceList
        }
        handleAssignResult([body]);
        setloading(false);
        setOpen(false);
    }


    const recipeInfo = { ...RecipeInfo };
    return (
        <Modal
            width={750}
            open={open}
            onCancel={handleCancel}
            footer={
                readonly ?
                    [
                        <Button key="Cancel" type="primary" loading={loading} onClick={handleCancel}>Close</Button>,
                    ] :
                    [
                        <Button key="Cancel" onClick={handleCancel}>Cancel</Button>,
                        <Button key="OK" type="primary" loading={loading} onClick={handleOk} >OK</Button>,
                    ]
            }
            afterClose={()=>form.resetFields()}
        >
            <RecipeBaseInfo recipeInfo={selected} />
            <Divider />
            <Row style={{ display: (readonly ? "none" : "") }} >
                <Form form={form} initialValues={{}}>
                    <BranchTreeAndRecipe id={selected?.recipeId} branchList={unassignbranch} />
                </Form>
            </Row>
            <Row>
                <RecipeDetailInfo recipeInfo={recipeInfo} />
            </Row>
            <Row>
                <RecipeUsedStore assignbranch={assignbranch} />
            </Row>
        </Modal>
    )
}
export default SingleAssign;