import React from 'react';
import coarse from './GrindSize/ic_grind_size_coarse.svg';
import medium_coarse from './GrindSize/ic_grind_size_medium_coarse.svg';
import medium from './GrindSize/ic_grind_size_medium.svg';
import medium_fine from './GrindSize/ic_grind_size_medium_fine.svg';
import fine from './GrindSize/ic_grind_size_fine.svg';

const GrindSize = (prop) => {
    switch (prop.grindSize) {
        case "Coarse":
            return <img src={coarse} alt={prop.grindSize} />
        case "Mediumcoarse":
            return <img src={medium_coarse} alt={prop.grindSize}/>
        case "Medium":
            return <img src={medium} alt={prop.grindSize}/>
        case "Mediumfine":
            return <img src={medium_fine} alt={prop.grindSize}/>
        case "Fine":
            return <img src={fine} alt={prop.grindSize}/>
        default:
            return <></>
    }

}
export default GrindSize;
