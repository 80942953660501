import React from 'react';
import { Layout, Row, Col, Form } from 'antd';
import LoginPic from '../../../image/Login.jpg';
const { Content } = Layout;

const formlayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
  style: { padding: '60px 40px 10px 40px', float: 'center', height: '100vh', width: '100%' }
};


function Success(props) {
  const [form] = Form.useForm();

  return (
    <Layout style={{ background: '#F5F2EF' }}>
      <Content>
        <Row >
          <Col xs={24} sm={24} md={24} lg={9} xl={9} >
            <Form
              form={form}
              requiredMark={false}
              size='middle'
              {...formlayout}
            >
              <Form.Item>
                <div style={{ fontSize: '28px', color: '#453539', textAlign: 'center', margin: '0', fontWeight: 'inhenormalrit' }}>coffee cloud</div>
                <div style={{ fontSize: '49px', color: '#404040', textAlign: 'center', fontWeight: 'lighter' }}>WELCOME</div>
                <div style={{ fontSize: '18px', color: '#404040', textAlign: 'center', margin: '50px 0 50px 0', fontWeight: 'inhenormalrit' }}> Your password has been updated! Please login again .</div>

              </Form.Item>
            </Form>
          </Col>
          <Col xs={0} sm={0} md={0} lg={15} xl={15}>
            <img src={LoginPic} style={{ height: '100vh', width: '100%', float: 'right' }} alt=""></img>
          </Col>
        </Row>

      </Content>
    </Layout>

  );
}

export default Success;