import React from 'react';
import barista from '../image/Authority/ic_barista.svg';
import branch_admin from '../image/Authority/ic_branch admin.svg';
import device_admin from '../image/Authority/ic_device admin.svg';
import operation from '../image/Authority/ic_operation manager.svg';
import system_admin from '../image/Authority/ic_system admin.svg';
import { getRoles } from '../Utils/session';

/**
 * 判斷是否為原廠系統
 * @returns 
 */
export const isRootRoles = () => {
    const roles = getRoles();
    return roles && (roles.includes(Roles.RootAdmin) || roles.includes(Roles.CoffeeLab)|| roles.includes(Roles.Agent));
}

/**
 * 判斷是否為原廠系統
 * @returns 
 */
export const isRoot = () => {
    const roles = getRoles();
    return roles && (roles.includes(Roles.RootAdmin))
}

/**
 * 判斷是否為系統可使用的角色
 * @param {string,string,string} roles 
 * @returns 
 */
export const isSystemRoles = (roles) => {
    const userRoles = roles ? roles.split(",") : [];

    //檢查是否是系統內的角色
    return userRoles.some(r => Object.keys(Roles).includes(r));
}
/**
 * 判斷是否擁有某個角色
 * @returns 
 */
 export const hasRoles = (role) => {
    const roles = getRoles();
    return roles && (roles.includes(role));
}

/**
 * 系統內的角色
 */
export const Roles =
{
    CoffeeLab: 'Tester',
    RootAdmin: 'RootAdmin',
    Barista: 'Barista',
    BrandOperationMgr: 'BrandOperationMgr',
    BrandAdmin: 'BrandAdmin',
    BranchAdmin: 'BranchAdmin',
    DeviceAdmin: 'DeviceAdmin',
    Agent: 'Agent',
    Subscriber: 'Subscriber',
}

/**
 * 品牌的角色
 */
export const RolesList =
    [
        "Barista", "BrandOperationMgr", "BrandAdmin", "BranchAdmin", "DeviceAdmin"
    ]
/**
 * 角色相關詳細(顯示的照片)
 */
export const RolesDetails =
{
    Barista: {
        name: Roles.Barista,
        label: 'Barista',
        tag: 'Barista',
        details:
            <div>
                <div>-Read brand recipe in web system</div>
                <div>-Authority to update recipe in BT connected devices</div>
            </div>,
        picSrc: barista,
        picColor: '#845D66',
    },

    BrandOperationMgr: {
        name: Roles.BrandOperationMgr,
        label: 'Operation Manager',
        tag: 'Operation Manager',
        details:
            <div>
                <div>-Achieve brand recipe in web system</div>
                <div>-Assign recipe in remote devices</div>
                <div>-Read branch and device data in Web system</div>
                <div>-Read sales report</div>
            </div>
        ,
        picSrc: operation,
        picColor: '#0A496A',
    },
    SystemAdmin: {
        name: Roles.BrandAdmin,
        label: 'System Admin',
        tag: 'System Admin',
        details: 'The system admin can create and edit branch, account, device data; and edit branch admin, device admin and assign role authority for each account.',
        picSrc: system_admin,
    },
    BranchAdmin: {
        name: Roles.BranchAdmin,
        label: 'Branch admin in system',
        tag: 'Branch Admin',
        details:
            <div>
                <div>-Get notification to upgrade device and execute upgrade process</div>
                <div>-Get notification of devices status in managed branches</div>
                <div>-Read device status in APP remotely</div>
                <div>-Read brewing statistic of devices in managed branches in APP remotely</div>
                <div>-Authority to update recipe in BT connected devices</div>
                <div>-Authority to edit device admin</div>
            </div>,
        picSrc: branch_admin,
        picColor: '#92B1C1',
    },
    DeviceAdmin: {
        name: Roles.DeviceAdmin,
        label: 'Device admin in system',
        tag: 'Device Admin',
        details:
            <div>
                <div>-Get notification of devices status in managed branches</div>
                <div>-Read device status in APP remotely</div>
                <div>-Authority to update recipe in BT connected devices</div>
            </div>,
        picSrc: device_admin,
        picColor: '#C1AA92',
    },
}