import React, { useState } from 'react';
import { Space, Row, Col, Select } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { CommonTable, columnFilterList } from '../../../Components/Table';
import SearchInput from '../../../Components/SearchInput';
import CustomerDetails from './CustomerDetails';
import { formatDate } from '../../../Utils/Utils'

export const GeneralCustomerTable = ({ activeKey }) => {
    return <CustomerTable activeKey={activeKey} name="General"></CustomerTable>
}
export const AdvancedCustomerTable = ({ activeKey }) => {
    return <CustomerTable activeKey={activeKey} name="Advanced"></CustomerTable>
}

const CustomerTable = ({ activeKey, name }) => {
    const [reload, setReload] = useState(false);
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [open, setOpen] = useState(false);
    //#region  condition
    const [search, setSearch] = useState({
        searchColumn: 'brandName',
        searchWord: ''
    });
    //#endregion

    const columns = [
        {
            isFilter: true,
            title: 'Company/Brand Name',
            dataIndex: 'brandName',
        },
        {
            title: 'Customer Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },

        {
            title: 'Create Date',
            dataIndex: 'createdDate',
            sorter: true,
            render: (Text) => <div>{formatDate(Text)}</div>,
        }

    ];
    //#endregion

    //#region table 上方 Row 相關
    function handleMenuClick(e) {
        setSearch({
            ...search,
            searchColumn: e
        })
    }

    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select dropdownMatchSelectWidth={false} placeholder='Select filter column' defaultValue={search.searchColumn} options={columnFilterList(columns)} onSelect={(e) => handleMenuClick(e)}>
                        </Select>
                    </div>
                    <div>
                        <SearchInput onPressEnter={(value) => setSearch({ ...search, searchWord: value })} />
                    </div>
                </Space>
            </Col>
        </Row>
    )
    //#endregion
    const tableProps = {
        condition: { type: name },
        active: activeKey,
        rowKey: 'accountId',
        ApiProviderUrl: ApiProvider.GetAccountAllGrid,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        setReload: setReload,
        setSelectleRow: (e) => { },
        setSelectSingleRow: (e) => setSelectSingleRow(e),
    }
    return (
        <div>
            <CommonTable {...tableProps} />
            <CustomerDetails title="Customer" open={open} setOpen={(isOpen) => setOpen(isOpen)} selected={selectSingleRow} />
            <div style={{ fontSize: '12px' }}>
            </div>
        </div>
    );
};

