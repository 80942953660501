import React, { useState } from 'react';
import { Space, Button, Row, Col, Table } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { formatDateTime } from '../../../Utils/Utils'
import { SelectedTable } from '../../../Components/Table';
import SearchInput from '../../../Components/SearchInput';
import { RemoveButton, ConfirmButton } from '../../../Components/Button';
import NotificationMangementDetails from './NotificationManagementDetails'
import NotificationMangementPush from './NotificationManagementPush'
import { NotificationLanguageTab } from '../../../CoffeeCloudConfig';

const NotificationManagementTable = () => {
    //#region  condition
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [openPush, setOpenPush] = useState(false);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState({
        searchColumn: '',
        searchWord: ''
    });


    //#region Column
    const handleClickName = (e) => {
        setOpenEdit(true)
        setSelectSingleRow(e.row)
    }
    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            render: (value, row, index) => {
                return (
                    <Button type="link" onClick={() => handleClickName({ row })}>{value}</Button>
                )
            }
        },
        {
            title: 'Content',
            dataIndex: 'content',
        },
        {
            title: 'Language',
            dataIndex: 'language',
            render: (value) => {
                return (
                    <div>{NotificationLanguageTab.filter(i => i.key.toUpperCase() === value.toUpperCase())[0]?.tab}</div>
                )
            }
        },
        {
            title: 'Push Person',
            dataIndex: 'lastPushUserName',
        },
        {
            title: 'Push Date',
            dataIndex: 'lastPushDate',
            render: (Text) => <div>{formatDateTime(Text)}</div>,
        },
    ];
    //#endregion

    //#region table 上方 Row 相關
    const tableTopRow = (
        <>
            <Row justify="space-between" style={{ marginBottom: '30px' }}>
                <Col>
                    <Space>
                        <div>
                        </div>
                        <div>
                            <SearchInput onPressEnter={(value) => setSearch({ ...search, searchWord: value })} />
                        </div>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button type="" style={{ display: (selectedRows.length === 1 ? "" : "none") }} onClick={() => setOpenPush(true)}>Push Notification For Test</Button>
                        <ConfirmButton label="Push Notification" service={ApiProvider.PushAllWebNotification} param={selectedRows[0]} setReload={setReload} style={{ display: (selectedRows.length === 1 ? "" : "none") }} />
                        <Button type="primary" style={{ display: (selectedRows.length === 0 ? "" : "none") }} onClick={() => setOpenNew(true)}>New Notification</Button>
                        <RemoveButton label="Delete" service={ApiProvider.RemoveWebNotification} param={selectedRows} setReload={setReload} style={{ display: (selectedRows.length > 0 ? "" : "none") }} />
                    </Space>
                </Col>
            </Row>
        </>
    )
    //#endregion

    const tableProps = {
        rowKey: 'messageId',
        ApiProviderUrl: ApiProvider.GetWebNotification,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        selectedRowKeys: selectedRowKeys,
        setReload: setReload,
        setSelectedRows: (e) => setSelectedRows(e),
        setSelectSingleRow: (e) => setSelectSingleRow(e),
        setSelectedRowKeys: (e) => setSelectedRowKeys(e),
        expandable: {
            expandedRowRender: record =>
                <Table
                pagination={false}
                size={'small'}
                    dataSource={record.otherlanguages} 
                    columns={
                    [
                        {
                        title: 'Subject',
                        dataIndex: 'subject',
                    },
                    {
                        title: 'Content',
                        dataIndex: 'content',
                    }]}
                />
        },
    }

    return <>
        <SelectedTable {...tableProps} />
        <NotificationMangementDetails title='Edit Notification' isEdit={true} open={openEdit} setOpen={(isOpen) => setOpenEdit(isOpen)} setReload={(isReload) => setReload(isReload)} selected={selectSingleRow} saveService={ApiProvider.UpdateWebNotification} />
        <NotificationMangementDetails title='Create New Notification' isEdit={false} open={openNew} setOpen={(isOpen) => setOpenNew(isOpen)} setReload={(isReload) => setReload(isReload)} selected={[]} saveService={ApiProvider.CreateWebNotification} />
        <NotificationMangementPush title='Push Notification' open={openPush} setOpen={(isOpen) => setOpenPush(isOpen)} setReload={(isReload) => setReload(isReload)} selected={selectedRows[0]} saveService={ApiProvider.PushWebNotification} />
    </>
};



export default NotificationManagementTable;

