import React, { useState, useEffect } from 'react';
import { Descriptions, Row, Tag, Skeleton } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';

//每個 Assign Box
export const RecipeBoxesContent = ({ assignbody: body, setClosedLoading }) => {
    const [fail, setFail] = useState([]);
    const [success, setSuccess] = useState([]);
    const [loading, setLoading] = useState(true)
    //defalult
    useEffect(() => {
        setFail([])
        setSuccess([])
    }, []);

    //執行Assign
    useEffect(() => {
        if (body.deviceList.length > 0) {
            async function fetchData() {
                ApiProvider.AssignRecipe(body)
            }
            fetchData();

            //Check 結果
            let timer1 = setTimeout(() => {

                async function fetchDataStatus(data) {
                    ApiProvider.GetDeviceStatus(data).then(res => {
                        setFail(res.data.fail);
                        setSuccess(res.data.success);
                        ApiProvider.CreateFailedRecipe(GetFailBody(res.data.fail, data));//失敗需存入Fail清單內            
                    });

                    const GetFailBody = (failItems, result) => {
                        const failBody = [];
                        failItems.forEach(deviceId => {
                            failBody.push({
                                deviceId: deviceId,
                                deviceName: result.deviceList.filter(i => i.value === deviceId)[0].label,
                                recipeId: result.recipeId,
                                recipeIndex: result.recipeIndex,
                                recipeName: result.recipeName
                            })
                        });
                        return failBody
                    }
                }
                fetchDataStatus(body)
            }, 5000);
            return () => {
                clearTimeout(timer1);
            };
        }

    }, [body]);
    //執行Assign
    useEffect(() => {
        //相加
        const unDone = (success.length + fail.length) !== body.deviceList.length
        setLoading(unDone)
        setClosedLoading(unDone)
    }, [success, fail, body, setClosedLoading]);

    return <BoxesContent success={success} fail={fail} offline={[]} loading={loading} />
}

const DeviceLabel = (props) => {
    const [display, setDisplay] = useState("");
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        async function fetchData() {
            ApiProvider.GetDeviceDetails({ sn: props.sn }).then(res => {
                setDisplay(`${res.data.branchName} | ${res.data.machineName}`)
                setLoading(false)
            })
        }
        fetchData();
    }, [props])
    return <Skeleton title={false} round={true} shape="square" active={loading} loading={loading} paragraph={{ rows: 1, width: 300 }}><Tag {...props}>{display}</Tag></Skeleton>

}
export const ContentBoxes = ({ title, subTitle, content, visible }) => {
    return (
        < div style={{ display: visible ? '' : 'none     ' }}>
            <Row>
                <BoxesTitle name={title} />
            </Row>
            <Row>
                <BoxesSubTitle lable={subTitle} />
            </Row>
            <Row style={{ margin: '0px 0px 30px 0px' }}>
                {content}
            </Row>
        </div>
    )
}

const BoxesTitle = ({ name }) => (
    <Row>
        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>{name}</div>
    </Row>)
const BoxesSubTitle = ({ lable }) => (<div style={{ color: '#797979' }}>{lable}</div>)

const BoxesContentTags = ({ title, deviceSNList, color, tagColor }) => (
    <div style={{ display: deviceSNList.length > 0 ? '' : 'none     ' }}>
        <div style={{ fontSize: '18px', color: color, fontWeight: 'bold' }}>{title}</div>
        {deviceSNList?.map((sn, i) => <DeviceLabel key={sn} sn={sn} closable={false} onClose={false} color={tagColor} />)}
    </div>
)

export const BoxesContent = ({ success, fail, offline, loading }) => (
    <Descriptions layout="vertical" >
        <Descriptions.Item span={12} >
            <Skeleton title={false} round={true} shape="square" active={loading} loading={loading} paragraph={{ rows: 1, width: 300 }}>
                <BoxesContentTags color={''} title={"Assign Success"} deviceSNList={success} />
                <BoxesContentTags color={'#BC4242'} tagColor={'red'} title={"Assign Fail"} deviceSNList={fail} />
                <BoxesContentTags color={'#797979'} tagColor={'gray'} title={<></>} deviceSNList={offline} />
            </Skeleton>
        </Descriptions.Item>
    </Descriptions>
)