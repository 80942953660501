import React, { useState } from 'react';
import { Modal, Button, Upload, Row, Descriptions } from 'antd';
import * as XLSX from 'xlsx';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import {  FileUrl } from '../CoffeeCloudConfig';
import * as ApiProvider from '../Api/ApiProvider';
import * as Message from './Message'
export const ImportAccountBrand = ({ open, setOpen, setReload }) => {
    const [errorMsg, setErrorMsg] = useState([]);
    const handleValidate = (item) => {
        const error = [];

        console.log(item)
        //先批次判斷信箱是否存在
        // ApiProvider.MultiAssignRecipe(body)
        // .then(res => {
        // error.push()
        // });

        item.forEach((element,i) => {
        });
        setErrorMsg(error);
        return error.length === 0;
    }

    return (
        <div>
            <ImportPop
                open={open}
                setOpen={(isOpen) => setOpen(isOpen)}
                setReload={(isReload) => setReload(isReload)}
                templateUrl={FileUrl.importAccountBrandTemplate}
                saveService={ApiProvider.CreateMultiAccount}
                handleValidate={(item) => handleValidate(item)}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
            />
        </div>
    );
};
export const ImportAccountOrg = ({ open, setOpen, setReload }) => {
    const [errorMsg, setErrorMsg] = useState([]);
    const handleValidate = (item) => {
        const error = [];

        console.log(item)
        //先批次判斷信箱是否存在
        // ApiProvider.MultiAssignRecipe(body)
        // .then(res => {
        // error.push()
        // });

        item.forEach((element,i) => {
        });
        setErrorMsg(error);
        return error.length === 0;
    }

    return (
        <div>
            <ImportPop
                open={open}
                setOpen={(isOpen) => setOpen(isOpen)}
                setReload={(isReload) => setReload(isReload)}
                templateUrl={FileUrl.importAccountOrgTemplate}
                saveService={ApiProvider.CreateMultiAccountOrg}
                handleValidate={(item) => handleValidate(item)}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
            />
        </div>
    );
};
export const ImportBranch = ({ open, setOpen, setReload }) => {
    const [errorMsg, setErrorMsg] = useState([]);
    const handleValidate = (item) => {
        const error = [];

        console.log(item)
        //先批次判斷信箱是否存在
        // ApiProvider.MultiAssignRecipe(body)
        // .then(res => {
        // error.push()
        // });

        item.forEach((element,i) => {
        });
        setErrorMsg(error);
        return error.length === 0;
    }

    return (
        <div>
            <ImportPop
                open={open}
                setOpen={(isOpen) => setOpen(isOpen)}
                setReload={(isReload) => setReload(isReload)}
                templateUrl={FileUrl.importBranchTemplate}
                saveService={ApiProvider.CreateMultiBranch}
                handleValidate={(item) => handleValidate(item)}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
            />
        </div>
    );
};

const ImportPop = ({ open, setOpen, setReload, templateUrl, saveService ,handleValidate,errorMsg,setErrorMsg}) => {
    const [loading, setloading] = useState(false);
    const [items, setItems] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [fileCount, setFileCount] = useState('Step 2. Upload File');
    const handleCancel = () => {
        setOpen(false);
        setItems([]);
        setFileList([]);
        setFileCount(`Step 2. Upload File`);
        setErrorMsg([]);
    };

    const handleOk = () => {
        console.log(items)
        if (items.length === 0) {
            Message.error('Please Upload files !')
            return
        }
        async function CreateMultiData() {
            saveService(items)
                .then(res => {
                    setReload(true);
                    setloading(false)
                    setOpen(false)
                    Message.success('Import success')
                })
                .catch(err => {
                    Message.error('Import error' + err)
                    setloading(false)
                });
        }
        CreateMultiData();
    }
    const onImportExcel = files => {
        const fileReader = new FileReader();
        fileReader.onload = ev => {
            try {
                var d = ev.target.result,
                    workbook = XLSX.read(d, {
                        type: 'binary'
                    }),
                    persons = [];
            } catch (e) {
                Message.error('error' + e)
                return;
            }
            var fromTo = '';
            for (var sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    fromTo = workbook.Sheets[sheet]['!ref'];
                    console.log(fromTo);
                    persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    break;
                }
            }

            if (!handleValidate(persons)) {
                setFileList([]);
                console.log('Error');
                return
            }
            console.log('setItems');
            console.log(persons);
            setItems(persons)
            setFileCount(`Total :  ${persons.length} items`);


        };

        // 以二進制方式打開文件
        fileReader.readAsBinaryString(files);
    };

    const props = {
        accept: ".xlsx, .xls, .csv",
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setFileCount(`Step 2. Upload File`);
        },
        beforeUpload: file => {
            setloading(true)
            setFileList([file]) //只能上傳一筆
            onImportExcel(file)
            setloading(false)
            return false;
        },

        fileList
    };

    return (
        <Modal
            title='Upload'
            width={800}
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                <Button key="OK" type="primary" onClick={handleOk}>Import</Button>,

            ]}>
            <Row>
                <Descriptions layout="vertical" column={12} >
                    <Descriptions.Item label="Step 1. Download Template" span={6}>
                        <Button type='danger' href={templateUrl} icon={<DownloadOutlined />}>Download</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label={fileCount} span={6}>
                        <Upload {...props}>
                            <Button type="danger" icon={<UploadOutlined />} loading={loading}>Upload
                            </Button>
                        </Upload>
                    </Descriptions.Item>
                    <Descriptions.Item span={12}>
                    {errorMsg.map(i=>{
                                return <div>{i}</div>
                            })}
                    </Descriptions.Item>

                </Descriptions>
            </Row>
            <Row>
            </Row>
        </Modal>
    )
}
