import React, { useState, useEffect } from 'react';
import { Popover, Descriptions, Button, Input } from 'antd';
import Info from '../../image/ic_information.svg';
import RecipeCard from './RecipeCard';
import * as ApiProvider from '../../Api/ApiProvider';

const RecipeInfoIcon = ({ recipe }) => {
    const [visible, setValue] = useState(false);
    const handleVisibleChange = (v) => {
        setValue(v)
    };
    return (
        <Descriptions.Item span={12}>
            <Popover
                placement="topLeft"
                content={<div  style={{ width: '500px' }}><RecipeCard recipe={recipe} /></div>}
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}>
                <Button type="link"><img src={Info} alt="" /></Button>
            </Popover>
        </Descriptions.Item>
    )
}


const RecipeSearchInfo = (props) => {
    const [deviceInfo, setDeviceInfo] = useState({});

    useEffect(() => {
        setDeviceInfo({})
        async function fetchData() {
            if (props.sn) {
                fetchDeviceDetails(props.sn)
            }
        }
        fetchData();
      
    }, [props.sn]);
    const fetchDeviceDetails = (sn) => {
        ApiProvider.GetDeviceDetails({ sn: sn })
            .then(res => {
                setDeviceInfo(res.data)
            });
    }

    const items = [
        {
            label: "Recipe A",
            value: deviceInfo.recipeA
        },
        {
            label: "Recipe B",
            value: deviceInfo.recipeB
        },
        {
            label: "Recipe C",
            value: deviceInfo.recipeC
        },

    ]
    return (
        <>
            <Descriptions title="Device Recipe Information" column={12} >
                <Descriptions.Item label={items[0].label} span={12}><RecipeInfoIcon recipe={items[0].value} /></Descriptions.Item>
                <Descriptions.Item span={6}><Input value={items[0].value?.text} /></Descriptions.Item>
                <Descriptions.Item span={6}></Descriptions.Item>
                <Descriptions.Item label={items[1].label} span={12}><RecipeInfoIcon recipe={items[1].value} /></Descriptions.Item>
                <Descriptions.Item span={6}><Input value={items[1].value?.text} /></Descriptions.Item>
                <Descriptions.Item span={6}></Descriptions.Item>
                <Descriptions.Item label={items[2].label} span={12}><RecipeInfoIcon recipe={items[2].value} /></Descriptions.Item>
                <Descriptions.Item span={6}><Input value={items[2].value?.text} /></Descriptions.Item>
                <Descriptions.Item span={6}></Descriptions.Item>
            </Descriptions>

        </>
    )
}

export default RecipeSearchInfo;