import React from 'react';
import 'antd/dist/antd.css';
import { Descriptions } from 'antd';
import RoastLevel from '../../image/RoastLevel'
import GrindSize from '../../image/GrindSize'

const RecipeDetailInfo = ({ recipeInfo }) => {
    return (
        <>
            <Descriptions title="Recipe" layout="vertical" column={12} >
                <Descriptions.Item span={12}><div className="detailLabel">Coffee Bean</div></Descriptions.Item>
                <Descriptions.Item span={12}>{recipeInfo.coffeeBean}</Descriptions.Item>
                <Descriptions.Item span={12}><div className="detailLabel">Basic</div></Descriptions.Item>
                <Descriptions.Item label="Brew Ratio" span={4}>{recipeInfo.brewRatio}</Descriptions.Item>
                <Descriptions.Item label="Ground Coffee" span={4}>{recipeInfo.groundCoffee}g</Descriptions.Item>
                <Descriptions.Item label="Cold Water" span={4}>{recipeInfo.coldWater}ml</Descriptions.Item>
                <Descriptions.Item span={12}><div className="detailLabel">Fredda</div></Descriptions.Item>
                <Descriptions.Item label="Stir Strength" span={3}>{recipeInfo.stirStrength}</Descriptions.Item>
                <Descriptions.Item label="Stir Period" span={3}>{recipeInfo.stirPeriod}s</Descriptions.Item>
                <Descriptions.Item label="Steeping Period" span={3}>{recipeInfo.steepingPeriod}s</Descriptions.Item>
                <Descriptions.Item label="Vacuum Level" span={3}>{recipeInfo.vacuumLevel}</Descriptions.Item>
                <Descriptions.Item span={12}><div className="detailLabel">Others</div></Descriptions.Item>
                <Descriptions.Item label="RoastLevel" span={6}>
                    <div><RoastLevel roastLevel={recipeInfo.roastLevel} /></div>
                    <div> {recipeInfo.roastLevel}</div>
                </Descriptions.Item>
                <Descriptions.Item label="GrindSize" span={6}>
                    <div><GrindSize grindSize={recipeInfo.grindSize} /></div>
                    <div> {recipeInfo.grindSize}</div>
                </Descriptions.Item>
                <Descriptions.Item span={12}><div className="detailLabel">Flavor and Note</div></Descriptions.Item>
                <Descriptions.Item span={12}>{recipeInfo.flavorNote}</Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default RecipeDetailInfo;
