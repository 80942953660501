import React, { useState } from 'react';
import { Space, Button, Row, Col, Select } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import LabDetails from './LabDetails';
import SearchInput from '../../../Components/SearchInput';
import { SelectedTable ,columnFilterList} from '../../../Components/Table';
import { RemoveButton } from '../../../Components/Button';
import { schema } from './LabSetting';
import { formatDateTime } from '../../../Utils/Utils'
const LabTable = () => {
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [reload, setReload] = useState(false);
    //#region  condition
    const [search, setSearch] = useState({
        searchColumn: null,
        searchWord: ''
    });
    //#endregion
    //#region Column

    const openDetails = (e) => {
        setOpenEdit(true)
        setSelectSingleRow(e.row)
    }

    const columns = [
        {
            title: schema.mode.label,
            dataIndex: schema.mode.name,
        },
        {
            sorter: true,
            title: schema.testDateTime.label,
            dataIndex: schema.testDateTime.name,
            render: (value, row, index) => {
                return (<Button type="link" onClick={() => openDetails({ row })}>{formatDateTime(value)}</Button>)
            }
        },
        {
            title: schema.firmwareVersion.label,
            dataIndex: schema.firmwareVersion.name,
        },
        {
            title: schema.serialNumber.label,
            dataIndex: schema.serialNumber.name,
        },
        {
            title: schema.coffeeBean.label,
            dataIndex: schema.coffeeBean.name,
        },
        {
            title: schema.result.label,
            dataIndex: schema.result.name,
        },
        {
            title: schema.remark.label,
            dataIndex: schema.remark.name,
        },
        {
            title: '填單時間',
            dataIndex: 'createdDate',
            render: (value, row, index) => {

                return (<div>{formatDateTime(value)}</div>)
            }
        },
        {
            title: '填單人',
            dataIndex: 'createdUserName',
        },
        
    ];
    //#endregion
    function handleMenuClick(e) {
        setSearch({
            ...search,
            searchColumn: e
        })
    }

    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select placeholder='Select filter column' options={columnFilterList(columns)} onSelect={(e) => handleMenuClick(e)}>
                        </Select>
                    </div>
                    <div>
                        <SearchInput onPressEnter={(value) => setSearch({ ...search, searchWord: value })} />
                    </div>
                </Space>
            </Col>
            <Col>
                <Space>
                    <Button type="primary" style={{ display: (selectedRows.length === 0 ? "" : "none") }} onClick={() => setOpenNew(true)}>New</Button>
                    <RemoveButton label="Remove" service={ApiProvider.RemoveTestReport} param={selectedRows} setReload={setReload} style={{ display: (selectedRows.length > 0 ? "" : "none") }} />
                </Space>
            </Col>
        </Row>
    )
    //#endregion

    const tableProps = {
        rowKey: 'coffeeTestId',
        ApiProviderUrl: ApiProvider.GetTestReport,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns.filter(i => (i.display === undefined) ? true : i.display),
        selectedRowKeys: selectedRowKeys,
        reload: reload,
        setReload: setReload,
        setSelectedRows: (e) => setSelectedRows(e),
        setSelectSingleRow: (e) => setSelectSingleRow(e),
        setSelectedRowKeys: (e) => setSelectedRowKeys(e),
    }

    return (
        <div>
            <SelectedTable {...tableProps} />
            <LabDetails title='Edit' open={openEdit} setOpen={(isOpen) => setOpenEdit(isOpen)} setReload={(isReload) => setReload(isReload)} selected={selectSingleRow} saveService={ApiProvider.UpdateTestReport} />
            <LabDetails title='New' open={openNew} setOpen={(isOpen) => setOpenNew(isOpen)} setReload={(isReload) => setReload(isReload)} selected={[]} saveService={ApiProvider.CreateTestReport} />
        </div>
    );
};

export default LabTable;

