import React, { useState } from 'react';
import { Space, Button, Row, Col, Select, Tooltip } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { isEqual } from 'lodash'
import AccountBrandDetails from './AccountBrandDetails';
import { ImportAccountBrand } from '../../../Components/ImportPop';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SystemAdmin, Authority, AuthorityhasLable } from '../../../image/Authority';
import SearchInput from '../../../Components/SearchInput';
import { CountryStateCitySelector } from '../../../Components/CountryStateCitySelector';
import { RolesDetails, Roles } from '../../../Settings/Roles';
import { SelectedTable,columnFilterList } from '../../../Components/Table';
import { RemoveButton } from '../../../Components/Button';
import { getRoles } from '../../../Utils/session';

const ExclamationCircle = prop => (
    <Tooltip title={prop.tooltips} >
        <ExclamationCircleOutlined style={{ color: "red" }} />
    </Tooltip>
)
const AccountBrandTable = () => {
    const roles = getRoles();
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [reload, setReload] = useState(false);
    const [showCountryStateCitySelector, setShowCountryStateCitySelector] = useState(false);
    //#region  condition
    const [search, setSearch] = useState({
        branchIds: [],
        searchColumn: 'userName',
        searchWord: ''
    });
    //#endregion

    //#region Column
    const handleClickName = (e) => {
        setOpenEdit(true)
        setSelectSingleRow(e.row)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            width: 1,
            render: (value, row, index) => {
                return row.roleList.includes(RolesDetails.SystemAdmin.name) ? <SystemAdmin /> : <></>
            }
        },
        {
            isFilter: true,
            title: 'User Name',
            dataIndex: 'userName',
            render: (value, row, index) => {
                return (
                    <Button type="link" onClick={() => handleClickName({ row })}>{value}</Button>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value, row, index) => {
                return (!isEqual(row.accountStatus, 'OK') ? <><ExclamationCircle tooltips={row.accountStatus} /> {value}</> : <>{value}</>
                )
            }
        },
        {
            isFilter: true,
            title: 'Located Store',
            dataIndex: 'branchName',
        },
        {
            title: 'Authority',
            width: 270,
            align: 'center',
            render: (value, row, index) => {
                return row.roleList.filter(i => !isEqual(i, RolesDetails.SystemAdmin.keys)).map(i => <Authority key={i} type={i} />)
            }
        },
    ];
    //#endregion

    function handleMenuClick(e) {
        setShowCountryStateCitySelector(isEqual(e, 'country'))
        setSearch({
            ...search,
            searchColumn: isEqual(e, 'country') ? '' : e
        })

    }
    const handleCountryStateCitySelectorChange = (value, selectedOptions) => {
        const column = ['country', 'state', 'city'];
        ApiProvider.GetBranchIdByAddress({
            column: column[value.length - 1],
            word: value[value.length - 1]
        }).then(res => {
            setSearch({
                ...search,
                searchColumn: 'branchId',
                branchIds: res.data,

            })
        })
    };

    const handlePressEnter = (value) => {
        setSearch({ ...search, searchWord: value })
    }
    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select dropdownMatchSelectWidth={false} placeholder='Select filter column' defaultValue={search.searchColumn} options={columnFilterList(columns).concat({ value: 'country', label: 'By Country/Region' })} onSelect={(e) => handleMenuClick(e)}>
                        </Select>
                    </div>
                    <div style={{ display: showCountryStateCitySelector ? 'none' : '' }}>
                        <SearchInput onPressEnter={handlePressEnter} />
                    </div>
                    <div style={{ display: showCountryStateCitySelector ? '' : 'none' }}>
                        <CountryStateCitySelector onChange={handleCountryStateCitySelectorChange} />
                    </div>
                </Space>
            </Col>
            <Col>
                <Space>
                    <Button type="primary" style={{ display: "none" }} onClick={() => setOpenImport(true)}>Import Account</Button>
                    <Button type="primary" style={{ display: (selectedRows.length === 0 && roles.includes(Roles.BrandAdmin) ? "" : "none") }} onClick={() => setOpenNew(true)}>New Account</Button>
                    <RemoveButton
                        label="Delete"
                        service={ApiProvider.RemoveAccountBrand}
                        param={selectedRows}
                        setReload={setReload}
                        style={{ display: (selectedRows.length > 0 ? "" : "none") }}
                        confirmMsg={`Are you sure to Delete "${selectedRows?.map(i => i.userName)?.join('","')}" Account?`}
                    />
                </Space>
            </Col>
        </Row>
    )
    //#endregion

    const tableProps = {
        rowKey: 'userId',
        ApiProviderUrl: ApiProvider.GetAccountGridBrand,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        selectedRowKeys: selectedRowKeys,
        reload: reload,
        setReload: setReload,
        setSelectedRows: (e) => setSelectedRows(e),
        setSelectSingleRow: (e) => setSelectSingleRow(e),
        setSelectedRowKeys: (e) => setSelectedRowKeys(e),
    }
    return (
        <div>
            <SelectedTable {...tableProps} />
            <Row style={{ fontSize: '12px' }}>
                <Col span={4}><SystemAdmin />System Admin</Col>
                <Col>Authority Note :</Col>
                {["BrandOperationMgr", "BranchAdmin", "DeviceAdmin", "Barista"].map(i => <Col style={{ margin: '0px 10px' }}><AuthorityhasLable key={i} type={i} ></AuthorityhasLable></Col>)}
            </Row>
            <AccountBrandDetails title='Edit' isEdit={true} open={openEdit} setOpen={(isOpen) => setOpenEdit(isOpen)} setReload={(isReload) => setReload(isReload)} selected={selectSingleRow} saveService={ApiProvider.UpdateAccountBrand} />
            <AccountBrandDetails title='Create' isEdit={false} open={openNew} setOpen={(isOpen) => setOpenNew(isOpen)} setReload={(isReload) => setReload(isReload)} selected={[]} saveService={ApiProvider.CreateAccountBrand} />
            <ImportAccountBrand open={openImport} setOpen={(isOpen) => setOpenImport(isOpen)} setReload={(isReload) => setReload(isReload)} />
        </div >
    );
};
export default AccountBrandTable;
