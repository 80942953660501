import React from 'react';

const brandPicUrl = "https://rapibrewstorage.blob.core.windows.net/welcome-ui/chain store";
const originPicUrl = "https://rapibrewstorage.blob.core.windows.net/welcome-ui/original supplier";

export const Brand = [
    {
        page: 1,
        title: "Account management",
        subTitle: <>If the account currently logged in is <b>Not</b> the one to manage system authority for your company,<br />please create another new <b>"System admin"</b> aacount in <b>"ACCOUNT"</b> feature.</>,
        desc: "Create user account and assign proper authority.",
        img: `${brandPicUrl}/1.png`
    },
    {
        page: 2,
        title: "Account management",
        desc: "5 major built-in roles define different types of authority. Only authorized users will be able to modify FREDDA setting in stores.",
        img: `${brandPicUrl}/2.png`
    },
    {
        page: 3,
        title: "System Admin",
        desc: "Be able to create branch & user account.",
        img: `${brandPicUrl}/3.png`
    },
    {
        page: 4,
        title: "Operation Manager",
        desc: "The highest level of authority who can access the Report feature for the analytic report of FREDDA brewing.",
        img: `${brandPicUrl}/4.png`
    },
    {
        page: 5,
        title: "Branch Admin",
        desc: "Assign any of your stores to the account and who will be able to check the arrangement of FREDDAs & recipes under all branches.",
        img: `${brandPicUrl}/5.png`
    },
    {
        page: 6,
        title: <>Report 1<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Assign any of your FREDDAs to the account, Even cross-store!",
        img: `${brandPicUrl}/6.png`
    },
    {
        page: 7,
        title: <>Report 2<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Report will be able to monitor all real-time status and detail data of your FREDDAs around the world.",
        img: `${brandPicUrl}/7.png`
    },
    {
        page: 8,
        title: <>Report 3<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Also, you can check recipe assignment of devices all the time.",
        img: `${brandPicUrl}/8.png`
    },
    {
        page: 9,
        title: <>Barista 1<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Remote recipe management-To review all the recipes",
        img: `${brandPicUrl}/9.png`
    },
    {
        page: 10,
        title: <>Barista 2<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Remote recipe management-Be able to assign selected recipe to the FREDDAs in all stores worldwide.",
        img: `${brandPicUrl}/10.png`
    },
    {
        page: 11,
        title: <>Barista 3<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Furthermore, understanding the statistical analysis of the recipes thru Recipe Reports.",
        img: `${brandPicUrl}/11.png`
    },
    {
        page: 12,
        title: "User Authentication",
        desc: "Assigned user will receive an activation email. Follow the instructions in the email to activate your account and access to the system & APP.",
        img: `${brandPicUrl}/12.png`
    },
    {
        page: 13,
        title: "Upgrade your FREDDA",
        desc: "Authorized users may be able to log in APP with activated account and connect FREDDA thru APP to make necessary FW upgrade.",
        img: `${brandPicUrl}/13.png`
    },
    {
        page: 14,
        title: "Create Branch",
        desc: "Quick snapshot of all your branches & corresponding device status thru branch dashboard.",
        img: `${brandPicUrl}/14.png`
    },
    {
        page: 15,
        title: "Create Branch",
        desc: "Start to manage your branches by creating new branch information.",
        img: `${brandPicUrl}/15.png`
    },
]
export const Origin = [
    {
        page: 1,
        title: <>Report 1<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Review all customers’ recipes from recipe dashboard.",
        img: `${originPicUrl}/1.png`
    },
    {
        page: 2,
        title: <>Report 2<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "You can review detailed information of any recipe by clicking recipe name.",
        img: `${originPicUrl}/2.png`
    },
    {
        page: 3,
        title: <>Device 3<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "The statistical report indicates the number of key values and trends of the recipes.",
        img: `${originPicUrl}/3.png`
    },
    {
        page: 4,
        title: <>Device 1<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "Device dashboard provides various related information of all devices.",
        img: `${originPicUrl}/4.png`
    },
    {
        page: 5,
        title: <>Device 2<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "To review detailed device information such as basic information, operation logs and currently assigned recipes by clicking the device name on the device dashboard.",
        img: `${originPicUrl}/5.png`
    },
    {
        page: 6,
        title: <>Device 3<span style={{ fontSize: '14px' }}>/3</span> </>,
        desc: "You can upload the latest versions of FW in Firmware Upload tab to trigger OTA updates.",
        img: `${originPicUrl}/6.png`
    },
    {
        page: 7,
        title: "Customer",
        desc: "Customer dashboard splits all customers into 2 parts, General customer and customers who subscribe for advanced service will be listed in “Advanced customer” tab.",
        img: `${originPicUrl}/7.png`
    },
    {
        page: 8,
        title: <>Account 1<span style={{ fontSize: '14px' }}>/2</span> </>,
        desc: "Account dashboard provides overview of all subscription stats of accounts.",
        img: `${originPicUrl}/8.png`
    },
    {
        page: 9,
        title: <>Account 2<span style={{ fontSize: '14px' }}>/2</span> </>,
        desc: "By creating new accounts or edit current accounts to subscribe or unsubscribe for advanced service by customers’ needs.",
        img: `${originPicUrl}/9.png`
    },
    {
        page: 10,
        title: <>Service Request 1<span style={{ fontSize: '14px' }}>/2</span> </>,
        desc: "All the issues reported from the customers will be listed systematically under “Service request”. There are 4 indicators showing the latest status of the requests.",
        img: `${originPicUrl}/10.png`
    },
    {
        page: 11,
        title: <>Service Request 2<span style={{ fontSize: '14px' }}>/2</span> </>,
        desc: "You can review the detailed information and update the status by clicking the SN column.",
        img: `${originPicUrl}/11.png`
    },
]
