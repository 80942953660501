import React, { useEffect, useState } from 'react';
import { Row, Select, Descriptions, TreeSelect, Form, Tag } from 'antd';
import { isEmpty } from 'lodash';
import { DescriptionsSelet } from './Descriptions'
import { UploadOutlined } from '@ant-design/icons';
import * as ApiProvider from '../Api/ApiProvider';
import { Status } from '../Pages/Content/Device/ShowDeviceStatus'
import {
    SyncOutlined,
} from '@ant-design/icons';
import {recipeIndex} from '../CoffeeCloudConfig';
const { SHOW_CHILD } = TreeSelect;

//Branch select tree 與 Recipe select A/B/C
const BranchTreeAndRecipe = ({ id, branchList, existRecipe = [], onChangeRecipe = () => { } }) => {
    const filteredOptions = recipeIndex.filter(o => !existRecipe.includes(o)).map(i => ({ value: i, label: i }));

    const checkboxdOptions = (branchList.length > 0 ? [{
        title: 'Select all',
        value: 'all',
        key: 'all',
        children: groupByBranch(branchList, function (item) {
            //要group的
            return item.groupId || 'No';
        })
    }
    ] : [])

    function groupByBranch(array, f) {
        let groups = {};
        array.forEach(function (o) {
            let group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);

        });
        return Object.keys(groups).map(function (group) {
            return {
                title: isEmpty(groups[group][0].groupId) ? '無分店' : groups[group][0].groupName,
                value: isEmpty(groups[group][0].groupId) ? 'groupId' : groups[group][0].groupId,
                key: groups[group][0].groupId,
                children: groups[group]
            }
        });
    }
    function TagRender(props) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const tagIconAndColor = {
            online: {
                icon: Status.OnlineImg,
                color: "default"
            },
            processing: {
                icon: <SyncOutlined spin />,
                color: "processing"
            },
            offline: {
                icon: Status.OfflineImg,
                color: "default"
            },
        };
        const { label, value, closable, onClose } = props;
        const [tag, setTag] = useState(tagIconAndColor.processing);

        useEffect(() => {
            async function fetchData() {
                ApiProvider.GetIsDeviceConnected(value)
                    .then(res => {
                        setTag(res.data ? tagIconAndColor.online : tagIconAndColor.offline)
                    });
            }
            fetchData();
             // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        return (
            <Tag
                icon={tag.icon}
                color={tag.color}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }
    return (
        <>
            <Row>
                <Descriptions title="Assign to" layout="vertical" column={2}>
                    <Descriptions.Item label="Store">
                        <Form.Item name={[id, "deviceIdList"]} style={{ width: '100%' }} rules={[{ required: true, message: "Please select device!" }]}>
                            <TreeSelect
                                disabled={checkboxdOptions.length === 0}
                                labelInValue
                                allowClear={true}
                                showArrow={true}
                                maxTagCount={0}
                                placeholder={checkboxdOptions.length === 0 ? <>Not found device</> : <>Please select device</>}
                                treeData={checkboxdOptions}
                                treeCheckable={true}
                                showCheckedStrategy={SHOW_CHILD}
                            />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label="Recipe">
                        <DescriptionsSelet
                            required={true}
                            name={[id, "recipeIndex"]}
                            label="Recipe"
                            list={filteredOptions}
                            dropdownMatchSelectWidth={false}
                            onChange={onChangeRecipe} />
                    </Descriptions.Item>
                    <Descriptions.Item >
                        <Form.Item name={[id, "deviceIdList"]} >
                            <Select
                                dropdownMatchSelectWidth={false}
                                tagRender={TagRender}
                                labelInValue
                                bordered={false}
                                mode="multiple"
                                open={false}
                                style={{ width: '100%' }}
                                options={branchList}
                                clearIcon={<UploadOutlined />}
                            >
                            </Select>
                        </Form.Item>
                        <div style={{ display: 'inline', fontSize: '12px' }}>Device Status :</div>
                        <div style={{ display: 'inline' }}>{Status.OnlineIconImg}</div>
                        <div style={{ display: 'inline', fontSize: '12px' }}>{Status.Online}</div>
                        <div style={{ display: 'inline' }}> {Status.OfflineIconImg}</div>
                        <div style={{ display: 'inline', fontSize: '12px' }}>{Status.Offline}</div>
                    </Descriptions.Item>
                </Descriptions>

            </Row>
        </>
    )
}
export default BranchTreeAndRecipe;