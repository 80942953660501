import axios from 'axios';
import { notification } from 'antd';
import { getToken } from '../Utils/session';
import { ServerSetting } from '../CoffeeCloudConfig';
import { isEqual } from 'lodash'
axios.defaults.baseURL = ServerSetting.baseURL;
axios.defaults.headers.common['api-key'] = ServerSetting.apiKey;

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  let errorMsg = (error.response) ? error.response.data.msg : error.message;

  if (error.response && error.response.status === 502) {
    errorMsg = "Sorry! Our system is currently busy, please try again later."
  }
  if (!errorMsg || errorMsg === '') {
    return; //沒有錯誤訊息就不要跳視窗
  }
  notification.error({
    message: errorMsg,
    placement: 'bottomLeft'
  })

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
}


);
//CheckContent
export function CheckContent() {
  return axios.get("")
}

//AccountLogin
export function AccountLogin(body) {
  return axios.post("api/v1/account/login", body)
}
//AccountLogout
export function AccountLogout() {
  return axios.post("api/v1/account/logout", { jwt: getToken() })
}
//ForgetPassword
export function ForgetPassword(body) {
  return axios.post("api/v1/account/forgetPassword", body)
}
//ResetPassword
export function ResetPassword(body) {
  return axios.post("api/v1/account/forgetPasswordReset", body)
}
//VerifyResetPasswordUrl
export function VerifyResetPasswordUrl(encryptedString) {
  return axios.get("api/v1/account/forgetPasswordExpired?encryptedString=" + encryptedString)
}
//ChangePassword
export function ChangePassword(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/account/updatePasswordOrigin", body)
}

//CheckToken
export function CheckToken() {
  return axios.get("api/v2/auth/checkToken", {
    headers: {
      jwt: getToken() //the token is a variable which holds the token
    }
  }
  )
}
//GetRecipeList
export function GetRecipeDataSource(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/recipeWeb/getRecipeList", body)
}

//GetRecipeDetails
export function GetRecipeDetails(recipeId) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get(`api/v2/recipe/getRecipeDetails?recipeId=${recipeId}`)
}

//GetBranchByRecipe
export function getDeviceByRecipe(recipeId) {
  axios.defaults.headers.common['jwt'] = getToken();
  const body = {
    recipeId: recipeId
  }
  return axios.post('api/v2/recipeWeb/getDeviceByRecipe', body)
}

//AssignRecipe
export function AssignRecipe(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/recipeWeb/assignRecipe", body)
}
//MultiAssignRecipe
export function MultiAssignRecipe(body) {
  const array = [];
  body.forEach(function (item) {
    array.push(axios.post("api/v2/recipeWeb/assignRecipe", item));
  })
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array).then(axios.spread((...args) => args))
}

//GetDeviceStatus
export function GetDeviceStatus(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post('api/v2/recipeWeb/assignRecipeCheck', body)
}

//RemoveRecipe (多筆)
export function RemoveRecipe(items) {
  const array = [];
  items.forEach(function (item) {
    array.push(axios({
      method: 'DELETE',
      url: 'api/v2/recipeWeb/removeRecipe',
      data: {
        recipeId: item.recipeId
      }
    }))
  });

  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array);
}

//RecoverRecipe (多筆)
export function RecoverRecipe(items) {
  const array = [];
  items.forEach(function (item) {
    array.push(axios.post("api/v2/recipeWeb/recoveryRecipe", { recipeId: item.recipeId }));
  })
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array).then(axios.spread((...args) => args))
}

//SearchRecipeList
export function SearchRecipeList(searchWord) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post(`api/v2/recipeWeb/searchRecipeList?searchWord=${searchWord}`)
}


//GetDeviceDataSource
export function GetDeviceDataSource(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/deviceWeb/getDeviceList", body)
}

//GetDeviceDataSource
export function GetDeviceTreeList() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/deviceWeb/getDeviceTreeList")
}
//GetDeviceDetails
export function GetDeviceDetails(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/deviceWeb/getDeviceDetail", body)
}

//GetBrandSelectItem
export function GetBrandSelectItem() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/brand/getBrandSelectItem")
}

//GetBrandSelectItem
export function GetBrandKeyValue() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/accountWeb/getBranchList")
}
//GetDeviceList
export function GetDeviceList() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/accountWeb/getDeviceList")
}

//GetBranchSelectItemByBrand
export function GetBranchSelectItemByBrand(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/branch/getBranchSelectItemByBrand", body)
}

//GetCleanRecordList
export function GetCleanRecordList(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/deviceWeb/getCoffeeRoundList", body)
}

//GetBranchDataSource
export function GetBranchDataSource(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/branch/getMyBranchGrid", body)
}
//UpdateBranch
export function UpdateBranch(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/branch/editBranch", body)
}

//CreateBranch
export function CreateBranch(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/branch/createBranch", body)
}

//CreateMultiBranch (多筆)
export function CreateMultiBranch(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/branch/createMultiBranch", body)
}

//RemoveBranch (多筆)
export function RemoveBranch(items) {
  return axios.post("api/v2/branch/removeBranch", { ids: items.map(i => i.branchId) })
}

//firmware
export function GetFMUploadList(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/firmware/getList", body)
}

export function SaveFirmwareVersion(formData) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/firmware/addFirmwareVersion", formData)
}

export function GetAllLocation() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v1/location/getAllLocation")
}

export function GetState(countryISO) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get(`api/v2/location/getState?countryISO=${countryISO}`)
}

export function GetCity(countryISO) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get(`api/v2/location/getCity?countryISO=${countryISO}`)
}

//Log
export function GetLogData(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/device/getDeviceLog", body)
}
//紀錄 Device Log for recipe
export function CreateDeviceLog(body, body2) {
  const array = [];
  body2.fail.forEach(function (item) {
    array.push(axios.post("api/v2/deviceLog/recipe", {
      recipeId: body.recipeId,
      recipeIndex: body.recipeIndex,
      sn: item,
      status: "fail"
    }));
  })
  body2.success.forEach(function (item) {
    array.push(axios.post("api/v2/deviceLog/recipe", {
      recipeId: body.recipeId,
      recipeIndex: body.recipeIndex,
      sn: item,
      status: "success"
    }));
  })
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array).then(axios.spread((...args) => args))
}

//DeviceDetails getLog
export function GetDeviceRecordData(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/deviceLog/getLog", body)
}

//getAbnormalLog
export function GetAbnormalLog(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/deviceLog/getAbnormalLog", body)
}

//Account
export function GetAccountGridBrand(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/getAccountGridBrand", body)
}

//UpdateAccountBrand
export function UpdateAccountBrand(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/editAccountBrand", body)
}

//CreateAccountBrand
export function CreateAccountBrand(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/createAccountBrand", body)
}

//RemoveAccount (多筆)
export function RemoveAccountBrand(items) {
  return axios.post("api/v2/accountWeb/deleteAccountBrand", { ids: items.map(i => i.userId) })
}
//CreateMultiAccount(多筆)
export function CreateMultiAccount(body) {
  GetBranchSelectItemByBrand({
    brand: "",
    search: ""
  })
    .then(res => {
      body.forEach(element => {
        res.data.forEach(r => {
          if (isEqual(r.text, element.store)) {
            element.storeId = r.val
          }
        })
      })
    })
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/createAccountBrandMulti", body)
}

//Account
export function GetAccountGridOrg(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/getAccountGridOrigin", body)
}

//UpdateAccountBrand
export function UpdateAccountOrg(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/editAccountOrigin", body)
}

//CreateAccountBrand
export function CreateAccountOrg(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/createAccountOrigin", body)
}

//RemoveAccount (多筆)
export function RemoveAccountOrg(items) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/deleteAccountBrand", { ids: items.map(i => i.userId) })
}

//CreateMultiAccount(多筆)
export function CreateMultiAccountOrg(items) {
  const body = [];
  items.forEach(item => {
    body.push({
      userId: "",
      userName: item.userName,
      email: item.email,
      brandName: item.brandName,
      tel: item.tel,
      reason: "",
      subscription: true,
      address: {
        zipCode: item.zipCode,
        country: item.country,
        city: item.city,
        state: item.state,
        street: item.street,
      }
    })
  })
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/createAccountOriginMulti", body)
}


export function GetDeviceRecipeByBranch(branchId) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get(`api/v2/deviceWeb/getDeviceRecipeByBranch?branchId=${branchId}`)
}
export function GetBranchIdByAddress(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/branch/getBranchIdByAddress", body)
}

export function CreateFailedRecipe(items) {
  const array = [];
  items.forEach(function (item) {
    array.push(axios.post("api/v2/recipeWeb/addFailedRecipe", item));
  })
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array).then(axios.spread((...args) => args))
}

export function GetFailedRecipe() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/recipeWeb/getFailedRecipe")
}

export function RemoveFailedRecipe(item) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios({
    method: 'DELETE',
    url: 'api/v2/recipeWeb/deleteFailedRecipe ',
    data: {
      uniqueId: item.uniqueId
    }
  })
}
export function ReAssignRecipe(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/recipeWeb/reAssignRecipe", body)
}

export function GetAccountAllGrid(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/getAccountAllGrid", body)
}

export function GetSnUsedStatus(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/vendor/multiVendor", body)
}

export function GetVendorList(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/vendor/getVendorList", body)
}

export function CreateVendor(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/vendor/createVendor", body)
}

export function UpdateVendor(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/vendor/updateVendor", body)
}
//RemoveVendor (多筆)
export function RemoveVendor(items) {
  const array = [];
  items.forEach(function (item) {
    array.push(axios({
      method: 'DELETE',
      url: 'api/v2/vendor/deleteVendor',
      data: {
        vendorId: item.vendorId
      }
    }))
  });

  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array);
}
export function GetRepairList(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/repair/getRepairList", body)
}


export function UpdateRepairStatus(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/repair/updateRepair", body)
}
export function GetTestReport(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/coffeeTestReport/getAllTestReport", body)
}
export function CreateTestReport(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/coffeeTestReport/createTestReport", body)
}
export function UpdateTestReport(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/coffeeTestReport/updateTestReport", body)
}
//RemoveTestReport (多筆)
export function RemoveTestReport(items) {
  const array = [];
  items.forEach(function (item) {
    array.push(axios({
      method: 'DELETE',
      url: 'api/v2/coffeeTestReport/deleteOneTestRepost',
      data: {
        coffeeTestId: item.coffeeTestId,
      }
    }))
  });
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array);
}

//[Device] 檢查機台是否在線上
export function CheckDeviceIsOnline(sn) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get(`api/v2/device/checkDeviceStatus?sn=${sn}`)
}
//[Device] 取得機台的異常列表(未被解決)
export function GetDeviceAbnrmalList(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/deviceWeb/getDeviceAbnrmalList", body)
}
//[Report] GetReportBrewTimes
export function GetReportBrewTimes(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/recipeWeb/getReportBrewTimes", body)
}

//[Report] GetSalesAnalysis
export function GetSalesAnalysis(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/recipeWeb/getSalesAnalysis", body)
}
//[Device] UpdateDeviceDetail
export function UpdateDeviceDetail(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/deviceWeb/updateDeviceDetail", body)
}

//[BranchSelector] GetBranchList
export function GetBranchList() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/branch/getBranchList")
}

//[Account] 取消訂閱
export function CancelSubscription(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/cancelSubscription", body)
}

//[Export] 匯出Excel
export function ExportExcel(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/excel/export", body, { responseType: 'blob' })
}

//[System] 推播功能
export function GetWebNotification(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/firebase/getWebNotification", body)
}

//[System] new 推播功能
export function CreateWebNotification(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/firebase/createWebNotification", body)
}

//[System] update 推播功能
export function UpdateWebNotification(body) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/firebase/updateWebNotification", body)
}

//Remove WebNotification (多筆)
export function RemoveWebNotification(items) {
  const array = [];
  items.forEach(function (item) {
    array.push(axios({
      method: 'DELETE',
      url: 'api/v2/firebase/removeWebNotification',
      data: {
        messageId: item.messageId
      }
    }))
  });
  return axios.all(array);
}
//[System] push 推播功能 (指定對象)
export function PushWebNotification(body) {
  axios.defaults.headers.common['jwt'] = getToken();

  return axios.post("api/v2/firebase/pushWebNotification", {
    ...body,
  })
}
//[System] push 推播功能（廣發）
export function PushAllWebNotification(body) {
  axios.defaults.headers.common['jwt'] = getToken();

  return axios.post("api/v2/firebase/pushAllWebNotification", {
    ...body,
  })
}

//Account 下拉選單(使用中的)
export function GetAccountList() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/accountWeb/getAccountList")
}
//Account
export function CheckAccountEmail(email) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.post("api/v2/accountWeb/checkCreateAccountOrigin", { email: email })
}

//GetServiceSite
export function GetServiceSiteKeyValue() {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/vendor/getVendorKeyValue")
}
//GetBrandSelectItem
export function GetIsDeviceConnected(sn) {
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.get("api/v2/deviceWeb/isDeviceConnected?sn=" + sn)
}

//GetMultiIsDeviceConnected
export function GetMultiIsDeviceConnected(snList) {
  const array = [];
  snList.forEach(function (sn) {
    array.push(GetIsDeviceConnected(sn))
  })
  axios.defaults.headers.common['jwt'] = getToken();
  return axios.all(array).then(axios.spread((...args) => args))
}
