


import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

export const DateRangePickers = ({ handleChangeDate, startDate, endDate }) => {
    //日期選擇邏輯 : 不超過今天
    function disabledDate(current) {
        return current > moment().endOf('day');
    }
    return <RangePicker
        placeholder={["Select start date", "Select end date"]}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        onChange={handleChangeDate}
        showToday
        defaultValue={[moment(startDate), moment(endDate)]}
    />
}