import React from 'react';
import { Row, Col, Image } from 'antd';

//Welcome 內容主結構
export const WelcomeContent = ({ id, data }) => {
    return data.filter(content => content.page === id).map(content => (
        <div key={content.page}>
            {/* <Row><Col><div style={{ backgroundColor: "#C1AA92", borderRadius: '16px', color: '#F5F2EF', fontSize: '14px', margin: '0px 0px 10px 0px', padding: '0 10px' }}>{content.step}</div></Col></Row> */}
            {/* 標題 */}
            <Row><Col><div style={{ margin: '10px 0px', color: '#0A496A', fontSize: '20px', fontWeight: 'bold', letterSpacing: '0px' }}>{content.title}</div></Col></Row>
            {/* 警示標語 */}
            {content.subTitle ?
                <Row><Col><div style={{ color: '#845D66', fontSize: '14px', letterSpacing: '0px', borderRadius: '4px', borderWidth: 'thin', borderColor: '#845D66', borderStyle: 'solid', padding: '5px 20px', textAlign: 'left', margin: '5px 0px' }}>{content.subTitle}</div></Col></Row>
                : <></>}
            {/* 內容 */}
            <Row><Col><div style={{ textAlign: 'left', color: '#404040', fontSize: '14px' }}>{content.desc}</div></Col></Row>
            {/* 說明圖片 */}
            <Row style={{ margin: '10px 10px' }}><Col><Image src={content.img} alt="" /></Col></Row>
        </div>
    )
    )
}
