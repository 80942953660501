import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Space, Button, Modal } from 'antd';
import * as Charts from './Charts';
import { formatDateTimeQ } from '../../../Utils/Utils';
import * as ApiProvider from '../../../Api/ApiProvider';
import { DateRangePickers } from '../../../Components/DateRangePicker';
import { CountryBranchTree } from '../../../Components/BranchSelect';
import RecipeCard from '../../../Components/RecipeDetailCard/RecipeCard';
import { exportPDF } from '../../../Utils/Export';
import { type } from '../../../CoffeeCloudConfig';

const { TabPane } = Tabs;

const SalesReport = () => {
    const [open, setOpen] = useState(false);
    const [exportdiv, setExport] = useState(false);
    const [recipe, setRecipe] = useState(null);

    const [branchList, setbranchList] = useState([]);
    const [date, setDateTime] = useState({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)), //一個月的資料
        endDate: new Date(),
    });

    const [reportBrewTimesData, setReportBrewTimesData] = useState({
        data: [],
        total: 0
    });

    const [salesAnalysisData, setSalesAnalysis] = useState({
        data: [],
        total: 0
    });
    const [key, setKey] = useState(type[0]);
    const [search, setSearch] = useState({
        top: 0,
        startDate: date.startDate,
        endDate: date.endDate,
        branchIds: branchList,
        groupBy: type[0]
    });

    const [reportBrewTimesPDF, setReportBrewTimesPDF] = useState({
        reportBrewTimesRecipe: {
            data: [],
        },
        reportBrewTimesDevice: {
            data: [],
        }
    });

    const [salesAnalysisPDF, setSalesAnalysisPDF] = useState({
        salesAnalysisRecipe: {
            data: [],
        },
        salesAnalysisDevice: {
            data: [],
        }
    });

    const [searchPDF, setSearchPDF] = useState({
        searchRecipe: {
            top: 0,
            startDate: date.startDate,
            endDate: date.endDate,
            branchIds: branchList,
            groupBy: type[0],
        },
        searchDevice: {
            top: 0,
            startDate: date.startDate,
            endDate: date.endDate,
            branchIds: branchList,
            groupBy: type[1],
        }
    });

    useEffect(() => {
        ApiProvider.GetReportBrewTimes(searchPDF.searchRecipe)
        .then(res => {
            setReportBrewTimesPDF((prevOption) => {
                return {
                    ...prevOption,
                    reportBrewTimesRecipe: res.data
                }
            });
        })

        ApiProvider.GetReportBrewTimes(searchPDF.searchDevice)
        .then(res => {
            setReportBrewTimesPDF((prevOption) => {
                return {
                    ...prevOption,
                    reportBrewTimesDevice: res.data
                }
            });
        })

        ApiProvider.GetSalesAnalysis(searchPDF.searchRecipe)
        .then(res => {
            setSalesAnalysisPDF((prevOption) => {
                return {
                    ...prevOption,
                    salesAnalysisRecipe: res.data
                }
            });
        })

        ApiProvider.GetSalesAnalysis(searchPDF.searchDevice)
        .then(res => {
            setSalesAnalysisPDF((prevOption) => {
                return {
                    ...prevOption,
                    salesAnalysisDevice: res.data
                }
            });
        })
    }, [searchPDF.searchRecipe, searchPDF.searchDevice]);

    useEffect(() => {
        setReportBrewTimesData({
            data: [],
            total: 0
        })
        ApiProvider.GetReportBrewTimes(search)
            .then(res => {
                setReportBrewTimesData(res.data)
            })

        ApiProvider.GetSalesAnalysis(search)
            .then(res => {
                setSalesAnalysis(res.data)
            })
    }, [search])

    function handleChangeDate(dates, dateStrings) {
        setDateTime({
            startDate: dateStrings[0],
            endDate: dateStrings[1],
        })
        setSearch({
            ...search,
            startDate: dateStrings[0],
            endDate:  dateStrings[1],
        })

        setSearchPDF({...searchPDF,
            searchRecipe: {
                startDate: dateStrings[0],
                endDate:  dateStrings[1],
                branchIds: branchList,
                groupBy: type[0],
            },
            searchDevice: {
                startDate: dateStrings[0],
                endDate:  dateStrings[1],
                branchIds: branchList,
                groupBy: type[1],
            }
        })
    }

    function changeTabs(e) {
        setKey(e)
        setSearch({
            ...search,
            groupBy: e
        })
        console.log(e);
    }
    useEffect(() => {
        if (exportdiv) {
            const input = document.getElementById('divPrint');

            exportPDF(input, `SalesReport`, function () {
                setExport(false)
            })
        }

    }, [exportdiv])

    function handleExportPDF(e) {
        setExport(true)
    }
    function handleSearch(e) {
        setSearch({
            ...search,
            startDate: date.startDate,
            endDate: date.endDate,
            branchIds: branchList,
            groupBy: key
        })

        setSearchPDF({...searchPDF,
            searchRecipe: {
                startDate: date.startDate,
                endDate: date.endDate,
                branchIds: branchList,
                groupBy: type[0],
            },
            searchDevice: {
                startDate: date.startDate,
                endDate: date.endDate,
                branchIds: branchList,
                groupBy: type[1],
            }
        })
    }
    function handleChangeBranch(value, node, extra) {
        setbranchList(value.map(v => v.value))
        setSearch({
            ...search,
            branchIds: value.map(v => v.value),
        })
    }
    function onClickOpenRecipeContent(e) {
        setOpen(true)
        setRecipe({
            val: e[0].data.key,
        })
    }

    const [detailData, setDetailData] = useState({
        value: 0,
        text: ''
    });
    function onClickShowDetails(e) {
        setDetailData({
            value: e[0].value,
            text: e[0].title,
        })
    }
    return (
        <>
            <Row style={{ marginBottom: '30px' }}>
                <Space>
                    <CountryBranchTree label="Store" multi={true} onChange={handleChangeBranch} value={branchList} />
                    <DateRangePickers handleChangeDate={handleChangeDate} startDate={date.startDate} endDate={date.endDate} />
                    <Button type="primary" onClick={handleSearch}>Search</Button>
                    <Button type="primary" onClick={handleExportPDF}>Export PDF</Button>
                </Space>
            </Row>
            <Row >
                <Col span={4}>

                </Col>
                <Col span={12}>

                </Col>
                <Col span={8}>
                    <p style={{ color: '#404040', fontSize: '12px', textAlign: 'right' }}>Update Time : {formatDateTimeQ(new Date())}</p>
                </Col>
                <Col span={4}>
                    <Charts.SalesSignboard count={reportBrewTimesData.total} unit="cups" title="Total Cups" />
                </Col>
                <Col span={20}>
                    <Tabs defaultActiveKey="1" onChange={changeTabs} >
                        <TabPane tab="By Recipe" key={type[0]}>
                            <Charts.SalesColumn data={reportBrewTimesData.data} xField="text" yField="value" onClick={onClickOpenRecipeContent} />
                        </TabPane>
                        <TabPane tab="By Device" key={type[1]}>
                            <Charts.SalesColumn data={reportBrewTimesData.data} xField="text" yField="value" onClick={onClickShowDetails} />
                        </TabPane>
                        <TabPane tab="By Branch" key={type[2]}>
                            <Charts.SalesColumn data={reportBrewTimesData.data} xField="text" yField="value" onClick={onClickShowDetails} />
                        </TabPane>
                    </Tabs>
                </Col>

                <Col span={24} style={{ display: key === type[0] ? '' : 'none' }}>
                    <Charts.SalesAnalysisCollapse data={salesAnalysisData.data} entrykey={key}/>
                </Col>
                <Col span={24} style={{ display: key === type[1] ? '' : 'none' }}>
                    <Charts.SalesAnalysisCollapse data={salesAnalysisData.data} entrykey={key}/>
                </Col>
                <Col span={8} style={{ display: key === type[2] ? '' : 'none' }}>
                    <Charts.SalesSignboard count={detailData.value} unit="cups" title="Total Cups" bigTitle={detailData.text} />
                </Col>
                <Col span={16} style={{ display: key === type[2] ? '' : 'none' }}>
                    <Charts.SalesAnalysisCollapse data={salesAnalysisData.data} entrykey={key}/>
                </Col>
            </Row>
            <Modal
                width='600px'
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button key="close" type="primary" onClick={() => setOpen(false)}>Close</Button>
                ]}
            >
                <RecipeCard recipe={recipe}></RecipeCard>
            </Modal>
            {/* 匯出PDF */}
            <div id="divPrint" style={{ display: exportdiv ? '' : 'none' }}>
                <div>
                    <p style={{ color: '#404040', fontSize: '12px', textAlign: 'right' }}>Update Time : {formatDateTimeQ(new Date())}</p>
                </div>
                <div className="pdfPage">
                    <div className="chartBoard">
                        <div>
                            <Charts.ChartTitle text="By Recipe" />
                            <Charts.SalesColumn data={reportBrewTimesPDF.reportBrewTimesRecipe.data} />
                            <Charts.SalesAnalysisCollapse data={salesAnalysisPDF.salesAnalysisRecipe.data} activeKey={salesAnalysisPDF.salesAnalysisRecipe.data.map(i => i.recipeId)} entrykey={type[0]} />
                        </div>
                    </div>
                </div>
                <div className="pdfPage">
                    <div className="chartBoard">
                        <Charts.ChartTitle text="By Device" />
                        <Charts.SalesColumn data={reportBrewTimesPDF.reportBrewTimesDevice.data} />
                        <Charts.SalesAnalysisCollapse data={salesAnalysisPDF.salesAnalysisDevice.data} activeKey={salesAnalysisPDF.salesAnalysisDevice.data.map(i => i.deviceId)} entrykey={type[1]} />
                    </div>
                </div>
                <div className="pdfPage">
                    <div className="chartBoard">
                        <Charts.ChartTitle text="By Branch" />
                        <Charts.SalesColumn data={reportBrewTimesPDF.reportBrewTimesDevice.data} />
                        <Charts.SalesAnalysisCollapse data={salesAnalysisPDF.salesAnalysisDevice.data} activeKey={salesAnalysisPDF.salesAnalysisDevice.data.map(i => i.deviceId)} entrykey={type[2]} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SalesReport;