
import React from 'react';
import { Button, Modal } from 'antd';

import * as Message from './Message';

export const RemoveConfirmButton = ({ label, onOk, confirmMsg = '',title='' }) => {
    function confirm() {
        Modal.confirm({
            closeIcon: true,
            title: <div style={{ textAlign: 'center', color: '#A24F62', fontSize: '22px', margin: '10px 0px' }}>{title === '' ?  label : title}</div>,
            icon: <></>,
            content: <div style={{ textAlign: 'center', color: '#707070', fontSize: '18px', margin: '10px 0px' }}>{confirmMsg === '' ? `Are you sure to ${label}?` : confirmMsg}</div>,
            okText: label,
            okType: 'danger',
            cancelText: 'Cancel',
            width: 600,
            onOk: onOk,
            centered: true
        });
    }
    return <Button type="danger" onClick={confirm}>{label}</Button>
}

export const RemoveButton = ({ label, service, param, style, setReload, confirmMsg = '' }) => {
    const handleRemove = () => {
        async function remove() {
            service(param)
                .then(res => {
                    setReload(true);
                    Message.success(`${label} success`)
                })
                .catch(err => {
                    Message.error(`${label} error` + err)
                });
        }
        remove();
    };
    function confirm() {
        Modal.confirm({
            closeIcon: true,
            title: <div style={{ textAlign: 'center', color: '#A24F62', fontSize: '22px', margin: '10px 0px' }}>{label}</div>,
            icon: <></>,
            content: <div style={{ textAlign: 'center', color: '#707070', fontSize: '18px', margin: '10px 0px' }}>{confirmMsg === '' ? `Are you sure to ${label}?` : confirmMsg}</div>,
            okText: label,
            okType: 'danger',
            cancelText: 'Cancel',
            width: 600,
            onOk: handleRemove,
            centered: true
        });
    }
    return <Button type="danger" style={style} onClick={confirm}>{label}</Button>
}
export const ConfirmButton = ({ label, service, param, style, setReload, confirmMsg = '' }) => {
    const handleOK = () => {
        async function save() {
            service(param)
                .then(res => {
                    setReload(true);
                    Message.success(`${label} success`)
                })
                .catch(err => {
                    Message.error(`${label} error` + err)
                });
        }
        save();
    };
    function confirm() {
        Modal.confirm({
            closeIcon: true,
            title: <div style={{ textAlign: 'center', color: '#A24F62', fontSize: '22px', margin: '10px 0px' }}>{label}</div>,
            icon: <></>,
            content: <div style={{ textAlign: 'center', color: '#707070', fontSize: '18px', margin: '10px 0px' }}>{confirmMsg === '' ? `Are you sure to ${label}?` : confirmMsg}</div>,
            okText: label,
            okType: 'primary',
            cancelText: 'Cancel',
            width: 600,
            onOk: handleOK,
            centered: true
        });
    }
    return <Button type="primary" style={style} onClick={confirm}>{label}</Button>
}