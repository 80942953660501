import { progressive } from '../../../Utils/Utils';
export const schema = {
    testDateTime: { label: "測試時間", name: "testDateTime" },
    firmwareVersion: { label: "FW version", name: "firmwareVersion" },
    machineVeriosn: { label: "機台版本", name: "machineVeriosn" },
    serialNumber: { label: "Seriel number", name: "serialNumber" },
    mode: {
        label: "模式", name: "mode", list: ["咖啡", "茶葉", "清潔", "深度清潔"]
    },
    coffeeBean: { label: "咖啡豆", name: "coffeeBean", showTime: '1' },
    process: {
        label: "處理法",
        name: "process",
        list: [
            {
                value: "Dry",
                label: "Dry 水洗"
            },
            {
                value: "Washed",
                label: "Washed 日曬"
            },
            {
                value: "Semi-washed",
                label: "Semi-washed 半水洗"
            },
            {
                value: "Honey",
                label: "Honey 蜜處理"
            },
            {
                value: "Wet-Hulling",
                label: "Wet-Hulling 濕剝"
            },
            {
                value: "Anaerobic Fermentation",
                label: "Anaerobic Fermentation 厭氧"
            },
            {
                value: "Rum Barrel Fermented",
                label: "Rum Barrel Fermented 蘭姆桶發酵"
            },

            {
                value: "Barrel Fermented",
                label: "Barrel Fermented 酒桶靜置"
            },
            {
                value: "None",
                label: "未標示"
            },
            {
                value: "ProcessOthers",
                label: "其他"
            },
        ],
        others: "ProcessOthers"
    },
    roastLevel: {
        label: "烘焙度",
        name: "roastLevel",
        list: ["Light", "Medium light", "Medium", "Medium dark", "Dark"]
    },
    grindSize: { label: "研磨度", name: "grindSize", list: progressive(1, 8, 0.5) },
    brewRatio: { label: "粉水比", name: "brewRatio" },
    groundCoffee: { label: "咖啡粉", name: "groundCoffee" },
    coldwater: { label: "水量", name: "coldWater" },
    temperature: { label: "水溫", name: "temperature" },
    filterType: { label: "過濾模組形式", name: "filterType", list: ["paper v1", "metal v1", "Paper v1+5凸點", "N/A"] },
    stirStrength: {
        label: "攪拌強度",
        name: "stirStrength",
        list: ["L", "M", "H"]
    },
    stirPeriod: { label: "攪拌時間(0-120)", name: "stirPeriod" },
    steepingPeriod: { label: "浸泡時間(0-360)", name: "steepingPeriod" },
    vacuumLevel: {
        label: "真空強度",
        name: "vacuumLevel",
        list: ["L", "M", "H"]
    },
    coffeeServes: { label: "咖啡產出", name: "coffeeServes" },
    productivity: { label: "產出率", name: "productivity" },
    TDS: { label: "TDS", name: "tds" },
    vacuumBrewingTimeMin: { label: "真空抽取咖啡時間(從電磁閥開啟至咖啡液抽完. 不含抽氣泡和空抽時間)", name: ["vacuumBrewingTime", "min"] },
    vacuumBrewingTime: { label: "真空抽取咖啡時間(從電磁閥開啟至咖啡液抽完. 不含抽氣泡和空抽時間)", name: ["vacuumBrewingTime", "sec"] },
    vacuumPumpStopTimeMin: { label: "真空馬達停止時間(從電磁閥開啟至抽取聲完全停止)", name: ["vacuumPumpStopTime", "min"] },
    vacuumPumpStopTime: { label: "真空馬達停止時間(從電磁閥開啟至抽取聲完全停止)", name: ["vacuumPumpStopTime", "sec"] },
    machineEndTimeMin: { label: "升降平台上升時間(抽取聲停止至升降平台上升完畢)", name: ["machineEndTime", "min"] },
    machineEndTime: { label: "升降平台上升時間(抽取聲停止至升降平台上升完畢)", name: ["machineEndTime", "sec"] },
    result: { label: "結果", name: "result", list: ["PASS", "FAIL"] },
    category: {
        name: "category",
        label: "問題分類",
        list: [
            {
                value: "Function",
                label: "Function"
            },
            {
                value: "Sensor",
                label: "Sensor"
            },
            {
                value: "LED",
                label: "LED"
            },
            {
                value: "BT",
                label: "BT"
            },
            {
                value: "抽不完",
                label: "抽不完"
            },
            {
                value: "攪拌棒",
                label: "攪拌棒"
            },
            {
                value: "過濾模組",
                label: "過濾模組"
            },
            {
                value: "升降平台",
                label: "升降平台"
            },
            {
                value: "真空壓力",
                label: "真空壓力"
            },
            {
                value: "上下壺",
                label: "上下壺"
            },
            {
                value: "FailedTypeOthers",
                label: "其他"
            },
        ],
        others: "FailedTypeOthers"
    },
    failedResult: {
        name: "failedResult",
        label: "失敗原因",
        list: [
            {
                value: "poweroffsuddenly",
                label: "突然關機"
            },
            {
                value: "stick",
                label: "阻塞"
            },
            {
                value: "time out, resuck pass",
                label: "time out, resuck pass"
            },
            {
                value: "time out, resuck fail",
                label: "time out, resuck fail"
            },
            {
                value: "time out, no further test",
                label: "time out, no further test"
            },
            {
                value: "FailedresultTypeOthers",
                label: "Others"
            },
        ],
        others: "FailedresultTypeOthers"
    },
    remark: { label: "Remark", name: "remark" },
}
