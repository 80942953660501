import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Divider } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import BranchTreeAndRecipe from '../../../Components/BranchTreeAndRecipe';
import RecipeBaseInfo from '../../../Components/RecipeDetailCard/RecipeBaseInfo';

const MultiAssign = ({ open, setOpen, selected, handleAssignResult }) => {
    const [branchList, setbranchList] = useState([]);
    const [existRecipe, setExistRecipe] = useState([]);
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        async function fetchData() {
            if (selected[0]) {
                fetchBranchDeviceData(selected[0])
            }
        }
        fetchData();
    }, [selected, open]);

    const fetchBranchDeviceData = (row) => {
        ApiProvider.getDeviceByRecipe(row.recipeId).then(res => {
            setbranchList(res.data.map(item => ({
                groupId: item.branchId,
                groupName: item.branchName,
                key: item.deviceId,
                value: item.deviceId,
                title: `${item.branchName}｜${item.deviceName} `
            })))
        });
    }

    const handleMultiCancel = () => {
        setOpen(false)
        form.resetFields();
    };
    const handleMultiOk = async () => {
        try {
            await form.validateFields();

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return
        }

        const body = GetFieldValue();

        setloading(true);
        handleAssignResult(body);
        setloading(false);
        setOpen(false);
        form.resetFields();
    }
    const GetFieldValue = () => {
        const body = []
        const fieldValue = form.getFieldValue();
        selected.forEach((item, i) => {
            body.push({
                recipeName: item.recipeName,
                recipeId: item.recipeId,
                deviceIdList: fieldValue[item.recipeId]?.deviceIdList?.map(d => d.value),
                recipeIndex: fieldValue[item.recipeId]?.recipeIndex,
                deviceList: fieldValue[item.recipeId]?.deviceIdList,//deviceList
            })
        });
        return body
    }
    const handleChangeRecipe = () => {
        const recipeIndexs = GetFieldValue().map(i => i.recipeIndex)
        setExistRecipe(recipeIndexs)
    };
    const AssignBoxs = () => {
        const boxs = [];
        selected.forEach(function (recipeInfo, i) {
            boxs.push(
                < div key={recipeInfo.recipeId} >
                    <RecipeBaseInfo recipeInfo={recipeInfo} />
                    <BranchTreeAndRecipe
                        form={form}
                        id={recipeInfo.recipeId}
                        existRecipe={existRecipe}
                        branchList={branchList}
                        onChangeRecipe={handleChangeRecipe}
                    />
                    <Divider />
                </div>
            )
        });
        return boxs;
    }

    return (
        <Modal
            width={750}
            open={open}
            onCancel={() => handleMultiCancel()}
            footer={[
                <Button key="back" onClick={() => handleMultiCancel()}>Cancel</Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => handleMultiOk()}>OK</Button>,
            ]}
            afterClose={()=>form.resetFields()}
        >
            <Form form={form}>
                <AssignBoxs />
            </Form>
        </Modal>
    )
}
export default MultiAssign;