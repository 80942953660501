import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import { formatDateTime } from '../../Utils/Utils';
import { isRootRoles } from '../../Settings/Roles';

const layout = {
    marginRight: '1em',
}

const title = {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '14px',
}
const content = {
    fontSize: '16px',
}
const Descriptions = (prop) => {
    return (
        <div style={layout}>
            <div style={title}>{prop.title}</div>
            <div style={content}>{prop.value}</div>
        </div>
    )
}

const RecipeBaseInfo = ({ recipeInfo }) => {
    var col = isRootRoles() ? 8 : 12;
    return (
        <>
            <Row>
                <Col><div className="detailTitle">{`${recipeInfo.seqNo}   ${recipeInfo.recipeName}`}</div></Col>
            </Row>
            <Row>
                <Col style={{ display: (isRootRoles() ? "" : "none") }} span={col}><Descriptions title="Brand" value={recipeInfo.brand} /></Col>
                <Col span={col}><Descriptions title="Create Time" value={formatDateTime(recipeInfo.createdDate)} /></Col>
                <Col span={col}><Descriptions title="Create Person" value={recipeInfo.createdUserName} /></Col>
            </Row>
        </>
    )
}

export default RecipeBaseInfo;