import React, { useState } from 'react';
import { Space, Button, Row, Col, Select, List, Typography } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import ServiceSiteDetails from './ServiceSiteDetails';
import SearchInput from '../../../Components/SearchInput';
import { SelectedTable ,columnFilterList} from '../../../Components/Table';
import { RemoveButton } from '../../../Components/Button';

const ServiceSiteTable = () => {
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [reload, setReload] = useState(false);
    //#region  condition
    const [search, setSearch] = useState({
        searchColumn: null,
        searchWord: ''
    });
    //#endregion
    //#region Column

    const handleClickName = (e) => {
        setOpenEdit(true)
        setSelectSingleRow(e.row)
    }

    const columns = [
        {
            title: 'Service Site',
            dataIndex: 'vendorName',
            isFilter: true,
            render: (value, row, index) => {
                return (<Button type="link" onClick={() => handleClickName({ row })}>{value}</Button>)
            }
        },
        {
            title: 'PIC',
            dataIndex: '',
            render: (value, row, index) => {
                return (<>{(row.principal) ? row.principal[0].name : ''}</>)
            }


        },
        {
            title: 'Email',
            dataIndex: '',
            render: (value, row, index) => {
                return (<>{(row.principal) ? row.principal[0].email : ''}</>)
            }

        },
        {
            title: 'Tel',
            dataIndex: '',
            render: (value, row, index) => {
                return (<>{(row.principal) ? row.principal[0].tel : ''}</>)
            }
        },
        {
            title: 'Agent',
            dataIndex: 'agentName',
        },
        {

            title: 'SN',
            dataIndex: 'sn',
            isFilter: true,
            display: false,
        },


    ];
    //#endregion
    function handleMenuClick(e) {
        setSearch({
            ...search,
            searchColumn: e
        })
    }

    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select placeholder='Select filter column' options={columnFilterList(columns)} onSelect={(e) => handleMenuClick(e)}>
                        </Select>
                    </div>
                    <div>
                        <SearchInput onPressEnter={(value) => setSearch({ ...search, searchWord: value })} />
                    </div>
                </Space>
            </Col>
            <Col>
                <Space>
                    <Button type="primary" style={{ display: (selectedRows.length === 0 ? "" : "none") }} onClick={() => setOpenNew(true)}>New Service Site</Button>
                    <RemoveButton label="Remove" service={ApiProvider.RemoveVendor} param={selectedRows} setReload={setReload} style={{ display: (selectedRows.length > 0 ? "" : "none") }} />
                </Space>
            </Col>
        </Row>
    )
    //#endregion

    const tableProps = {
        rowKey: 'vendorId',
        ApiProviderUrl: ApiProvider.GetVendorList,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns.filter(i => (i.display === undefined) ? true : i.display),
        selectedRowKeys: selectedRowKeys,
        reload: reload,
        setReload: setReload,
        setSelectedRows: (e) => setSelectedRows(e),
        setSelectSingleRow: (e) => setSelectSingleRow(e),
        setSelectedRowKeys: (e) => setSelectedRowKeys(e),
        expandable: {
            expandIconColumnIndex: 1,
            expandedRowRender: row =>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 7,
                        xl: 8,
                        xxl: 10
                    }}
                    dataSource={row.snInfo.sn}
                    size="small"
                    renderItem={item => (
                        <List.Item bordered="true">
                            <Typography.Text bordered="true" mark={false}>{item}</Typography.Text>
                        </List.Item>
                    )}
                />,
            // rowExpandable: row => row.sn?.length > 0 ,
        },
    }
    return (
        <div>
            <SelectedTable {...tableProps} />
            <ServiceSiteDetails title='Edit Service Site' open={openEdit} setOpen={(isOpen) => setOpenEdit(isOpen)} setReload={(isReload) => setReload(isReload)} selected={selectSingleRow} saveService={ApiProvider.UpdateVendor} />
            <ServiceSiteDetails title='Create New Service Site' open={openNew} setOpen={(isOpen) => setOpenNew(isOpen)} setReload={(isReload) => setReload(isReload)} selected={[]} saveService={ApiProvider.CreateVendor} />
        </div>
    );
};

export default ServiceSiteTable;

