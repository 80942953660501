import React, { Suspense } from 'react';
import HomePage from './Pages/Content/MainPage/HomePage';
import './App.less';

function App() {
  return (
    <Suspense fallback="loading">
      <HomePage />
    </Suspense>
  );
}

export default App;
