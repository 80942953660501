import React from 'react';
import { Tag } from 'antd'


//Service Requset Status
export const ServiceStatus = {
    New: 'New',
    Open: 'Open',
    Active: 'Active',
    Closed: 'Closed',
}
//Service Requset Status tag 
export const ServiceStatusTagSetting = {
    New: <Tag color="#A24F62">New</Tag>,
    Open: <Tag color="#92B1C1">Open</Tag>,
    Active: <Tag color="#D09E6D">Active</Tag>,
    Closed: <Tag color="#404040">Closed</Tag>,
}