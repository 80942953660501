import React, { useState, useEffect } from 'react';
import * as ApiProvider from '../Api/ApiProvider';
import { TreeSelect, Select, Form, Cascader } from 'antd';
import { isEmpty } from 'lodash';
const { SHOW_CHILD } = TreeSelect;
const { Option } = Select;
//select Branch 
export const BranchFormSelect = ({ name, required, label }) => {
    const [branchList, setbranchList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            fetchBranchData()
        }
        fetchData();
    }, []);

    const fetchBranchData = () => {
        //等API
        ApiProvider.GetBrandKeyValue().then(res => {
            setbranchList(res.data)
        });
    }

    return (
        <Form.Item name={name} rules={[{ required: required, message: `Please select ${label}` }]} style={{ width: '100%' }} >
            <Select
                dropdownMatchSelectWidth={false}
                labelInValue
                showSearch
                allowClear={true}
                disabled={branchList.length === 0}
                placeholder={branchList.length === 0 ? <>Loading....</> : <>{`Select ${label}`}</>}
            >
                {branchList.map(data => (
                    <Option key={data.val}>{data.text}</Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export const BranchSelect = ({ name, required, label }) => {
    const [branchList, setbranchList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            fetchBranchData()
        }
        fetchData();
    }, []);

    const fetchBranchData = () => {
        //等API
        ApiProvider.GetBrandKeyValue().then(res => {
            setbranchList(res.data)
        });
    }

    return (

        <Select
            dropdownMatchSelectWidth={false}
            labelInValue
            showSearch
            allowClear={true}
            disabled={branchList.length === 0}
            placeholder={branchList.length === 0 ? <>Loading....</> : <>{`Select ${label}`}</>}
        >
            {branchList.map(data => (
                <Option key={data.val}>{data.text}</Option>
            ))}
        </Select>
    )
}

//Muti select Branch 
export const BranchMutiSelect = ({ name, required, label, onChange = {} }) => {
    const [branchList, setbranchList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            fetchBranchData()
        }
        fetchData();
    }, []);

    const fetchBranchData = () => {
        //等API
        ApiProvider.GetBrandKeyValue().then(res => {
            setbranchList(res.data)
        });
    }

    return (
        <Form.Item name={name} rules={[{ required: required, message: `Please select ${label}` }]} style={{ width: '100%' }}>
            <Select
                mode="multiple"
                labelInValue
                showSearch
                allowClear={true}
                onChange={onChange}
                disabled={branchList.length === 0}
                placeholder={branchList.length === 0 ? <>Loading....</> : <>{`Select ${label}`}</>}
            >
                {branchList.map(data => (
                    <Option key={data.val}>{data.text}</Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export const BranchTreeSelect = ({ label, onChange, children }) => {
    const [branchList, setbranchList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            fetchBranchData()
        }
        fetchData();
    }, []);

    const fetchBranchData = () => {
        //等API
        ApiProvider.GetBranchList().then(res => {
            setbranchList(res.data.map(item => ({
                country: item.address?.country,
                value: item.branchId,
                label: item.branchName,
            })))
        });
    }
    // eslint-disable-next-line no-extend-native
    Array.prototype.groupBy = function (prop) {
        return this.reduce(function (groups, item) {
            const val = item[prop]
            groups[val] = groups[val] || []
            groups[val].push(item)
            return groups
        }, {})
    };

    const countries = branchList.groupBy('country');

    const branches = [{
        value: '',
        label: 'Unassigned',
    }];
    Object.keys(countries).forEach(country => {
        branches.push({
            value: country,
            label: country,
            children: countries[country].map(i => ({ value: i.value, label: i.label }))
        })
    });

    const hasNoBrnach = branches.length === 0;
    const placeholder = hasNoBrnach ? <>Loading....</> : <>{`Select ${label}`}</>;

    return <Cascader options={branches} onChange={onChange} placeholder={placeholder}>{children}</Cascader>
}
//[Account] 選擇 Device Admin (Branch and Device Tree)
export const BranchDeviceTree = ({ name, required, label, onChange = {} }) => {
    const [branchList, setbranchList] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetchBranchDeviceData()
        }
        fetchData();
    }, []);

    const fetchBranchDeviceData = () => {
        //等API
        ApiProvider.GetDeviceList().then(res => {
            setbranchList(res.data.map(item => ({
                groupId: item.branchId,
                groupName: item.branchName,
                key: item.deviceId,
                value: item.deviceId,
                title: `${item.branchName}｜${item.deviceName} `
            })))
        });
    }


    const treeData = (branchList.length > 0 ? [{
        title: 'Select all',
        value: 'all',
        key: 'all',
        children: groupByBranch(branchList, function (item) {
            //要group的
            return item.groupId || 'No';
        })
    }
    ] : [])


    function groupByBranch(array, f) {
        let groups = {};
        array.forEach(function (o) {
            let group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);

        });
        return Object.keys(groups).map(function (group) {
            return {
                title: isEmpty(groups[group][0].groupId) ? 'non-branch' : groups[group][0].groupName,
                value: groups[group][0].groupId,
                key: groups[group][0].groupId,
                children: groups[group]
            }
        });
    }

    return (
        <Form.Item name={name} rules={[{ required: required, message: `Please select ${label}` }]} style={{ width: '100%' }} >
            <TreeSelect
                disabled={treeData.length === 0}
                placeholder={treeData.length === 0 ? <>Loading....</> : <>{`Select ${label}`}</>}
                labelInValue
                allowClear={true}
                showArrow={true}
                maxTagCount={5}
                treeData={treeData}
                treeCheckable={true}
                showCheckedStrategy={SHOW_CHILD}
                onChange={onChange}
            />
        </Form.Item>

    )
}

//country Branch and Device Tree (antd 4.17.0 有Cascader multi 的功能，目前版本還沒relese 先用seletetree頂一下)
export const CountryBranchTree = ({ label, onChange, value, multi, children }) => {
    const [branchList, setbranchList] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetchBranchDeviceData()
        }
        fetchData();
    }, []);

    const fetchBranchDeviceData = () => {
        ApiProvider.GetDeviceTreeList().then(res => {
            setbranchList(res.data.map(item => ({
                country: item.address?.country,
                value: item.branchId,
                label: item.branchName,
            })))
        });
    }

    // eslint-disable-next-line no-extend-native
    Array.prototype.groupBy = function (prop) {
        return this.reduce(function (groups, item) {
            const val = item[prop]
            groups[val] = groups[val] || []
            groups[val].push(item)
            return groups
        }, {})
    };
    const countries = branchList.groupBy('country');

    const branches = Object.keys(countries).map(country => {
        if (country === "undefined") {
            return {
                value: 'non-branch',
                label: 'non-branch',
                children: []
            }
        } else {
            const branchesKeyValue = countries[country].map(i => ({ value: i.value, label: i.label }));
            return {
                value: country,
                label: country,
                children: Object.keys(branchesKeyValue.groupBy('value')).map(branch => {
                    return {
                        value: branch,
                        label: branchesKeyValue.filter(i => i.value === branch)[0].label
                    }
                }
                )

            }
        }
    });

    var options = [{
        value: 'all',
        label: 'SELECT ALL',
        children: branches
    }];

    const hasNoBrnach = options.length === 0;
    const placeholder = hasNoBrnach ? <>Loading....</> : <>{`Select ${label}`}</>;

    return multi ? <TreeSelect
        style={{ width: '200px' }}
        disabled={hasNoBrnach}
        placeholder={placeholder}
        labelInValue
        allowClear={true}
        showArrow={true}
        treeData={options}
        treeCheckable={true}
        showCheckedStrategy={SHOW_CHILD} onChange={onChange} /> : <Cascader options={options[0].children} onChange={onChange} placeholder="Please select">{children}</Cascader>
}

