import React, { useState } from 'react';
import { Space, Button, Row, Col, Select, Tag } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import AccountOrgDetails from './AccountOrgDetails';
import { ImportAccountOrg } from '../../../Components/ImportPop';
import SearchInput from '../../../Components/SearchInput';
import { SelectedTable, columnFilterList } from '../../../Components/Table';
import { RemoveButton } from '../../../Components/Button';

const AccountOrgTable = () => {
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [reload, setReload] = useState(false);
    //#region  condition
    const [search, setSearch] = useState({
        searchColumn: 'userName',
        searchWord: ''
    });
    //#endregion
    //#region Column

    const handleClickName = (e) => {
        setOpenEdit(true)
        setSelectSingleRow(e.row)
    }

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'userName',
            isFilter: true,
            render: (value, row, index) => {
                return (<Button type="link" onClick={() => handleClickName({ row })}>{value}</Button>)
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Company Name',
            dataIndex: 'brandName',
            isFilter: true,
            render: (value, row, index) => {
                return (<Button type="link" onClick={() => handleClickName({ row })}>{value}</Button>)
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: '',
            dataIndex: 'subscription',
            render: (value, row, index) => {
                return (row.subscription) ? <></> : <Tag shape="round" color="#A24F62" >Unsubscribe</Tag >

            }
        },

    ];
    //#endregion

    function handleMenuClick(e) {
        setSearch({
            ...search,
            searchColumn: e
        })
    }

    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select dropdownMatchSelectWidth={false} placeholder='Select filter column' defaultValue={search.searchColumn} options={columnFilterList(columns)} onSelect={(e) => handleMenuClick(e)}>
                        </Select>
                    </div>
                    <div>
                        <SearchInput onPressEnter={(value) => setSearch({ ...search, searchWord: value })} />
                    </div>
                </Space>
            </Col>
            <Col>
                <Space>
                    <Button type="primary" style={{ display: "none" }} onClick={() => setOpenImport(true)}>Import Account</Button>
                    <Button type="primary" style={{ display: (selectedRows.length === 0 ? "" : "none") }} onClick={() => setOpenNew(true)}>New Advanced Customer</Button>
                    <RemoveButton
                        label="Delete"
                        service={ApiProvider.RemoveAccountOrg}
                        param={selectedRows}
                        setReload={setReload}
                        style={{ display: (selectedRows.length > 0 ? "" : "none") }}
                        confirmMsg={`Are you sure to Delete "${selectedRows?.map(i => i.userName)?.join('","')}" Account?`}
                    />
                </Space>
            </Col>
        </Row>
    )
    //#endregion

    const tableProps = {
        rowKey: 'userId',
        ApiProviderUrl: ApiProvider.GetAccountGridOrg,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        selectedRowKeys: selectedRowKeys,
        reload: reload,
        setReload: setReload,
        setSelectedRows: (e) => setSelectedRows(e),
        setSelectSingleRow: (e) => setSelectSingleRow(e),
        setSelectedRowKeys: (e) => setSelectedRowKeys(e),
    }
    return (
        <div>
            <SelectedTable {...tableProps} />
            <AccountOrgDetails title='Edit' isEdit={true} open={openEdit} setOpen={(isOpen) => setOpenEdit(isOpen)} setReload={(isReload) => setReload(isReload)} selected={selectSingleRow} saveService={ApiProvider.UpdateAccountOrg} />
            <AccountOrgDetails title='Create New Advanced Customer' isEdit={false} open={openNew} setOpen={(isOpen) => setOpenNew(isOpen)} setReload={(isReload) => setReload(isReload)} selected={[]} saveService={ApiProvider.CreateAccountOrg} />
            <ImportAccountOrg open={openImport} setOpen={(isOpen) => setOpenImport(isOpen)} setReload={(isReload) => setReload(isReload)} />
        </div>
    );
};

export default AccountOrgTable;

