import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Descriptions, Form, Input } from 'antd';
import { CountryStateCityFormSelector } from '../../../Components/CountryStateCitySelector';
import { isEmpty } from 'lodash';
import { DescriptionsInput, DescriptionsEmail } from '../../../Components/Descriptions';
import * as Message from '../../../Components/Message';

const BranchDetails = ({ open, setOpen, setReload, selected, saveService, title, deviceInfo = [] }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();

        if (!isEmpty(selected)) {
            console.log(selected);

            const fieldsValue = Deserialization(selected);
            console.debug('Deserialization(selected):', fieldsValue);

            form.setFieldsValue(fieldsValue);
        }
        setIsEdit(!isEmpty(selected));
    }, [form, selected, open]);

    const ifEmptyOrNull = (value) => {
        return isEmpty(value) ? '' : value;
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = async () => {
        try {
            await form.validateFields();
        } catch (errorInfo) {
            console.error('form validateFields failed!');
            console.debug('errorInfo:', errorInfo);
            return false;
        }
        const fieldValue = form.getFieldValue();

        setloading(true);
        await (async () => {
            saveService(Serialization(fieldValue))
                .then(res => {
                    Message.success(`${title} success`);
                    setOpen(false);
                    setloading(false);
                    setReload(true);
                })
                .catch(err => {
                    Message.error(`${title} error`);
                    setloading(false);
                });
        })();
    };

    const Deserialization = (row) => {
        return (
            {
                ...row,
                countryStateCity: [row.country, row.state, row.city],
            }
        );
    };

    const Serialization = (fieldValue) => {
        return (
            {
                branchId: selected.branchId,
                branchName: fieldValue.branchName,
                email: fieldValue.email,
                tel: fieldValue.tel,
                country: ifEmptyOrNull(fieldValue.countryStateCity[0]),
                state: ifEmptyOrNull(fieldValue.countryStateCity[1]),
                city: ifEmptyOrNull(fieldValue.countryStateCity[2]),
                zipCode: fieldValue.zipCode,
                street: fieldValue.street,
            }
        );
    };

    return (
        <Modal
            width={800}
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                <Button key="Save" type="primary" loading={loading} onClick={handleOk}>Save</Button>,
            ]}
        >
            <Row>
                <Form
                    form={form}
                    requiredMark={false}
                    size='middle'
                    onFinish={handleOk}
                >
                    <Descriptions title={title} layout="vertical" column={12} >
                        <Descriptions.Item label="*Branch Name" span={6}>
                            <DescriptionsInput name='branchName' label="Branch Name" readOnly={isEdit} required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Branch Admin" span={6} style={{ display: isEdit ? "" : "none" }}>
                            <DescriptionsInput name='branchAdmin' label="Branch Admin" readOnly={true} required={false} />
                        </Descriptions.Item>
                        <Descriptions.Item label="*Email" span={12}>
                            <DescriptionsEmail name="email" label="*Email" required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="*Tel" span={12}>
                            <DescriptionsInput name='tel' label="Tel" required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="*Country/Region" span={8} required={true} >
                            < CountryStateCityFormSelector />
                        </Descriptions.Item>
                        <Descriptions.Item label="*Post Code" span={4}>
                            <DescriptionsInput name='zipCode' label="Post Code" required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="*Street" span={12}>
                            <DescriptionsInput name='street' label="Street" required={true} />
                        </Descriptions.Item>
                    </Descriptions>
                </Form>

            </Row>
            <Row>
                <Descriptions title="Recipe" layout="vertical" column={12} >
                    {
                        deviceInfo.map(item => (
                            <>
                                <Descriptions.Item span={12}><p style={{ fontSize: '16px ', color: '#0A496A', opacity: 1, fontWeight: 'bold' }}>{item.deviceId}</p></Descriptions.Item>
                                <Descriptions.Item label="Recipe A" span={12}>
                                    <Input readOnly value={item?.recipeA?.text} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Recipe B" span={12}>
                                    <Input readOnly value={item?.recipeB?.text} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Recipe B" span={12}>
                                    <Input readOnly value={item?.recipeC?.text} />
                                </Descriptions.Item>
                            </>
                        ))
                    }
                </Descriptions>
            </Row>
        </Modal>
    )
}

export default BranchDetails;
