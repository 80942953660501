import React, { useState, useEffect } from 'react';
import { notification, Tabs, Layout, Spin, Alert } from 'antd';
import { Switch, useHistory, Route, Redirect } from 'react-router-dom';
import * as ApiProvider from '../../../Api/ApiProvider';
import { ActionContext } from '../../../Context/ActionContext';
import { getUser, getToken, removeUserSession, setUserSession } from '../../../Utils/session';
import { setCookie, delCookie } from '../../../Utils/cookie';
import { Routes } from '../../../routes'
//page
import NotFoundPage from './NotFoundPage';
import ServerBusy from './ServerBusy';

import Login from './Login';
import ResetPassword from './ResetPassword';
import Success from './Success';
import ChangePassword from '../Setting/ChangePassword';
//Components
import Welcome from '../../Welcome/Welcome';
import Retry from '../Recipe/Retry';
import LayoutHeader from '../../Layout/LayoutHeader';
//import { MenuItem } from '../../../Settings/LayoutSetting'
import { GetMenuItems } from '../../../Settings/MenuSetting';
import { isSystemRoles } from '../../../Settings/Roles';

import { dateTimeDiff } from '../../../Utils/Utils'
import * as CoffeeCloudConfig from '../../../CoffeeCloudConfig';

const { Header, Content, Footer } = Layout;

function HomePage() {
    const [authLoading, setAuthLoading] = useState(true);
    const [user, setUser] = useState();
    const [openRetry, setOpenRetry] = useState(false);
    const [openWelcome, setOpenWelcome] = useState(false);
    const [count, setCount] = useState(0);
    let history = useHistory();

    useEffect(() => {
        let lastTime = new Date().getTime();
        const timeOut = 30 * 60 * 1000; //30分鐘後登出

        document.addEventListener('mousemove', function () {
            const currentTime = new Date().getTime(); //目前時間
            if (dateTimeDiff(lastTime, currentTime) > timeOut) {
                console.log('閒置超時')
                signOut()
            }
            lastTime = new Date().getTime();
        })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (getToken()) {
            ApiProvider.CheckContent()
                .then(result => {
                    console.log("content OK " + JSON.stringify(result.data));
                    ApiProvider.CheckToken()
                        .then(tResult => {
                            console.log("token ok");
                            setUser(getUser());
                            setAuthLoading(false);
                        })
                        .catch(err => {
                            removeUserSession();
                            setAuthLoading(false);
                        });
                })
                .catch(err => {
                    console.log("content NG " + JSON.stringify(err));
                    setUser(null);
                });
        }
    }, [user]);

    if (authLoading && getToken()) {
        return <Spin tip="Loading...">
            <Alert
                message="Loading..."
                description="因系統忙碌，請稍後再嘗試登入 Sorry! Our system is currently busy, please try again later."
                type="info"
            />
        </Spin>
    }
    function signOut() {
        return ApiProvider.AccountLogout()
            .then(result => {
                //登出
                removeUserSession();
                setUser();
                history.push(Routes.Login.path);
            })
    }

    const actionContext = {
        GoHome: () => {
            history.push(Routes.Login.path);
        },
        signIn: async data => {
            return ApiProvider.AccountLogin({ account: data.account, password: data.password })
                .then(result => {
                    //判斷如果沒有任何角色，不轉入畫面
                    const roles = result.data.role.join(",");
                    if (!isSystemRoles(roles)) {
                        notification.error({
                            message: 'This account is not authorized to access Coffee Cloud service, please check with your system manager, thanks.',
                            placement: 'bottomLeft'
                        })
                        return;
                    }
                    setAuthLoading(false);
                    setUserSession(result.data);
                    setUser(getUser());
                    //記住密碼
                    if (data.remember) {
                        let accountInfo = data.account;
                        setCookie('accountInfo', accountInfo);
                    } else {
                        delCookie('accountInfo');
                    }
                    history.push(GetMenuItems()[0].HeaterItemPath);
                })
                .catch(err => {

                    setAuthLoading(false);
                    delCookie('accountInfo');
                    console.log("login NG " + JSON.stringify(err));
                });
        },
        signOut: () => {
            signOut();
        },
        //重設密碼
        resetPassword: async data => {
            //檢查驗證碼是否失效
            return ApiProvider.ResetPassword({ confirmPassword: data.confirmPassword, newPassword: data.newPassword, encryptedString: data.encryptedString })
                .then(result => {
                    history.push(Routes.Success.path);
                })
                .catch(err => {
                    notification.error({
                        message: 'Reset Password Fail',
                        placement: 'bottomLeft'
                    })
                    setAuthLoading(false);
                    console.log("Reset Password Fail" + JSON.stringify(err));
                });
        },
        VerifyResetPasswordUrl: async encryptedString => {
            ApiProvider.VerifyResetPasswordUrl(encryptedString)
                .catch(err => {
                    Alert('您的重設密碼連結已經失效，請重新執行忘記密碼流程，謝謝 Your password reset request is expired, please perform "Forgot Password" process again, thanks.');
                    history.push(Routes.NotFound.path);
                })
        },
        openWelcome: () => {
            setOpenWelcome(true)
        },
        openRetry: () => {
            setOpenRetry(true)
        },
        getCount: () => {
            return count
        },
        updateCount: (c) => {
            return ApiProvider.GetFailedRecipe()
                .then(result => {
                    setCount(result.data.length)
                })
        }
    };

    const MainRoute = ({ item, component: Component, ...rest }) => {
        return (
            <Route exact {...rest} render={props => getToken() ?
                (<Layout >
                    <Header style={{ width: '100%' }}>
                        <LayoutHeader item={item} />
                    </Header>
                    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>

                            <Component {...props} />
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center', color: '#848484' }}>
                        <p>Copyright © 2021 RAPIBREW All rights reserved</p>
                        <p style={{ fontSize: 6 }}>{CoffeeCloudConfig.ReleaseVersion}</p>
                    </Footer>
                </Layout>)
                : <Redirect to={{ pathname: Routes.Login.path, state: { from: props.location } }} />} />)
    };
    function ContentTab(item) {
        const [activeKey, setActiveKey] = useState(item.HeaterItem);
        const handleChange = (key) => {
            setActiveKey(key)
        }

        return (
            // <Tabs type="card" onChange={handleChange}>
            //     {item.Tabs.map((tab) => {
            //         return (<TabPane key={tab.key} tab={tab.title}>{tab.content(activeKey)}</TabPane>)
            //     })
            //     }
            // </Tabs>
            <Tabs type="card" onChange={handleChange} items={item.Tabs.map((tab) => {
                return {
                    key: tab.key,
                    label: tab.title,
                    children: tab.content(activeKey),
                };
            })} />
        );
    }
    return (
        <ActionContext.Provider value={actionContext}>
            <Switch>

                {/* PublicRoute */}
                <Route exact path={Routes.Presentation.path} component={Login} />
                <Route exact path={Routes.Login.path} component={Login} />
                <Route exact path={Routes.Forgetpassword.path} component={ResetPassword} />
                <Route exact path={Routes.Success.path} component={Success} />
                <Route exact path={Routes.ServerBusy.path} component={ServerBusy} />
                {/* PrivateRoute */}
                {
                    GetMenuItems().map((item) => {
                        return (<MainRoute item={item.HeaterItem} key={item.HeaterItemPath} path={item.HeaterItemPath} component={() => ContentTab(item)} />)
                    })
                }
                <MainRoute item="ChangePassword" key="ChangePassword" path={Routes.Setting.path} component={ChangePassword} />
                <Route exact path={Routes.NotFound.path} component={NotFoundPage} />
                <Redirect to={Routes.NotFound.path} />
            </Switch>
            <Welcome open={openWelcome} setOpen={(e) => setOpenWelcome(e)}></Welcome>
            <Retry open={openRetry} setOpen={(e) => setOpenRetry(e)}></Retry>
        </ActionContext.Provider>
    );
}

export default HomePage;
