import React, { useState, useEffect } from 'react';
import { Modal, Button, message, Form, List, Upload, Tag, Space, Row, Col, Descriptions } from 'antd';
import { isEmpty } from 'lodash';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { DescriptionsDetailsTitle, DescriptionsInput, DescriptionsEmail } from '../../../Components/Descriptions';
import { formatDateTimeHasSec } from '../../../Utils/Utils';
import { exportExcel } from '../../../Utils/Export';
import * as XLSX from 'xlsx';
import * as Message from '../../../Components/Message'
import * as ApiProvider from '../../../Api/ApiProvider';
import { RemoveConfirmButton } from '../../../Components/Button';
const ServiceSiteDetails = ({ open, setOpen, setReload, selected, saveService, title }) => {
    const [loading, setloading] = useState(false);
    const [snInfo, setSnInfo] = useState({
        snInfo: [],
        updateDate: null,
        count: 0,
    });
    const [form] = Form.useForm();

    useEffect(() => {
        if (isEmpty(selected)) {
            form.resetFields()
            handleClear()
        } else {
            const fieldsValue = Deserialization(selected);
            console.log(fieldsValue)
            form.setFieldsValue(fieldsValue)
            const info = {
                snInfo: selected.snInfo.sn.map(i => ({ sn: i, used: false })),
                updateDate: selected.snInfo.updateDate,
                count: selected.snInfo.sn.length,
            }
            setSnInfo(info)
        }


    }, [open, selected, form]);


    async function fetchData(sn) {
        ApiProvider.GetSnUsedStatus({
            vendorId: selected?.vendorId,
            sn: sn,
        })
            .then(res => {
                const info = {
                    snInfo: res.data.map(i => ({ sn: i.sn, used: i.used })),
                    updateDate: new Date(),
                    count: sn.length,
                }
                setSnInfo(info)
            });

    }

    const handleCancel = () => {
        setOpen(false)
    };
    const handleOk = async () => {
        try {
            await form.validateFields();

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return
        }
        //確認更新
        confirm()
    };
    function confirm() {
        if (!isEmpty(selected)) {
            Modal.confirm({
                title: '確認更新',
                content: <Space><Tag color="#93796B" >更新後筆數：{snInfo.count}｜更新前筆數：{selected.snInfo.sn.length}</Tag></Space>,
                okText: 'OK',
                cancelText: 'Cancel',
                onOk: handleSave
            });
        } else {
            handleSave()
        }
    }

    const handleSave = async () => {
        const fieldValue = form.getFieldValue();
        const data = Serialization(fieldValue);
        async function saveData() {
            saveService(data)
                .then(res => {
                    Message.success(`${title} success`)
                    setOpen(false);
                    setloading(false);
                    setReload(true)
                })
                .catch(err => {
                    Message.error(`${title} error`)
                    setloading(false);
                });
        }
        saveData()
    };
    const Deserialization = (row) => {
        return row
    }
    const Serialization = (fieldValue) => {
        return (
            {
                vendorId: selected.vendorId,
                vendorName: fieldValue.vendorName,
                principal: fieldValue.principal,
                snInfo: {
                    sn: snInfo.snInfo.map(i => i.sn),
                    updateDate: snInfo.updateDate
                },
            });
    }

    function handleExport(e) {
        const exportdobdy = snInfo.snInfo.length === 0 ? [{ sn: 'input your sn' }, { sn: 'input your sn' }, { sn: 'input your sn' }] : snInfo.snInfo.map(sn => ({ sn: sn.sn }));
        exportExcel("ExportSN", exportdobdy)
    }
    function handleClear() {
        setSnInfo({
            snInfo: [],
            updateDate: new Date(),
            count: 0,
        })
    }
    const props = {
        accept: ".xlsx, .xls, .csv",
        showUploadList: false,
        beforeUpload: file => {
            onImportExcel(file)
            return false;
        },
    };
    //把上傳的excel轉換成 sn (array) 純預覽尚未更新資料庫!!
    const onImportExcel = files => {
        const fileReader = new FileReader();
        fileReader.onload = ev => {
            try {
                var d = ev.target.result,
                    workbook = XLSX.read(d, {
                        type: 'binary'
                    }),
                    sn = [];
            } catch (e) {
                message.error('error' + e)
                return;
            }
            for (var sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    sn = sn.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    break;
                }
            }
            if (sn.length === 0) {
                message.error('不允許匯入空白資料，如果要清除機台連結請執行 Clear All ')
                return
            }
            if (!sn[0].sn) {
                message.error('格式錯誤，請先匯出檔案,編輯後再重新匯入!')
                return
            }
            fetchData(sn.map(i => i.sn))
        };

        // 以二進制方式打開文件
        fileReader.readAsBinaryString(files);
    };

    const PrincipalCol = {
        padding: "0 10px"
    }
    const PrincipalColLabel = {
        color: '#ababa4',
        padding: "0 10px",
    }
    return (
        <>
            <Modal
                maskClosable={false}
                width={800}
                open={open}
                onCancel={() => handleCancel()}
                footer={[
                    <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                    <Button key="OK" type="primary" loading={loading} onClick={handleOk} disabled={snInfo.snInfo.some(i => i.used)} >Save</Button>,
                ]}
            >
                <Form
                    form={form}
                    size='middle'
                    validateMessages={{ required: label => `${label} is required` }}
                >

                    <Descriptions title={title} layout="vertical" column={12} >
                        <Descriptions.Item span={12}><DescriptionsDetailsTitle title="SERVICE SITE INFO" /></Descriptions.Item>
                        <Descriptions.Item label="*Service Site Name" span={6}>
                            <DescriptionsInput name='vendorName' label="Service Site Name" required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item span={6}></Descriptions.Item>
                        <Descriptions.Item span={12}>
                            <Form.List name="principal">
                                {(fields, { add, remove }) => (
                                    <>
                                        <Row style={PrincipalColLabel}>PIC
                                            <PlusCircleFilled style={{ margin: '5px 15px', fontSize: '18px', color: '#0A496A' }} onClick={() => add()} />
                                        </Row>
                                        {fields.map(field => (
                                            <Row key={field.key} align="baseline" style={{ margin: "10px" }}>
                                                <Row key={field.key}>
                                                    <Col span={7} style={PrincipalColLabel}>*Name</Col>
                                                    <Col span={8} style={PrincipalColLabel}>*Email</Col>
                                                    <Col span={8} style={PrincipalColLabel}>*Tel</Col>
                                                    <Col span={7} style={PrincipalCol}><DescriptionsInput name={[field.name, 'name']} label="Name" required={true} /></Col>
                                                    <Col span={8} style={PrincipalCol}><DescriptionsEmail name={[field.name, 'email']} label="email" required={true} /></Col>
                                                    <Col span={8} style={PrincipalCol}><DescriptionsInput name={[field.name, 'tel']} label="Tel" required={true} /></Col>
                                                    <Col span={1}> <CloseCircleFilled style={{ margin: '0 15px', fontSize: '18px', color: '#A24F62' }} onClick={() => remove(field.name)} /></Col>
                                                </Row>
                                            </Row>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Descriptions.Item>
                        <Descriptions.Item span={6}><DescriptionsDetailsTitle title="SN LIST" /></Descriptions.Item>
                        <Descriptions.Item span={2}><DescriptionsDetailsTitle />
                            <RemoveConfirmButton label="Clear" onOk={handleClear} title="移除所有機台" confirmMsg= { <><div>請問您確定要將所有機台從此支援廠商中移除嗎?</div><div>Are you sure to clear all SN from current service site?</div></>}/>
                        </Descriptions.Item>
                        <Descriptions.Item span={2}><DescriptionsDetailsTitle />
                            <Button key="OK" type="primary" onClick={handleExport} >Export</Button>
                        </Descriptions.Item>
                        <Descriptions.Item span={2}><DescriptionsDetailsTitle />
                            <Upload {...props}><Button key="OK" type="primary" >Import</Button></Upload>
                        </Descriptions.Item>
                        <Descriptions.Item span={12}>
                            <p style={{ textAlign: 'right', fontSize: '12px', width: '100%' }} >*請先匯出檔案,編輯後再重新匯入</p>
                        </Descriptions.Item>
                        <Descriptions.Item span={12}>
                            <List
                                header={
                                    <>
                                        <Row>
                                            <Col style={{ border: '1px solid #707070', margin: '0px 10px', padding: '0px 15px', fontSize: '12px' }}>
                                                資料更新日期 : {formatDateTimeHasSec(snInfo.updateDate)} 共 {snInfo.count} 筆 </Col>
                                        </Row>
                                        <Row>

                                            <Col span={20} style={{ fontSize: '12px', color: 'red', margin: '0px 10px' }}>
                                                *機台序號若顯示為紅色代表此序號已經存在其他支援廠商下，請檢查您的資料再行匯入。
                                            </Col>
                                        </Row>
                                    </>

                                }
                                style={{ backgroundColor: '#E2D6CA', width: '100%' }}
                                grid={{
                                    gutter: 0,
                                    xs: 1,
                                    sm: 2,
                                    md: 3,
                                    lg: 4,
                                    xl: 5,
                                    xxl: 5
                                }}
                                dataSource={snInfo.snInfo}
                                size="small"
                                renderItem={item => {
                                    return (
                                        <List.Item style={{ color: item.used ? 'red' : '', textAlign: 'center', fontWeight: 'bold' }}>{item.sn}</List.Item>
                                    )
                                }}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
            </Modal>
        </>
    )
}

export default ServiceSiteDetails;