import React, {useState} from 'react';
import {Button, Col, Row, Space} from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import {CountryStateCitySelector} from '../../../Components/CountryStateCitySelector';
import {isEqual} from 'lodash'
import {DeviceListStatus} from '../Device/ShowDeviceStatus';
import BranchDetails from './BranchDetails';
import {ImportBranch} from '../../../Components/ImportPop';
import {SelectedTable} from '../../../Components/Table';
import {RemoveButton} from '../../../Components/Button';
import {getRoles} from '../../../Utils/session';
import {Roles} from '../../../Settings/Roles';

const BranchTable = () => {
    const roles = getRoles();
    const [deviceInfo, setDeviceInfo] = useState([]);
    const [selectSingleRow, setSelectSingleRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [openImportBranch, setOpenImportBranch] = useState(false);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState({
        searchColumn: '',
        searchWord: '',
    });

    //#region Column
    const branchNameClickHandler = (passedInData) => {
        console.debug('branchNameClickHandler(passedInData):', passedInData);

        (async () => {
            ApiProvider.GetDeviceRecipeByBranch(passedInData.branchId)
                .then(res => {
                    setDeviceInfo(res.data);
                });
        })();
        setOpenEdit(true);
        setSelectSingleRow(passedInData);
    };

    const columns = [
        {
            title: 'Store',
            dataIndex: 'branchName',
            render: (text, record, index) => {
                return (<Button type="link" onClick={() => branchNameClickHandler({...record})}>{text}</Button>);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            // ellipsis: true,
            render: (text, record, index) => (
                <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>{text}</div>
            ),
        },
        {
            title: 'Country/Region',
            dataIndex: 'country',
        },
        {
            title: 'State/Area',
            dataIndex: 'state',
        },
        {
            title: 'City',
            dataIndex: 'city',
        },
        {
            title: 'Address',
            dataIndex: 'street',
            // ellipsis: true,
            render: (text, record, index) => (
                <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>{text}</div>
            ),
        },
        {
            title: 'Branch Admin',
            dataIndex: 'branchAdmin',
        },
        {
            title: '',
            render: (value, record, index) => {
                if (record.deviceIdList.length > 0) {
                    return <DeviceListStatus deviceIdList={record.deviceIdList} />
                }
            },
        },
    ];
    //#endregion

    //#region table 上方 Row 相關
    const handleImportBranch = (e) => {
        setOpenImportBranch(true);
    };
    const handleNewBranch = () => {
        setOpenNew(true);
    }
    const handleCountryStateCityChange = (value, selectedOptions) => {
        console.debug('handleCountryStateCityChange:', value, selectedOptions);

        if (!value) {
            setSearch({
                searchColumn: '',
                searchWord: '',
            });
            return true;
        }

        if (isEqual(value.length, 1)) {
            setSearch({
                searchColumn: 'country',
                searchWord: value[0],
            });
        }
        if (isEqual(value.length, 2)) {
            setSearch({
                searchColumn: 'state',
                searchWord: value[1],
            });
        }
        if (isEqual(value.length, 3)) {
            setSearch({
                searchColumn: 'city',
                searchWord: value[2],
            });
        }
    };

    const tableTopRow = (
        <Row justify="space-between" style={{ marginBottom: '30px' }}>
            <Col>
                <CountryStateCitySelector onChange={handleCountryStateCityChange} />
            </Col>
            <Col>
                <Space>
                    <Button type="primary" style={{ display: "none" }} onClick={handleImportBranch}>Import Branch</Button>
                    <Button type="primary" style={{ display: (selectedRows.length === 0 && roles.includes(Roles.BrandAdmin) ? "" : "none") }} onClick={handleNewBranch}>New Branch</Button>
                    <RemoveButton
                        label="Remove"
                        service={ApiProvider.RemoveBranch}
                        param={selectedRows}
                        setReload={setReload}
                        style={{ display: (selectedRows.length > 0 ? "" : "none") }}
                    />
                </Space>
            </Col>
        </Row>
    );
    //#endregion

    const tableProps = {
        rowKey: 'branchId',
        ApiProviderUrl: ApiProvider.GetBranchDataSource,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        selectedRowKeys: selectedRowKeys,
        reload: reload,
        setReload: setReload,
        setSelectedRows: (e) => setSelectedRows(e),
        setSelectSingleRow: (e) => setSelectSingleRow(e),
        setSelectedRowKeys: (e) => setSelectedRowKeys(e),
    };

    return (
        <div>
            <SelectedTable {...tableProps} />
            <div style={{ fontSize: '12px' }}>&nbsp;</div>
            <ImportBranch open={openImportBranch} setOpen={(isOpen) => setOpenImportBranch(isOpen)} setReload={(isReload) => setReload(isReload)} />
            <BranchDetails title='Create New Branch' open={openNew} setOpen={(isOpen) => setOpenNew(isOpen)} setReload={(isReload) => setReload(isReload)} selected={[]} saveService={ApiProvider.CreateBranch} deviceInfo={[]} />
            <BranchDetails title='Edit' open={openEdit} setOpen={(isOpen) => setOpenEdit(isOpen)} setReload={(isReload) => setReload(isReload)} selected={selectSingleRow} saveService={ApiProvider.UpdateBranch} deviceInfo={deviceInfo} />
        </div>
    );
};

export default BranchTable;
