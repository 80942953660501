import React, {useEffect, useState} from 'react';
import {Cascader, Form} from 'antd';
import * as ApiProvider from '../Api/ApiProvider';
import {merge} from 'lodash';

const SetData = (res) => {
    let data = [];
    res.country.forEach(country => {
        data.push({
            value: country.text,
            label: country.text,
            children: country.state.map(function (state) {
                return {
                    value: state.text,
                    label: state.text,
                    children: state.city.map(function (city) {
                        return {
                            value: city.text,
                            label: city.text,
                        }
                    }),
                }
            }),
        })
        merge(data, { children: { aa: 'bb' } })
    });
    return data;
}

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}
export const CountryStateCitySelector = ({ onChange }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            ApiProvider.GetAllLocation()
                .then(res => {
                    setOptions(SetData(res.data))
                });
        }
        fetchData()
    }, []);

    return <Cascader style={{ width: "300px" }} options={options} onChange={onChange} changeOnSelect placeholder="Please select Country,State,City !" showSearch={{filter}} />;
}

//Form 專用
export const CountryStateCityFormSelector = (prop) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        async function fetchDataCountryStateCityFormSelector() {
            ApiProvider.GetAllLocation()
                .then(res => {
                    setOptions(SetData(res.data))
                });
        }
        fetchDataCountryStateCityFormSelector()
    }, []);

    return (
        <Form.Item name="countryStateCity" rules={[{ required: true, message: `Please select Country,State,City` }]} style={{ width: '100%' }} >
            <Cascader style={{ fontSize: "16px" }} options={options} changeOnSelect placeholder="Please select Country,State,City !" showSearch={{filter}} {...prop} />
        </Form.Item>
    );
}
