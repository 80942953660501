import React from 'react';
import medium from './RoastLevel/ic_roast_level_medium.svg';
import light from './RoastLevel/ic_roast_level_light.svg';
import mediumlight from './RoastLevel/ic_roast_level_medium_light.svg';
import mediumdark from './RoastLevel/ic_roast_level_mediumdark.svg';
import dark from './RoastLevel/ic_roast_level_dark.svg';

const roastLevel = {
    medium: 'Medium',
    light: 'Light',
    mediumdark: 'Mediumdark',
    mediumlight: 'Mediumlight',
    dark: 'Dark',
}

const RoastLevel = (prop) => {
    switch (prop.roastLevel) {
        case roastLevel.medium:
            return <img src={medium} alt={roastLevel.medium} />
        case roastLevel.light:
            return <img src={light} alt={roastLevel.light} />
        case roastLevel.mediumdark:
            return <img src={mediumdark} alt={roastLevel.mediumdark} />
        case roastLevel.mediumlight:
            return <img src={mediumlight} alt={roastLevel.mediumlight} />
        case roastLevel.dark:
            return <img src={dark} alt={roastLevel.dark} />
        default:
            return <></>
    }
}
export default RoastLevel;