import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Upload, Input } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { UploadOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

const UploadPop = ({ open, setOpen, setReload }) => {
    const [mcuFileList, setMcuFileList] = useState([]);
    const [wifiFileList, setWifiFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setMcuFileList([]);
        setWifiFileList([]);
        form.resetFields();
    }, [open, form]);

    const handleCancel = () => {
        setOpen(false)
    };

    const handleUpload = async (e) => {
        try {
            await form.validateFields();

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return
        }
        const formData = new FormData();
        formData.append('versionNo', e.versionNo);
        formData.append('mcuVersionNo', e.versionNo);
        formData.append('wifiVersionNo', e.versionNo);
        if (!isEmpty(mcuFileList[0])) {
            formData.append('mcuUpdateFile', mcuFileList[0]);
        }
        if (!isEmpty(wifiFileList[0])) {
            formData.append('wifiUpdateFile', wifiFileList[0]);
        }
        ApiProvider.SaveFirmwareVersion(formData)
            .then(res => {
                setUploading(false);
                setOpen(false);
                setReload(true);
            })
            .catch(err => {
                setUploading(false)
            });
    };
    const props = (fileList, setFileList) => {
        return ({
            onRemove: file => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            },
            beforeUpload: file => {
                setFileList([file]) //只能上傳一筆
                return false;
            },
            fileList
        })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    return (
        <Modal
            open={open}
            title="Upload"
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                <Button type="primary" form="myForm" key="submit" htmlType="submit" loading={uploading} disabled={(mcuFileList.length === 0 && wifiFileList.length === 0)}>Upload</Button>
            ]}
        >
            <Form
                {...layout}
                id="myForm"
                style={{ margin: '30px 30px' }}
                name="basic"
                initialValues={{
                    versionNo: "",
                }}
                onFinishFailed={onFinishFailed}
                onFinish={handleUpload}
            >
                <Form.Item
                    label="Version No"
                    name="versionNo"
                    rules={[{ required: true, message: `Please input Version No` }]}
                >
                    <Input style={{ margin: '0px 0px 10px 0px' }} />
                </Form.Item>
                <Form.Item label="MCU UpdateFile"
                    name="mcuUpdateFile"
                    initialValue="mcuUpdateFile"
                    style={{ margin: '0px 0px 10px 0px' }}
                >
                    <Upload {...props(mcuFileList, setMcuFileList)} accept=".bin">
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                </Form.Item>
                <Form.Item label="WIFI UpdateFile"
                    name="wifiUpdateFile"
                    initialValue="wifiUpdateFile"
                    style={{ margin: '0px 0px 10px 0px' }}
                >
                    <Upload {...props(wifiFileList, setWifiFileList)} accept=".bin">
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default UploadPop;