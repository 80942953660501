import React, { useState } from 'react';
import { Space, Button, Row } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { isEmpty, split } from 'lodash'
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { formatDateTime } from '../../../Utils/Utils'
import UploadPop from './UploadPop';
import { CommonTable } from '../../../Components/Table';

const UploadTable = () => {
    const [openUpload, setOpenUpload] = useState(false);
    const [reload, setReload] = useState(0);

    //#region Column
    const columns = [
        {
            title: 'Version No',
            dataIndex: 'versionNo',
            ellipsis: true,
            width: 100
        },
        {
            title: 'MCU Link',
            dataIndex: 'mcuLink',
            textWrap: 'word-break',
            ellipsis: true,
            render: (value, row, index) => {
                if (!isEmpty(value)) {
                    const mcuLink = split(value, '/');
                    return (<Button type="link" href={value} icon={<DownloadOutlined />}>{mcuLink[mcuLink.length - 1]}</Button>)
                }

            }
        },
        {
            title: 'WIFI Link',
            dataIndex: 'wifiLink',
            textWrap: 'word-break',
            ellipsis: true,
            render: (value, row, index) => {
                if (!isEmpty(value)) {
                    const wifiLink = split(value, '/');
                    return (<Button type="link" href={value} icon={<DownloadOutlined />}>{wifiLink[wifiLink.length - 1]}</Button>)
                }

            }
        },
        {
            title: 'Created User',
            dataIndex: 'createdUserName',
            ellipsis: true,
            width:150,
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            ellipsis: true,
            width:120,
            render: (value, row, index) => {
                return (<div>{formatDateTime(value)}</div>)
            }
        },
    ];
    //#endregion

    //#region table 上方 Row 相關
    function handleUpload(e) {
        setOpenUpload(true)
    }
    const tableTopRow = (
        <>
            <Row style={{ marginBottom: '30px' }}>
                <Space>
                    <Button type='primary' onClick={(e) => handleUpload(e)} icon={<UploadOutlined />} >Upload</Button>
                </Space>
            </Row>

        </>
    )
    //#endregion

    const tableProps = {
        rowKey: 'id',
        ApiProviderUrl: ApiProvider.GetFMUploadList,
        search: {},
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        setReload: setReload,
        setSelectedRows: (e) => {},
        setSelectSingleRow: (e) => {},
        setSelectedRowKeys: (e) => {},
    }
    return (
        <div>
            <CommonTable {...tableProps} />
            <UploadPop open={openUpload} setOpen={(isOpen) => setOpenUpload(isOpen)} setReload={(isReload) => setReload(isReload)} ></UploadPop>
        </div>
    );
};

export default UploadTable;

