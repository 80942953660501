import React, { useState } from 'react';
import { Space, Button, Row, Col, Select } from 'antd';
import SingleAssign from './SingleAssign';
import * as ApiProvider from '../../../Api/ApiProvider';
import { formatDate } from '../../../Utils/Utils';
import MultiAssign from './MultiAssign';
import AssignResult from './AssignResult';
import SearchInput from '../../../Components/SearchInput';
import { CommonTable, SelectedTable, columnFilterList } from '../../../Components/Table';
import { RemoveButton } from '../../../Components/Button';
import { isRootRoles } from '../../../Settings/Roles';
import * as Message from '../../../Components/Message';
import { merge } from 'lodash';

const RecipeTypeName = {
  public: 'public',
  private: 'private',
  history: 'history',
};

export const PublicRootRecipeTable = ({ activeKey }) => {
  return <RecipeTable activeKey={activeKey} name={RecipeTypeName.public} isShowCheckBox={false} />
}
export const PrivateRootRecipeTable = ({ activeKey }) => {
  return <RecipeTable activeKey={activeKey} name={RecipeTypeName.private} isShowCheckBox={false} />
}
export const HistoryRootRecipeTable = ({ activeKey }) => {
  return <RecipeTable activeKey={activeKey} name={RecipeTypeName.history} isShowCheckBox={false} />
}
export const PublicRecipeTable = ({ activeKey }) => {
  return <RecipeTable activeKey={activeKey} name={RecipeTypeName.public} />
}
export const PrivateRecipeTable = ({ activeKey }) => {
  return <RecipeTable activeKey={activeKey} name={RecipeTypeName.private} />
}
export const HistoryRecipeTable = ({ activeKey }) => {
  return <RecipeTable activeKey={activeKey} name={RecipeTypeName.history} />
}

const RecipeTable = ({ activeKey, name, isShowCheckBox = true }) => {
  const [openSingleAssign, setOpenSingleAssign] = useState(false);
  const [openMultiAssign, setOpenMultiAssign] = useState(false);
  const [openAssignResult, setOpenAssignResult] = useState(false);
  const [assignBody, setAssignResult] = useState([]);
  //是否顯示 CheckBox : 非原廠、非歷史頁面
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectSingleRow, setSelectSingleRow] = useState(null);
  const [reload, setReload] = useState(false);
  //#region  condition
  const [search, setSearch] = useState({
    searchColumn: 'recipeName',
    searchWord: ''
  });

  //#region Column
  const handleClickRecipeName = (e) => {
    setOpenSingleAssign(true)
    setSelectSingleRow(e.row)
  }
  const columns = [
    {
      title: '',
      dataIndex: 'seqNo',
    },
    {
      isFilter: true,
      title: 'Recipe Name',
      dataIndex: 'recipeName',
      render: (value, row, index) => {
        return (<Button type='link' onClick={() => handleClickRecipeName({ row })}>{value}</Button>)
      },
    },
    {
      title: 'Flavor & Note',
      dataIndex: 'flavorNote',
      width: '400px'
    },
    {
      display: isRootRoles(),
      isFilter: isRootRoles(),
      title: 'Brand',
      dataIndex: 'brand',
    },

    {
      title: 'Brew Times',
      dataIndex: 'brewTimes',
      sorter: true,
    },
    {
      title: 'Assign Times',
      dataIndex: 'assignTimes',
      sorter: true,
    },
    {
      isFilter: true,
      title: 'Create Time',
      dataIndex: 'createdDate',
      sorter: true,
      render: (Text) => <div>{formatDate(Text)}</div>,
    },
    {
      isFilter: true,
      title: 'Create Person',
      dataIndex: 'createdUserName',
    },
  ];

  //#endregion

  //#region table 上方 Row 相關
  function handleMenuClick(e) {
    setSearch({
      ...search,
      searchColumn: e
    })
  }
  function handleAssignTo(e) {
    setOpenMultiAssign(true)
  }
  const handleRestore = () => {
    async function restore() {
      ApiProvider.RecoverRecipe(selectedRows)
        .then(res => {
          setReload(true);
          Message.success('Restore success')
        })
        .catch(err => {
          Message.error(`Restore error : ${err.response.data} `)
        });
    }
    restore();
  };
  const tableTopRow = (
    <Row justify="space-between" style={{ marginBottom: '30px' }}>
      <Col>
        <Space>
          <div>
            <Select dropdownMatchSelectWidth={false} defaultValue={search.searchColumn} options={columnFilterList(columns)} onSelect={(e) => handleMenuClick(e)}>
            </Select>
          </div>
          <div>
            <SearchInput onPressEnter={(value) => setSearch({ ...search, searchWord: value })} />
          </div>
        </Space>
      </Col>
      <Col>
        <Space>
          <Button type="primary" style={{ display: (name !== RecipeTypeName.history && selectedRowKeys.length > 0 && selectedRowKeys.length < 4 ? "" : "none") }} onClick={(e) => handleAssignTo(e)}>Assign To</Button>
          {/* 只有 private 可以移除 Recipe */}
          <RemoveButton
            label="Archive"
            service={ApiProvider.RemoveRecipe}
            param={selectedRows}
            setReload={setReload}
            confirmMsg={<div><div>Are you sure to archive those items to history</div>{selectedRows.map(i => <div>-{i.recipeName}</div>)}</div>}
            style={{ display: (name !== RecipeTypeName.history && selectedRows.length > 0 ? "" : "none") }} />
          {/* 只有 history 可以 restore Recipe */}
          <Button type="primary" style={{ display: (name === RecipeTypeName.history && selectedRows.length > 0 ? "" : "none") }} onClick={handleRestore}>Restore</Button>
        </Space>
      </Col>
    </Row>
  )


  //#endregion
  //執行 Assign 開啟 result 視窗
  const handleAssignResult = (result) => {
    let deviceList = []
    result.map(i => i.deviceList.map(device => deviceList.push(device)));
    const unique = [...new Set(deviceList.map(item => item.value))]

    //再檢查一次所有機台連線狀況
    ApiProvider.GetMultiIsDeviceConnected(unique)
      .then(res => {
        const deviceConnected = res.map((getIsConnect) => ({
          deviceSn: getIsConnect.config.url.split("sn=")[1],
          isConnect: getIsConnect.data
        }))
        setAssignResult(merge({ bodies: result }, {
          deviceConnected: deviceConnected
        }))
        setOpenAssignResult(true)
      });
  }

  const tableProps = {
    active: activeKey,
    rowKey: 'seqNo',
    ApiProviderUrl: ApiProvider.GetRecipeDataSource,
    condition: { type: name },
    search: search,
    tableTopRow: tableTopRow,
    columns: columns,
    reload: reload,
    setReload: setReload,
    setSelectedRows: (e) => setSelectedRows(e),
    setSelectSingleRow: (e) => setSelectSingleRow(e),
    setSelectedRowKeys: (e) => setSelectedRowKeys(e),
  }
  return (
    <div>
      {isShowCheckBox ? <SelectedTable {...tableProps} /> : <CommonTable {...tableProps}></CommonTable>}
      <SingleAssign open={openSingleAssign} setOpen={(isOpen) => setOpenSingleAssign(isOpen)} selected={selectSingleRow} readonly={!isShowCheckBox} handleAssignResult={handleAssignResult}></SingleAssign>
      <MultiAssign open={openMultiAssign} setOpen={(isOpen) => setOpenMultiAssign(isOpen)} selected={selectedRows} handleAssignResult={handleAssignResult}></MultiAssign>
      <AssignResult open={openAssignResult} setOpen={(isOpen) => setOpenAssignResult(isOpen)} assignbody={assignBody} setReload={setReload}></AssignResult>
    </div>
  );
};
