import React, { useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchInput = ({ onPressEnter }) => {
    const [search, setSearch] = useState('');

    return (
        <>
            <Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='search' onPressEnter={() => onPressEnter(search)} suffix={<SearchOutlined onClick={() => onPressEnter(search)} />} />
        </>

    );
}


export default SearchInput;