import React, { useEffect, useState } from 'react';
import * as ApiProvider from '../../../Api/ApiProvider';
import { Modal, Button, Row, Descriptions, Form, Divider, Select } from 'antd';
import { isEmpty } from 'lodash';
import { ServiceStatus } from '../../../Utils/Enum';
import { DownloadOutlined } from '@ant-design/icons';
import * as Message from '../../../Components/Message';
import { DescriptionsInput } from '../../../Components/Descriptions'
import PopoverInfo from '../../../Components/PopoverInfo';

const { Option } = Select;

const ServiceFormDetails = ({ open, setOpen, selected, title, setReload }) => {
    const [form] = Form.useForm();
    const [loading, setloading] = useState(false);

    useEffect(() => {
        form.resetFields();
        if (!isEmpty(selected)) {
            console.log(selected)
            const fieldsValue = {
                repairId: selected.repairId,
                status: selected.status,
                remark: selected.remark
            };
            console.log(fieldsValue)
            form.setFieldsValue(fieldsValue)
        }
    }, [form, selected, open]);

    const handleCancel = () => {
        closeDetails()
    };
    const handleSave = () => {
        const fieldValue = form.getFieldValue();
        setloading(true);
        async function saveData() {
            ApiProvider.UpdateRepairStatus(fieldValue)
                .then(res => {
                    Message.success(`${title} success`)
                    setOpen(false);
                    setloading(false);
                    setReload(true);
                })
                .catch(err => {
                    Message.error(`${title} error`)
                    setloading(false);
                });
        }
        saveData()
    }
    const closeDetails = () => {
        setOpen(false)
    }


    return (
        <Modal
            maskClosable={false}
            width={800}
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                <Button key="Save" type="primary" onClick={() => handleSave()} loading={loading}>Save</Button>,
            ]}
        >
            <Row>
                <Form
                    form={form}
                    requiredMark={false}
                    size='middle'
                    onFinish={() => { }}
                >
                    <Descriptions title="Service Form" layout="vertical" column={12} >
                        <Descriptions.Item label="Applied By" span={4}>{selected?.createdUserName}</Descriptions.Item>
                        <Descriptions.Item label="Phone Number" span={4}>{selected?.tel}</Descriptions.Item>
                        <Descriptions.Item label="Email" span={4}>{selected?.email}</Descriptions.Item>
                        <Descriptions.Item label="Device" span={4}>{selected?.deviceName}</Descriptions.Item>
                        <Descriptions.Item label="SN" span={4}>{selected?.deviceSn}</Descriptions.Item>
                        <Descriptions.Item label="Service Site" span={4}>{selected?.vendorName}</Descriptions.Item>
                        <Descriptions.Item label="Brand" span={4}>{selected?.brandName}</Descriptions.Item>
                        <Descriptions.Item label="Branch" span={4}>{selected?.branchName}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Service Request" layout="horizontal" column={12} >
                    <Descriptions.Item span={12} label="Occurred Date :"></Descriptions.Item>
                    <Descriptions.Item span={12} >{selected?.happenDate}</Descriptions.Item>
                        <Descriptions.Item span={12} label="Maintenance or service item:"></Descriptions.Item>
                        <Descriptions.Item span={12}>{selected?.issueType}</Descriptions.Item>
                        {selected?.repairContents?.map(i =>
                            <>
                                <Descriptions.Item span={10} label={i.question}></Descriptions.Item>
                                <Descriptions.Item span={2}>{i.answer}</Descriptions.Item>
                                <Descriptions.Item span={12}><Divider style={{ margin: '0px', borderColor: '#707070' }} /></Descriptions.Item>
                            </>
                        )}
                    </Descriptions>
                    <Descriptions title="Attachment" layout="horizontal" column={12} >
                        {selected?.fileLink?.map((value, i) => <Descriptions.Item span={12}><Button type="link" href={value} icon={<DownloadOutlined />}>{selected?.deviceSn}-{i}</Button></Descriptions.Item>)}
                    </Descriptions>
                    <Descriptions title="Service Request" layout="vertical" column={12} >
                        <Descriptions.Item label={<>Treatment Record<PopoverInfo infoContent={
                                     <div>
                                     <div>-New: 未讀取的新案件 Unread cases</div>
                                     <div>-Open: 已讀取案件 Read cases</div>
                                     <div>-Active: 處理中案件 Processing (若已經有解決方式與計畫，請選此項並於下方Remark註記內容)</div>
                                     <div>-Close: 已結案案件 Closed (若已經解決使用者問題，請選此項並於下方Remark註記內容)</div>
                                 </div>
                        }/></>} span={6}>
                            <Form.Item name='status'>
                                <Select>
                                    {Object.values(ServiceStatus).map(i =>
                                        <Option key={i} value={i}>{i}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        
                        </Descriptions.Item>
                        <Descriptions.Item label="Service Site" span={6}>{selected?.vendorName}</Descriptions.Item>
                        <Descriptions.Item label="Remark" span={12}>
                            <DescriptionsInput name="remark" />
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
            </Row>
        </Modal>
    )
}
export default ServiceFormDetails

