import React, { useState, useEffect } from 'react';
import { Drawer, Card, Tag, Button, Skeleton, Empty, Modal } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as ApiProvider from '../../../Api/ApiProvider';
import { formatDateTime } from '../../../Utils/Utils';
import { ActionContext } from '../../../Context/ActionContext';
import * as Message from '../../../Components/Message'
const Retry = ({ open, setOpen }) => {
    const { updateCount } = React.useContext(ActionContext);
    const [failData, setFailData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showEmpty, setShowEmpty] = useState(false);


    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            GetFailedRecipe()
        }
        if (open) {
            fetchData()
        }
    }, [open]);

    const GetFailedRecipe = () => {
        ApiProvider.GetFailedRecipe()
            .then(result => {
                setFailData(result.data)
                setShowEmpty(result.data.length === 0)
                setLoading(false)

            })
    }


    const onClose = () => {
        setOpen(false);
        updateCount();
    };

    const Title = ({ date, recipeName }) => {
        return (
            <>
                <div style={{ fontSize: '12px' }}>{formatDateTime(date)}</div>
                <div style={{ fontSize: '18px' }}>Assign Recipe</div>
                <div style={{ fontSize: '16px' }}>{recipeName}</div>
            </>
        )
    }
    const RetryCard = ({ item }) => {
        const [retryLoading, setRetryLoading] = useState(false);

        const onRetry = () => {
            setRetryLoading(true)
            const body = {
                deviceId: item.deviceId,
                deviceName: item.deviceName,
                recipeId: item.recipeId,
                recipeName: item.recipeName,
                recipeIndex: item.recipeIndex,
                uniqueId: item.uniqueId,
            }
            ApiProvider.ReAssignRecipe(body)
                .then(res => {                   
                    if (res.data.fail.length > 0) {
                        Message.error(item.deviceName + 'Assign Recipe fail')
                    }else{
                        Message.success(item.deviceName + 'Assign Recipe success')
                    }                    
                })
                .finally(res=>{                 
                    setRetryLoading(false)
                    GetFailedRecipe()
                })
        };

        const handleRemove = () => {
            setLoading(true)
            ApiProvider.RemoveFailedRecipe(item)
                .then(res => {
                    GetFailedRecipe()
                    Message.success('Remove success')
                })
                .catch(err => {

                    Message.error(`Restore error : ${err.response.data} `)
                    console.log("contnect NG " + JSON.stringify(err));
                    setLoading(false)
                });
        };
        function onDelete() {
            Modal.confirm({
                title: 'Remove',
                icon: <ExclamationCircleOutlined />,
                content: 'Are you sure ?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: handleRemove
            });
        }
        return (
            <Card style={{ margin: '10px' }} title={<Title date={item.errorDateTime} recipeName={item.recipeName}></Title>}
                extra={<CloseOutlined key="CloseOutlined" onClick={() => onDelete()} />}
                actions={[<Button type='primary' style={{ width: '130px' }} onClick={() => onRetry()} loading={retryLoading}>Retry</Button>]}>
                <Skeleton loading={loading} avatar active>
                    <p>You have an assigned formula that has not been fully assigned. Do you want to continue?</p>
                    <div style={{ fontSize: '14px', color: '#BC4242' }}>Assign Fail</div>
                    <Tag closable={false} onClose={false} color='red'>{item.deviceName}</Tag>
                </Skeleton>
            </Card >
        )
    }
    return (
        <>
            <Drawer
                title="Notification"
                placement="right"
                closable={false}
                onClose={onClose}
                open={open}
                width={350}
            >
                {failData.map(item => <RetryCard key={item.uniqueId} item={item} />)}
                <Empty style={{ display: showEmpty ? '' : 'none' }}></Empty>
            </Drawer>
        </>
    );
};

export default Retry;