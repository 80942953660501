import React, { useState } from 'react';
import { Tabs } from 'antd';
import { DeviceOrgReport, DeviceBranchReport } from './DeviceReport'
const { TabPane } = Tabs;


export const DeviceOrgReportTab = ({ isAgent=false }) => {
    const [activeKey, setActiveKey] = useState('clean1');
    function callback(key) {
        setActiveKey(key);
    }
    return (
        <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Clean Record" key="clean1">
                <DeviceOrgReport recipeIndex={activeKey} isAgent={isAgent} />
            </TabPane>
            <TabPane tab="Deep Clean Record" key="clean2">
                <DeviceOrgReport recipeIndex={activeKey} isAgent={isAgent}/>
            </TabPane>
        </Tabs>
    )

}
export const DeviceBrandReportTab = () => {
    const [activeKey, setActiveKey] = useState('clean1');
    function callback(key) {

        setActiveKey(key);
    }


    return (
        <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Clean Record" key="clean1">
                <DeviceBranchReport recipeIndex={activeKey} />
            </TabPane>
            <TabPane tab="Deep Clean Record" key="clean2">
                <DeviceBranchReport recipeIndex={activeKey} />
            </TabPane>
        </Tabs>
    )

}



