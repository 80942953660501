import React from 'react';
import { notification, Modal } from "antd"

export const success = (content) => {

  return notification.success({
    message: content,
    placement: 'bottomLeft'
  })
}


export const error = (content) => {
  return notification.error({
    message: content,
    placement: 'bottomLeft'
  })
}

export const warning = (content) => {
  return notification.warning({
    message: content,
    placement: 'bottomLeft'
  })
};

export const confirm = ({ title, confirmMsg, okText, onOk, isDanger }) => {
  return Modal.confirm({
    closeIcon: true,
    title: <div style={{ textAlign: 'center', color: '#A24F62', fontSize: '22px', margin: '10px 0px' }}>{title}</div>,
    icon: <></>,
    content: <div style={{ textAlign: 'center', color: '#707070', fontSize: '18px', margin: '10px 0px' }}>{confirmMsg === '' ? `Are you sure to ${okText}?` : confirmMsg}</div>,
    okText: okText,
    okType: isDanger ? 'danger' : 'primary',
    cancelText: 'Cancel',
    width: 600,
    onOk: onOk,
    centered: true
  });
};