import React from 'react';
import { Tooltip } from 'antd';
import system_admin from './Authority/ic_system admin.svg';
import { RolesDetails } from '../Settings/Roles';

export const SystemAdmin = (prop) => {
    return <img style={{ margin: '0 5px' }} src={system_admin} alt={RolesDetails.SystemAdmin.label}></img>
}

export const Authority = (prop) => {
    if(!RolesDetails[prop.type]){return <></>}
    return (
        <Tooltip title={RolesDetails[prop.type].label} >
            <img style={{ margin: '0 5px' }} src={RolesDetails[prop.type].picSrc} alt={RolesDetails[prop.type].label}></img>
        </Tooltip>
    )

}
export const AuthorityhasLable = (prop) => {
    return (
        <>
            <svg width="2em" height="1.1em" fill={RolesDetails[prop.type].picColor}> <circle cx="10" cy="10" r="3" /></svg>
            {RolesDetails[prop.type].tag}
        </>


    )

}

