import React, {  useEffect } from 'react';
import { Modal, Button, Row, Descriptions, Form, Input } from 'antd';
import { CountryStateCityFormSelector } from '../../../Components/CountryStateCitySelector';
import { isEmpty } from 'lodash';

const CustomerDetails = ({ open, setOpen, selected, title }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        if (!isEmpty(selected)) {
            console.log(selected)
            const fieldsValue = Deserialization(selected);
            console.log(fieldsValue)
            form.setFieldsValue(fieldsValue)
        }       
    }, [form, selected, open]);

    const handleCancel = () => {
        setOpen(false)
    };

    const Deserialization = (row) => {
        return (
            {
                branchName: row.branchName,
                email: row.email,
                tel: row.tel,
                countryStateCity: [row.country, row.state, row.city],
                zipCode: row.zipCode,
                street: row.street,
            }
        );
    }
  
    const DescriptionsInput = ({ name, label, required = true, readOnly = false }) => (
        <Form.Item name={name} rules={[{ required: required, message: `Please input ${label}` }]}>
            {readOnly ? <Input readOnly /> : <Input />}
        </Form.Item>
    )
    const DescriptionsEmail = (props) => (
        <Form.Item name={props.name} rules={[
            {
                type: 'email',
                message: 'The input is not valid E-mail!',
            },
            {
                required: true,
                message: "Please input Email"
            }
        ]}>
            <Input />
        </Form.Item>
    )
    return (
        <Modal
            width={800}
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Close</Button>,               
            ]}
        >
            <Row>
            <Form
                    form={form}
                    requiredMark={false}
                    size='middle'
                    onFinish={()=>{}}
                >
                    <Descriptions title={title} layout="vertical" column={12} >
                        <Descriptions.Item label="Customer Name" span={6}>
                            <DescriptionsInput name='branchName' label="Branch Name" readOnly={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Company Name" span={6}>
                        <DescriptionsInput name='branchName' label="Branch Name" readOnly={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Email" span={12}>
                            <DescriptionsEmail name="email" label="*Email" required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Tel" span={12}>
                            <DescriptionsInput name='tel' label="Tel"  required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Country/City" span={8}>
                            <Form.Item name="countryStateCity">
                                < CountryStateCityFormSelector required={true}/>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Post Code" span={4}>
                            <DescriptionsInput name='zipCode' label="Post Code"  required={true} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Street" span={12}>
                            <DescriptionsInput name='street' label="Street"  required={true}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
            </Row>
        </Modal>
    )
}

export default CustomerDetails;