import React, { useState, useEffect } from 'react';
import { Modal, Button, Descriptions, Form, message } from 'antd';
import { isEmpty } from 'lodash';
import { DescriptionsTextArea, DescriptionsInput, DescriptionsSelet } from '../../../Components/Descriptions';
import * as Message from '../../../Components/Message';
import { Tabs } from 'antd';
import { NotificationType, NotificationLanguageTab } from '../../../CoffeeCloudConfig';
const { TabPane } = Tabs;

const NotificationManagementDetails = ({ open, setOpen, setReload, selected, saveService, title, isEdit }) => {
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();

    const filteredOptions = NotificationType.map(i => ({ value: i, label: i }));

    useEffect(() => {
        form.resetFields();
        if (!isEmpty(selected)) {
            console.log(selected)
            const fieldsValue = Deserialization(selected);
            console.log(fieldsValue)
            form.setFieldsValue(fieldsValue)

        }
    }, [form, open, selected]);

    const handleCancel = () => {
        setOpen(false)
    };

    const Deserialization = (row) => {
        return (
            {
                ...row
            }
        );
    }
    const Serialization = (fieldValue) => {
        return (
            {
                ...fieldValue
            });
    }
    function checkTabInfo(fieldValue) {
        let unSaveTab = [];
        NotificationLanguageTab.forEach(tabName => {
            if (!Object.keys(fieldValue).includes(tabName.key)) {
                unSaveTab.push(tabName.tab)
                return
            }
            if (!fieldValue[tabName.key].subject || !fieldValue[tabName.key].content) {
                unSaveTab.push(tabName.tab)
                return
            }
        })
        if (unSaveTab.length > 0) {
            message.warning("請完整設定" + unSaveTab.join("，"))
        }
        return unSaveTab.length > 0;
    }

    //Save
    const handleOk = async () => {

        const fieldValue = form.getFieldValue();
        try {
            await form.validateFields();

        } catch (errorInfo) {       
            checkTabInfo(fieldValue)
            console.log('Failed:', errorInfo);
            return
        }

        if(checkTabInfo(fieldValue)){
            return
        }

        console.log(Serialization(fieldValue))
        setloading(true);
        async function saveData() {
            saveService(Serialization(fieldValue))
                .then(res => {
                    Message.success(`${title} success`)
                    setOpen(false);
                    setloading(false);
                    setReload(true)
                })
                .catch(err => {
                    Message.error({
                        message: err.response.data.msg,
                        placement: 'topRight'
                    })
                    setloading(false);
                });
        }
        saveData()
    };


    const Content = ({ id }) => {
        return (
            <Descriptions layout="vertical" column={12} >
                <Descriptions.Item label="*Subject" span={12}>
                    <DescriptionsInput name={[id, 'subject']} label="Subject" required={true} />
                </Descriptions.Item>
                <Descriptions.Item label="*Content" span={12}>
                    <DescriptionsTextArea name={[id, 'content']} label="Content" required={true} />
                </Descriptions.Item>
            </Descriptions>
        )
    }
    return (
        <Modal
            width={1000}
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Cancel</Button>,
                <Button key="OK" type="primary" loading={loading} onClick={handleOk}  >Save</Button>,
            ]}
        >
            <Form
                form={form}
                requiredMark={false}
                size='middle'
                onFinish={handleOk}
            >
                <Descriptions title={title} layout="vertical" column={12} >
                    <Descriptions.Item span={6} label="*Type">
                        <DescriptionsSelet
                            required={true}
                            name="type"
                            label="Type"
                            list={filteredOptions}
                            dropdownMatchSelectWidth={false} />
                    </Descriptions.Item>
                    <Descriptions.Item span={6}>
                    </Descriptions.Item>
                </Descriptions>
                <Tabs tabPosition="top">
                    {NotificationLanguageTab.map(tab => {
                        return (<TabPane tab={tab.tab} key={tab.key}>
                            <Content id={tab.key} />
                        </TabPane>)
                    }
                    )}
                </Tabs>
            </Form>
        </Modal>
    )
}

export default NotificationManagementDetails; 