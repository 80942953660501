import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { PaginationSetting } from '../CoffeeCloudConfig';
import useService from "../CustomerHook/useService";

export const columnFilterList = (columns) => {
    return columns.filter(i => i.isFilter).map(function (i) {
        var rObj = {};
        rObj.value = i.dataIndex;
        rObj.label = `By ${i.title} `;
        rObj.key = i.dataIndex;
        return rObj;
    })
};

export const SelectedTable = (props) => {
    return <BaseTable props={props} showRowSelection={true} />
};

export const CommonTable = (props) => {
    return <BaseTable props={props} />
};

const BaseTable = ({ props, showRowSelection = false }) => {
    //#region condition
    const [pagination, setPage] = useState(PaginationSetting);
    //#endregion

    const { loading: girdLoading = false, response = {} } = useService(
        props.ApiProviderUrl,
        {
            active: props.active,
            ...props.condition,
            search: props.search,
            pagination,
        },
        props.reload,
        props.setReload
    );
    const { data = [], total } = response || {};

    useEffect(() => {
        if (response && props.setResponse) {
            props.setResponse(response.data);
        }
    }, [props, response]);

    useEffect(() => {
        if (props.reload) {
            console.debug('reload table');
            props.setSelectedRows([]);
            props.setSelectSingleRow('');
            props.setSelectedRowKeys([]);
        }
    }, [props]);

    const paginationSetting = {
        pagination: {
            showSizeChanger: pagination.showSizeChanger,
            defaultCurrent: pagination.current,
            current: pagination.current > Math.ceil(total / pagination.pageSize) ? 1 : pagination.current,
            pageSize: pagination.pageSize,
            total: total,
            size: "small",
            showTotal: t => `Total ${t} items `,
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        if (!sorter.order) {
            sorter.field = "";
            sorter.order = "";
        }

        setPage({
            ...pagination,
            current: page.current,
            pageSize: pagination.pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order.slice(0, -3)
        })
    }
    const rowSelection = {
        hideSelectAll: true,
        selectedRowKeys: props.selectedRowKeys,
        onChange: (keys, selectedRows) => {
            props.setSelectedRowKeys(keys);
            props.setSelectedRows(selectedRows);
        },
    };
    return (
        <div>
            {props.tableTopRow}
            <Table
                {...props}
                {...showRowSelection ? { rowSelection: rowSelection } : ''}
                columns={props.columns.filter(i => (i.display === undefined) ? true : i.display)}     //display 預設顯示，可以自訂是否要顯示欄位
                dataSource={data}
                {...paginationSetting}
                className="table"
                rowKey={props.rowKey}
                onChange={handleTableChange}
                loading={girdLoading}
                expandable={props.expandable}
            />
        </div>
    );
}
