import React from 'react';
import {Col, Form, Input, InputNumber, Radio, Row, Select} from 'antd';

const { Option } = Select;
const { TextArea } = Input;

export const DescriptionsDetailsTitle = (props) => (
    <div className="detailLabel" style={{ letterSpacing: '2.4px', margin: '20px 0' }}>{props.title}</div>
)
export const DescriptionsRadioGroup = (props) => {
    const required = props.required === undefined ? false : props.required;
    const schema = props.schema ? props.schema : props;
    return (
        <Form.Item name={schema.name} rules={[{ required: required, message: `Please select ${schema.label}` }]}>
            <Radio.Group {...props} buttonStyle="solid">
                {schema.list.map(i => <Radio.Button key={i} value={i}>{i}</Radio.Button>)}
            </Radio.Group>
        </Form.Item>
    )
}
export const DescriptionsHalfInputNumber = (props) => {
    const required = props.required === undefined ? false : props.required;
    const schema = props.schema ? props.schema : props;
    return (
        <>
            <Col span={8}>
                <Form.Item name={schema.name} rules={[{ required: required, message: `Please input ${schema.label} ` }]}>
                    <InputNumber
                        style={{ width: '100%' , fontSize:'16px'}}
                        min={0}
                        max={1000}
                        keyboard={true}
                        {...props}
                    />
                </Form.Item>
            </Col >
            <Col span={3}>{props.text}</Col>
        </>
    )
}
export const DescriptionsInputNumber = (props) => {
    const required = props.required === undefined ? false : props.required;
    const schema = props.schema ? props.schema : props;
    return (
        <Row>
            <Col span={21}>
                <Form.Item name={schema.name} rules={[{ required: required, message: `Please input ${schema.label} ` }]}>
                    <InputNumber
                        style={{ width: '100%', fontSize:'16px' }}
                        min={0}
                        max={1000}
                        keyboard={true}
                        {...props}
                    />
                </Form.Item>
            </Col >
            <Col span={3}>{props.text}</Col>
        </Row>
    )
}
export const DescriptionsSelet = (props) => {
    const required = props.required === undefined ? false : props.required;
    const schema = props.schema ? props.schema : props;
    return (
        <Form.Item style={{ width: '100%' }} name={schema.name} rules={[{ required: required, message: `Please select ${schema.label}` }]}>
            <Select  style={{ fontSize:'16px' }} placeholder={`Please select ${schema.label}`} {...props}>
                {schema.list.map(i =>
                    <Option key={i.value} value={i.value} disabled={i.disabled} >{i.label}</Option>
                )}
            </Select>
        </Form.Item>
    )
}
export const DescriptionsInput = (props) => {
    const required = props.required === undefined ? false : props.required;
    const readOnly = props.readOnly === undefined ? false : props.readOnly;

    let rules = [].concat(
        [{ required: required, message: `Please input ${props.label}`}],
        props.rules || [],
    );

    return (
        <Form.Item style={{ width: '100%' }} name={props.name} rules={rules}>
            {readOnly ? <Input readOnly style={{ fontSize:'16px' }} {...props} /> : <Input style={{ fontSize:'16px' }}  {...props} />}
        </Form.Item>
    )
}
export const DescriptionsTextArea = (props) => {
    const required = props.required === undefined ? false : props.required;
    const readOnly = props.readOnly === undefined ? false : props.readOnly;
    return (
        <Form.Item style={{ width: '100%' }} name={props.name} rules={[{ required: required, message: `Please input ${props.label}` }]}>
            {readOnly ?  <TextArea placeholder="textarea with clear icon" allowClear />: <TextArea style={{ fontSize:'16px' }}  {...props}/>}
        </Form.Item>
    )
}
export const DescriptionsEmail = (props) =>  {
    const readOnly = props.readOnly === undefined ? false : props.readOnly;
    return (
    <Form.Item name={props.name} style={{ width: '100%' }} rules={[
        {
            type: 'email',
            message: 'The input is not valid E-mail!',
        },
        {
            required: true,
            message: "Please input Email"
        }
    ]}>
          {readOnly ? <Input readOnly style={{ fontSize:'16px' }} /> : <Input style={{ fontSize:'16px' }} />}
    </Form.Item>)
}
