import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Button, Form } from 'antd';
import { ActionContext } from '../../../Context/ActionContext';
import LoginPic from '../../../image/Login.jpg';
import { useLocation } from 'react-router-dom';
import { NewPasswordInput, ConfirmPasswordInput } from '../../../Components/PasswordInput';

const { Content } = Layout;

const formlayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
  style: { padding: '60px 40px 10px 40px', float: 'center', height: '100vh', width: '100%' }
};

function ResetPassword(props) {
  const location = useLocation()
  //# regin 用 queryString + 取代為空白，目前還不知道怎麼解先用取代的方式 
  // const queryString = require('query-string');
  // const param = queryString.parse(location.search);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const param = { encryptedString: location.search.replace('?encryptedString=', '') }
  //# end regin 
  const { resetPassword, VerifyResetPasswordUrl } = React.useContext(ActionContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    //檢查驗證碼是否失效
    VerifyResetPasswordUrl(encodeURIComponent(param.encryptedString))
  }, [VerifyResetPasswordUrl, param])

  const onFinish = (values) => {
    setLoading(true);
    resetPassword({ confirmPassword: values.confirm, newPassword: values.password, encryptedString: param.encryptedString }).then((result) => {
      form.resetFields();
      setLoading(false);
    })
  };

  return (
    <Layout style={{ background: '#F5F2EF' }}>
      <Content>
        <Row >
          <Col xs={24} sm={24} md={24} lg={9} xl={9} >
            <Form
              form={form}
              requiredMark={false}
              size='middle'
              onFinish={onFinish}
              {...formlayout}
            >
              <Form.Item>
                <div style={{ fontSize: '28px', color: '#453539', textAlign: 'center', margin: '0', fontWeight: 'inhenormalrit' }}>coffee cloud</div>
                <div style={{ fontSize: '49px', color: '#404040', textAlign: 'center', fontWeight: 'lighter' }}>WELCOME</div>
              </Form.Item>
              <NewPasswordInput
                name="password"
                label="Password" />
              <ConfirmPasswordInput
                name="confirm"
                label="Confirm Password"
                checkname="password"
              />
              <Form.Item style={{ textAlign: "right", fontSize: '22px', color: '#404040', margin: '50px 0 0 0 ' }} >
                <Button type="link" htmlType="submit" loading={loading} style={{ fontSize: '22px', color: '#404040' }}>OK</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={0} sm={0} md={0} lg={15} xl={15}>
            <img src={LoginPic} style={{ height: '100vh', width: '100%', float: 'right' }} alt=""></img>
          </Col>
        </Row>

      </Content>
    </Layout>

  );
}

export default ResetPassword;