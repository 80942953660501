import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Select, Space} from 'antd';
import {DeviceBranchDetails, DeviceOrgDetails} from './DeviceDetails';
import * as ApiProvider from '../../../Api/ApiProvider';
import {formatDate} from '../../../Utils/Utils'
import {isEmpty, isEqual} from 'lodash';
import {CountryStateCitySelector} from '../../../Components/CountryStateCitySelector';
import {columnFilterList, CommonTable} from '../../../Components/Table';
import SearchInput from '../../../Components/SearchInput';
import {DeviceStatus, DeviceStatusAlert, Status} from './ShowDeviceStatus'

const { Option } = Select;
const statusList = [
    {
        key: 'All',
        value: ''
    },
    {
        key: 'Online',
        value: 'online'
    },
    {
        key: 'Error',
        value: 'abnormal'
    },
    {
        key: 'Offline',
        value: 'offline'
    },
];


export const DeviceOrgTable = () => {
    return <DeviceTable showBrand={true} Detail={DeviceOrgDetails} />
}
export const DeviceBrandTable = () => {
    return <DeviceTable Detail={DeviceBranchDetails} />
}
const DeviceTable = ({ showBrand = false, Detail }) => {
    const [reload, setReload] = useState(false);
    const [selectRow, setSelectleRow] = useState();
    const [open, setOpen] = useState(false);
    const openDetails = (e) => {
        setOpen(true)
        setSelectleRow(e.row)
    }
    //#region  condition
    const [searchColumn, setSearchColumn] = useState(null);
    const [search, setSearch] = useState({
        branchIds: [],
        searchColumn: '',
        searchWord: ''
    });
    //#endregion
    const [showCountryStateCitySelector, setShowCountryStateCitySelector] = useState(false);
    const [showStatusSelector, setShowStatusSelector] = useState(false);
    const [showInput, setShowInput] = useState(false);

    useEffect(() => {
        setShowCountryStateCitySelector(isEqual(searchColumn, 'branchId'))
        setShowStatusSelector(isEqual(searchColumn, 'status'))
        setShowInput(searchColumn && !isEqual(searchColumn, 'branchId') && !isEqual(searchColumn, 'status'))
    }, [searchColumn])

    //#region Column
    const columns = [
        {
            display: false,
            title: 'Status',
            dataIndex: 'status',
            isFilter: true,

        },
        {
            title: 'Status',
            dataIndex: 'abnormal',
            width: '20px',
            render: (value, row, index) => {
                return (<DeviceStatus sn={row.sn} abnormal={value} connect={row.wifi === "connected"} />)
            }
        },
        {
            title: '',
            dataIndex: 'machineName',
            width: '10px',
            render: (value, row, index) => {
                return (<DeviceStatusAlert abnormalList={row.abnormal} />)
            },
        },
        {
            title: 'Device Name',
            dataIndex: 'machineName',
            render: (value, row, index) => {
                const text = isEmpty(value) ? 'No Name' : value;
                return (<><Button type="link" onClick={() => openDetails({ row })}>{text}</Button></>)
            },
            isFilter: true,
        },
        {
            display: showBrand,
            title: 'Brand',
            dataIndex: 'brandName',
            isFilter: showBrand,
        },
        {
            title: 'Branch',
            dataIndex: 'branchName',
            isFilter: true,
        },
        {
            display: showBrand,
            title: 'Country/Region',
            dataIndex: 'address',
            render: (Text) => {
                return Text?.country;
            },
        },
        {
            display: showBrand,
            title: 'State/Area',
            dataIndex: 'address',
            render: (Text) => {
                return Text?.state;
            },
        },
        {
            display: showBrand,
            title: 'City',
            dataIndex: 'address',
            render: (Text) => {
                return Text?.city;
            },
        },
        {
            title: 'SN',
            dataIndex: 'sn',
            isFilter: true,
        },
        {
            title: 'Device Admin',
            dataIndex: 'deviceAdmin',
            render: (Text) => <div>{Text.join(',')}</div>
        },
        {
            title: 'Register Date',
            dataIndex: 'registerDate',
            sorter: true,
            render: (Text) => <div>{formatDate(Text)}</div>,
        },
        {
            display: false,
            title: 'Location',
            dataIndex: 'branchId',
            isFilter: true,

        },
    ];
    //#region table 上方 Row 相關
    const handleCountryStateCitySelectorChange = (value, selectedOptions) => {
        const column = ['country', 'state', 'city'];
        ApiProvider.GetBranchIdByAddress({
            column: column[value.length - 1],
            word: value[value.length - 1]
        }).then(res => {
            setSearch({
                searchColumn: 'branchId',
                branchIds: res.data,
                searchWord: 'branchId',

            })
        })
    };

    function handleMenuClick(e) {
        setSearchColumn(e)
    }
    const handleChangeStatus = (value) => {
        const column = (value === 'online' || value === 'offline') ? 'wifi' : searchColumn;
        setSearch({ ...search, searchColumn: column, searchWord: value })

    }
    const handlePressEnter = (value) => {
        setSearch({ ...search, searchColumn: searchColumn, searchWord: value })
    }
    const tableTopRow = (
        <Row style={{ marginBottom: '30px' }}>
            <Col>
                <Space>
                    <div>
                        <Select dropdownMatchSelectWidth={false} onChange={handleMenuClick} options={columnFilterList(columns)} placeholder={`Please select ${columnFilterList(columns).map(i => i.label).join("/")}`}>
                        </Select>
                    </div>
                    <div style={{ display: showStatusSelector ? '' : 'none' }}>
                        <Select dropdownMatchSelectWidth={false} onChange={handleChangeStatus} placeholder="Please select Status !">
                            {statusList.map(item => (
                                <Option key={item.key} value={item.value}>{item.key}</Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{ display: showCountryStateCitySelector ? '' : 'none' }}>
                        <CountryStateCitySelector onChange={handleCountryStateCitySelectorChange} />
                    </div>
                    <div style={{ display: showInput ? '' : 'none' }}>
                        <SearchInput onPressEnter={handlePressEnter} />
                    </div>
                </Space>
            </Col>
        </Row>
    )
    //#endregion
    const tableProps = {
        rowKey: 'sn',
        ApiProviderUrl: ApiProvider.GetDeviceDataSource,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        setReload: setReload,
        setSelectSingleRow: (e) => console.log(e),
        setSelectedRows: (e) => console.log(e),
        setSelectedRowKeys: (e) => console.log(e),
    }
    return (
        <div>

            <CommonTable {...tableProps} />
            <Detail open={open} setOpen={setOpen} selectRow={selectRow} setReload={(isReload) => setReload(isReload)} />
            <div style={{ display: 'inline', fontSize: '12px' }}>Device Status :</div>
            <div style={{ display: 'inline' }}>{Status.OnlineIconImg}</div>
            <div style={{ display: 'inline', fontSize: '12px' }}>{Status.Online}</div>
            <div style={{ display: 'inline' }}>{Status.RedErrorIconImg}</div>
            <div style={{ display: 'inline', fontSize: '12px' }}>{Status.RedError}</div>
            <div style={{ display: 'inline' }}> {Status.OfflineIconImg}</div>
            <div style={{ display: 'inline', fontSize: '12px' }}>{Status.Offline}</div>
        </div>
    );

};
