import React, { useState } from 'react';
import { Checkbox, Layout, notification, Row, Col, Input, Button, Form } from 'antd';
import { ActionContext } from '../../../Context/ActionContext';
import { getCookie } from '../../../Utils/cookie'
import { split } from 'lodash'
import LoginPic from '../../../image/Login.jpg';
import { useTranslation, Trans } from "react-i18next";
import * as ApiProvider from '../../../Api/ApiProvider';
import RAPIBREW from '../../../image/RAPIBREW.png';
import * as CoffeeCloudConfig from '../../../CoffeeCloudConfig';
const { Content } = Layout;

const formlayout = {

  style: { padding: '60px 40px 10px 40px', float: 'center', height: '100vh', width: '100%' }
};

function getAccountCookie() {
  let accountInfo = getCookie('accountInfo');
  const accountInfos = split(accountInfo, '&');
  return {
    email: accountInfos[0]
  }
}

function Login(props) {
  const { signIn } = React.useContext(ActionContext);
  const [loading, setLoading] = useState(false);
  const account = getAccountCookie();
  const [form] = Form.useForm();
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleForgetPassword = () => {
    let fieldValue = form.getFieldValue();

    ApiProvider.ForgetPassword({ email: fieldValue.email }).then((result) => {
      notification.info({
        description:
          `We have sent the password reset information to ${fieldValue.email}. Please go to mail to set new password.`,
        placement: 'bottomLeft',
      });
    }).catch((error) => {
      const msg = (error.response) ? error.response.data.msg : 'ForgetPassword Fail!'
      notification.error({ message: msg, placement: 'bottomLeft' })
    })
  };
  const handleFinish = (values) => {
    setLoading(true);
    signIn({ account: values.email, password: values.password, remember: values.remember }).then((result) => {
      form.setFieldsValue({ 'email': values.email, 'password': '' })
      setLoading(false);
    })
  };

  return (
    <Layout style={{ background: '#F5F2EF' }}>
      <Content>
        <Row >
          <Col xs={24} sm={24} md={24} lg={9} xl={9} >
            <Form
              form={form}
              requiredMark={false}
              size='middle'
              onFinish={handleFinish}
              validateMessages={{ required: label => `${label} is required` }}
              initialValues={{
                email: account.email,
                password: account.password,
                remember: true,
              }}
              layout="vertical"
              {...formlayout}
            >
              <Form.Item>
                <div style={{ fontSize: '28px', color: '#453539', textAlign: 'center', margin: '0', fontWeight: 'inhenormalrit' }}>coffee cloud</div>
                <div style={{ fontSize: '49px', color: '#404040', textAlign: 'center', fontWeight: 'lighter' }}>WELCOME</div>
              </Form.Item>
              <Form.Item  label="Email"  name="email" rules={[{ required: true }]}>
                <Input style={{ fontSize: '16px' }} />
              </Form.Item>
              <Form.Item  label="Password"  name="password" rules={[{ required: true }]} >
                <Input.Password style={{ fontSize: '16px' }} />
              </Form.Item>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox style={{ fontSize: '16px' }}>Remember Me</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="link"
                  style={{ fontSize: '14px', color: '#404040' }}
                  onClick={handleForgetPassword}>Forget Password</Button>
                <div>

                </div>
              </Form.Item>
              <Form.Item style={{ textAlign: "right", fontSize: '22px', color: '#404040' }} >
                <Button style={{ display: "none" }} onClick={() => changeLanguage("zh-tw")}><Trans i18nKey='zh-tw' /></Button>
                <Button style={{ display: "none" }} onClick={() => changeLanguage("en")}><Trans i18nKey='en' /></Button>
                <Button type="link" htmlType="submit" loading={loading} style={{ fontSize: '22px', color: '#404040' }}>Log in</Button>
              </Form.Item>
              <Form.Item style={{ textAlign: "center", position: 'absolute', bottom: '50px', right: '0px' }}>
                <img style={{ width: 150 }} src={RAPIBREW} alt="" />
                <p style={{fontSize:6}}>{CoffeeCloudConfig.ReleaseVersion}</p>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={0} sm={0} md={0} lg={15} xl={15}>
            <img src={LoginPic} style={{ height: '100vh', width: '100%', float: 'right' }} alt=""></img>
          </Col>
        </Row>

      </Content>
    </Layout>

  );
}

export default Login;
