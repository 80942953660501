import React from 'react';

const Report = ({url}) => {
    return (
        <div>
            <iframe
                title="SaleReport"
                src={url}
                scrolling="yes" frameBorder="0"
                style={{ width: '100%', overflow: 'visible', height: '100vh' }}

            />
        </div>
    );
};

export default Report;

