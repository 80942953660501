import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Space, Button,Modal } from 'antd';
import * as Charts from './Charts';
import { formatDateTimeQ } from '../../../Utils/Utils';
import { DateRangePickers } from '../../../Components/DateRangePicker';
import * as ApiProvider from '../../../Api/ApiProvider';
import RecipeCard from '../../../Components/RecipeDetailCard/RecipeCard';

const filterList = [{ label: "By Brew times", value: "recipeId" }, { label: "By Creator", value: "createUser" }];

const RecipeReport = () => {
    const [open, setOpen] = useState(false);
    const [recipe, setRecipe] = useState(null);
    const [key, setKey] = useState(filterList[0].value);
    const [date, setDateTime] = useState({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)), //一個月的資料
        endDate: new Date(),
    });
    const [data, setData] = useState({
        data: [],
        total: 0
    });

    const [search, setSearch] = useState({
        top: 10,
        startDate: date.startDate,
        endDate: date.endDate,
        groupBy: filterList[0].value
    });

    useEffect(() => {
        setData({
            data: [],
            total: 0
        })
        ApiProvider.GetReportBrewTimes(search)
            .then(res => {
                setData(res.data)
            })

    }, [search])

    function handleChangeDate(dates, dateStrings) {
        setDateTime({
            startDate: dateStrings[0],
            endDate: dateStrings[1],
        })
    }

    function handleSearch(e) {
        setSearch({
            ...search,
            startDate: date.startDate,
            endDate: date.endDate,
            groupBy: key
        })
    }
    function onClickOpenRecipeContent(e) {
        setOpen(true)
        setRecipe({
            val: e[0].data.key,
        })
    }
    return (
        <>
            <Row style={{ marginBottom: '30px' }}>
                <Space>
                    <Select dropdownMatchSelectWidth={false} options={filterList} defaultValue="recipeId" onChange={(e) => setKey(e)} />
                    <DateRangePickers handleChangeDate={handleChangeDate} startDate={date.startDate} endDate={date.endDate} />
                    <Button type="primary" onClick={handleSearch}>Search</Button>
                </Space>
            </Row>
            <Row>
                <Col span={20}>

                </Col>
                <Col span={4}>
                    <p style={{ color: '#404040', fontSize: '12px', textAlign: 'right' }}>Update Time : {formatDateTimeQ(new Date())}</p>
                </Col>
                <Col span={6}>
                    <Charts.RecipeReportPieBox data={data} />
                </Col>
                <Col span={18}>
                    <p style={{ color: "#342813", fontSize: '22px', textAlign: 'center' }}>TOP 10 Recipe</p>
                    <Charts.SalesColumn data={data.data} xField="text" yField="value" onClick={onClickOpenRecipeContent} />
                </Col>

            </Row>
            <Modal
                width='600px'
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button key="close" type="primary" onClick={() => setOpen(false)}>Close</Button>
                ]}
            >
                <RecipeCard recipe={recipe}></RecipeCard>
            </Modal>
        </>

    );
};

export default RecipeReport;