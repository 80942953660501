import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Descriptions, Form, DatePicker } from 'antd';
import { isEmpty } from 'lodash';
import { DescriptionsInput, DescriptionsDetailsTitle, DescriptionsSelet, DescriptionsHalfInputNumber, DescriptionsInputNumber, DescriptionsRadioGroup } from '../../../Components/Descriptions';
import { getRatio } from '../../../Utils/Utils';
import moment from "moment";
import { schema } from './LabSetting';
import * as Message from '../../../Components/Message'
//主畫面
const LabDetails = ({ open, setOpen, selected, title, saveService, setReload }) => {
    const [showFail, setShowFail] = useState(false)
    const [loading, setloading] = useState(false);
    const [processCol, setProcessCol] = useState([6, 0, false])
    const [showCoffeeInfo, setShowCoffeeInfo] = useState(true)
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        //Edit
        if (!isEmpty(selected)) {
            console.log(selected)
            const includeProcess = IncludeProcess(selected.process);
            if (!includeProcess) {
                ChangeProcessList(schema.process.others);
            }
            //如果結果為 Fail                 
            setShowFail(selected.result === 'FAIL');
            const fieldsValue = Deserialization(selected, includeProcess);
            console.log(fieldsValue)
            form.setFieldsValue(fieldsValue)

        }
    }, [form, selected, open]);
    //判斷是否為處理法之一
    const IncludeProcess = (process) => {
        return schema.process.list.filter(i => i.value === process).length > 0
    }
    //修改處理法
    const ChangeProcessList = (type) => {
        const col = type === schema.process.others ? [2, 4, true] : [6, 0, false];
        //選到其他 要顯示輸入框
        // 參數說明: 
        //[0] : 處理法的col
        //[1]:其他輸入框的 Col
        //[2]:其他輸入框是否必填
        setProcessCol(col)
    };
    const Deserialization = (row, includeProcess) => {
        return {
            ...row,
            testDateTime: moment(row.testDateTime),
            process: includeProcess ? row.process : schema.process.others,
            ProcessOthers: includeProcess ? '' : row.process,
        }
    }
    const Serialization = (fieldValue) => {
        return {
            ...fieldValue,
            process: fieldValue.process === schema.process.others ? fieldValue.ProcessOthers : fieldValue.process, //處理法是其他時寫入文字
        }
    }

    const handleSave = async () => {
        try {
            await form.validateFields();

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return
        }
        const fieldValue = form.getFieldValue();
        console.log(Serialization(fieldValue))
        setloading(true);
        async function saveData() {
            saveService(Serialization(fieldValue))
                .then(res => {
                    Message.success(`${title} success`)
                    setOpen(false);
                    setloading(false);
                    setReload(true)
                    form.resetFields()
                })
                .catch(err => {
                    Message.error(`${title} error`)
                    setloading(false);
                });
        }
        saveData()
    };

    const handleCancel = () => {
        setOpen(false)
        setReload(true)
    };

    //咖啡粉 水量 咖啡產出
    const handleChangeGroundcoffee = (e) => {
        const fieldValue = form.getFieldsValue();
        console.log(fieldValue.groundCoffee)
        //粉水比
        form.setFieldsValue({ brewRatio: getRatio(fieldValue.groundCoffee, fieldValue.coldWater, 100) })
        //產出率
        form.setFieldsValue({ productivity: (fieldValue.coffeeServes / fieldValue.coldWater * 100).toFixed(2) })
    };
    //結果
    const handleChangeResult = (e) => {
        setShowFail(e.target.value === 'FAIL')
        //清空FAIL
        form.setFieldsValue({ failedResult: null })
        form.setFieldsValue({ category: null })
    };
    //修改處理法
    const handleChangeProcessList = (e) => {
        ChangeProcessList(e)
    };
    //修改模式
    const handleChangeMode = (e) => {
        setShowCoffeeInfo(e.target.value === '茶葉' || e.target.value === '咖啡')
        // //清空欄位
        // form.resetFields({ mode: e.target.value })
        // form.setFieldsValue({ mode: e.target.value })
    };

    const showCoffeeInfoClass = { style: { display: showCoffeeInfo ? '' : 'none' } };
    return (
        <Modal
            maskClosable={false}
            width={800}
            open={open}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>Close</Button>,
                <Button key="Save" type="primary" onClick={() => handleSave()} loading={loading}>Save</Button>,
            ]}
        >
            <Row>
                <Form
                    form={form}
                    requiredMark={true}
                    size='middle'
                    initialValues={{
                        testDateTime: moment(new Date()),
                        grindSize: 3,
                        roastLevel: 'Medium',
                        vacuumLevel: 'M',
                        stirStrength: 'M',
                        temperature: 28,
                        mode: '咖啡',
                    }}
                >
                    <Descriptions title={title} layout="vertical" column={12} >
                        {/* 測試時間 */}
                        <Descriptions.Item label={schema.testDateTime.label} span={6}>
                            <Form.Item name={schema.testDateTime.name} rules={[{ required: true, message: `Please input ${schema.testDateTime.label}` }]}>
                                <DatePicker showTime format="YYYY/MM/DD hh:mm" />
                            </Form.Item>
                        </Descriptions.Item>
                        {/* 模式 */}
                        <Descriptions.Item label={schema.mode.label} span={6}>
                            <DescriptionsRadioGroup schema={schema.mode} required onChange={handleChangeMode} />
                        </Descriptions.Item>
                        {/* FWversion */}
                        <Descriptions.Item label={schema.firmwareVersion.label} span={4}>
                            <DescriptionsInput name={schema.firmwareVersion.name} label={schema.firmwareVersion.label} required maxLength={128} />
                        </Descriptions.Item>
                        {/* 機台版本 */}
                        <Descriptions.Item label={schema.machineVeriosn.label} span={4}>
                            <DescriptionsInput name={schema.machineVeriosn.name} label={schema.machineVeriosn.label} required maxLength={128} />
                        </Descriptions.Item>
                        {/* sn */}
                        <Descriptions.Item label={schema.serialNumber.label} span={4}>
                            <DescriptionsInput name={schema.serialNumber.name} label={schema.serialNumber.label} required maxLength={128} />
                        </Descriptions.Item>
                        <Descriptions.Item span={12} {...showCoffeeInfoClass}>
                            <DescriptionsDetailsTitle title="COFFEE" />
                        </Descriptions.Item>
                        {/* 咖啡豆  */}
                        <Descriptions.Item label={schema.coffeeBean.label} span={6} {...showCoffeeInfoClass}>
                            <DescriptionsInput name={schema.coffeeBean.name} label={schema.coffeeBean.label} required={showCoffeeInfo} />
                        </Descriptions.Item>
                        {/* 處理法 */}
                        <Descriptions.Item label={schema.process.label} span={processCol[0]} {...showCoffeeInfoClass}>
                            <DescriptionsSelet schema={schema.process} required={showCoffeeInfo} onChange={handleChangeProcessList} />
                        </Descriptions.Item>
                        {/* 處理法的其他 */}
                        <Descriptions.Item span={processCol[1]} style={{ display: processCol[2] ? '' : 'none' }}>
                            <DescriptionsInput name={schema.process.others} label={schema.process.label} required={processCol[2]} />
                        </Descriptions.Item>
                        {/* 烘焙度 */}
                        <Descriptions.Item label={schema.roastLevel.label} span={12}{...showCoffeeInfoClass}>
                            <DescriptionsRadioGroup schema={schema.roastLevel} required={showCoffeeInfo}></DescriptionsRadioGroup>
                        </Descriptions.Item>
                        {/* 研磨度 */}
                        <Descriptions.Item label={schema.grindSize.label} span={12}{...showCoffeeInfoClass}>
                            <DescriptionsRadioGroup schema={schema.grindSize} required={showCoffeeInfo}></DescriptionsRadioGroup>
                        </Descriptions.Item>
                        <Descriptions.Item span={12}>
                            <DescriptionsDetailsTitle title="RECIPE" />
                        </Descriptions.Item>
                        {/* 過濾XX */}
                        <Descriptions.Item label={schema.filterType.label} span={12}>
                            <DescriptionsRadioGroup schema={schema.filterType} required />
                        </Descriptions.Item>
                        {/* 咖啡粉 */}
                        <Descriptions.Item label={schema.groundCoffee.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsInputNumber schema={schema.groundCoffee} text="g." onChange={handleChangeGroundcoffee} required={showCoffeeInfo} />
                        </Descriptions.Item>
                        {/* 水量 */}
                        <Descriptions.Item label={schema.coldwater.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsInputNumber schema={schema.coldwater} text="ml" onChange={handleChangeGroundcoffee} required={showCoffeeInfo} />
                        </Descriptions.Item>
                        {/* 粉水比 */}
                        <Descriptions.Item label={schema.brewRatio.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsInput name={schema.brewRatio.name} label={schema.brewRatio.label} readOnly />
                        </Descriptions.Item>
                        {/* 水溫 */}
                        <Descriptions.Item label={schema.temperature.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsInputNumber schema={schema.temperature} max={100} text="°C" required={showCoffeeInfo} />
                        </Descriptions.Item>
                        {/* 攪拌強度 */}
                        <Descriptions.Item label={schema.stirStrength.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsRadioGroup schema={schema.stirStrength} required={showCoffeeInfo}></DescriptionsRadioGroup>
                        </Descriptions.Item>
                        {/* 攪拌時間 */}
                        <Descriptions.Item label={schema.stirPeriod.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsInputNumber schema={schema.stirPeriod} max={120} text="秒" required={showCoffeeInfo} />
                        </Descriptions.Item>
                        {/* 浸泡時間 */}
                        <Descriptions.Item label={schema.steepingPeriod.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsInputNumber schema={schema.steepingPeriod} max={360} text="秒" required={showCoffeeInfo} />
                        </Descriptions.Item>
                        {/* 真空強度 */}
                        <Descriptions.Item label={schema.vacuumLevel.label} span={3}{...showCoffeeInfoClass}>
                            <DescriptionsRadioGroup schema={schema.vacuumLevel} required={showCoffeeInfo} />
                        </Descriptions.Item>
                        <Descriptions.Item span={12}>
                            <DescriptionsDetailsTitle title="RESULT" />
                        </Descriptions.Item>
                        {/* 咖啡產出 */}
                        <Descriptions.Item label={schema.coffeeServes.label} span={4}{...showCoffeeInfoClass}>
                            <DescriptionsInputNumber schema={schema.coffeeServes} text="g." onChange={handleChangeGroundcoffee} required={showCoffeeInfo} />
                        </Descriptions.Item>
                        {/* 產出率 */}
                        <Descriptions.Item label={schema.productivity.label} span={4}{...showCoffeeInfoClass}>
                            <DescriptionsInputNumber schema={schema.productivity} text="%" readOnly />
                        </Descriptions.Item>
                        {/* TDS */}
                        <Descriptions.Item label={schema.TDS.label} span={4}{...showCoffeeInfoClass}>
                            <DescriptionsInput name={schema.TDS.name} label={schema.TDS.label} />
                        </Descriptions.Item>
                        {/* 真空抽時間 */}
                        <Descriptions.Item label={schema.vacuumBrewingTime.label} span={4}>
                            <Row>
                                <DescriptionsHalfInputNumber schema={schema.vacuumBrewingTimeMin} text="分" />
                                <DescriptionsHalfInputNumber schema={schema.vacuumBrewingTime} text="秒" />
                            </Row>
                        </Descriptions.Item>
                        {/* 真空馬達停止時間 */}
                        <Descriptions.Item label={schema.vacuumPumpStopTime.label} span={4}>
                            <Row>
                                <DescriptionsHalfInputNumber schema={schema.vacuumPumpStopTimeMin} text="分" required />
                                <DescriptionsHalfInputNumber schema={schema.vacuumPumpStopTime} text="秒" required />
                            </Row>
                        </Descriptions.Item>
                        {/* 升降平台上時間 */}
                        <Descriptions.Item label={schema.machineEndTime.label} span={4}>
                            <Row>
                                <DescriptionsHalfInputNumber schema={schema.machineEndTimeMin} text="分" required />
                                <DescriptionsHalfInputNumber schema={schema.machineEndTime} text="秒" required />
                            </Row>
                        </Descriptions.Item>
                        {/* 結果 */}
                        <Descriptions.Item label={schema.result.label} span={12}>
                            <DescriptionsRadioGroup schema={schema.result} onChange={handleChangeResult} required />
                        </Descriptions.Item>
                        {/* 問題分類 */}
                        <Descriptions.Item label={schema.category.label} span={12} style={{ display: showFail ? '' : 'none' }}>
                            <DescriptionsSelet schema={schema.category} required={showFail} />
                        </Descriptions.Item>
                        {/* 失敗原因 */}
                        <Descriptions.Item label={schema.failedResult.label} span={12} style={{ display: showFail ? '' : 'none' }}>
                            <DescriptionsSelet schema={schema.failedResult} required={showFail} />
                        </Descriptions.Item>
                        {/* Remark */}
                        <Descriptions.Item label={schema.remark.label} span={12}>
                            <DescriptionsInput name={schema.remark.name} label={schema.remark.label} />
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
            </Row>
        </Modal >
    )
}

export default LabDetails;