
//當數字小於10以下，補0 ex : 9 toBe 09
function addz(num) {
    return num < 10 ? '0' + num : num;
}

//日期回傳文字格式 : yyyy-mm-dd
export const formatDate = (date) => {
    if (!date) {
        return '';
    }
    var d = new Date(date),
        month = addz(d.getMonth() + 1),
        day = addz(d.getDate()),
        year = addz(d.getFullYear());

    return [year, month, day].join('-');
}
//日期回傳文字格式 : yyyy-mm-dd hh:mm
export const formatDateTime = (date) => {
    if (!date) {
        return '';
    }
    var d = new Date(date),
        Hours = addz(d.getHours()),
        Minutes = addz(d.getMinutes());

    return `${formatDate(date)} ${[Hours, Minutes].join(':')}`;
}
//日期回傳文字格式 : yyyy-mm-dd hh:mm:ss
export const formatDateTimeHasSec = (date) => {
    if (!date) {
        return '';
    }
    return `${formatDate(date)} ${formatTimeHasSec(date)}`;
}

//日期回傳文字格式 : hh:mm:ss
export const formatTimeHasSec = (date) => {
    if (!date) {
        return '';
    }
    var d = new Date(date),
        Hours = addz(d.getHours()),
        Minutes = addz(d.getMinutes()),
        Seconds = addz(d.getSeconds());

    return `${[Hours, Minutes, Seconds].join(':')}`;
}
//日期回傳文字格式 : hh:mm
export const formatTime = (date) => {
    if (!date) {
        return '';
    }
    var d = new Date(date),
        Hours = addz(d.getHours()),
        Minutes = addz(d.getMinutes());

    return `${[Hours, Minutes].join(':')}`;
}
//傳入日期回傳與今天相差的天數 
export const dateDiff = (date1, date2) => {
    if (!date1) {
        return '';
    }
    const oDate1 = new Date(date1),
        oDate2 = new Date(date2);
    return parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數

}
//傳入日期時間回傳與今天相差的豪秒數 
export const dateTimeDiff = (date1, date2) => {
    if (!date1) {
        return '';
    }
    const oDate1 = new Date(date1),
        oDate2 = new Date(date2);
    return parseInt(Math.abs(oDate1 - oDate2)); // 把相差的毫秒數轉換為天數

}
export const getRatio = (a, b, tolerance) => {
    /*where a is the first number, b is the second number, and tolerance is a percentage 
    of allowable error expressed as a decimal. 753,4466,.08 = 1:6, 753,4466,.05 = 14:83,*/
    let bg = a, sm = b;
    if (a < b) { bg = b; sm = a; }
    for (var i = 1; i < 1000000; i++) {
        var d = sm / i;
        var res = bg / d;
        var howClose = Math.abs(res - res.toFixed(0));
        if (howClose < tolerance) {
            if (a > b) {
                return res.toFixed(0) + ':' + i;
            } else {
                return i + ':' + res.toFixed(0);
            }
        }
    }
}

export const progressive = (min, max, plus) => {
    const array = []
    for (var i = min; i <= max - 1; i++) {
        array.push(i);
        array.push(i + plus);
    }
    array.push(max);
    return array
}

export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

//日期回傳文字格式(整點) : yyyy-mm-dd hh:00
export const formatDateTimeQ = (date) => {
    if (!date) {
        return '';
    }
    var d = new Date(date),
        Hours = addz(d.getHours());

    return `${formatDate(date)} ${[Hours, '00'].join(':')}`;
}

