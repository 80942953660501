import React from 'react';
import { Tag, Descriptions } from 'antd';

const RecipeUsedStore = ({ assignbranch }) => {
    return (
        <>
            <Descriptions title="Used Store">
                <Descriptions.Item >
                    {assignbranch?.map(item =>
                        <Tag key={item.value} closable={false} onClose={false} >{item.value}</Tag>
                    )}
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default RecipeUsedStore;