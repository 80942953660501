// return the user data from the session storage
export const getUser = () => {
  return localStorage.getItem('user')
  // const userStr = localStorage.getItem('user');
  // console.log(JSON.parse(userStr))
  // if (userStr) return JSON.parse(userStr);
  // else return null;
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (data) => {
  localStorage.setItem('token', data.jwt);
  localStorage.setItem('user', data.account);
  localStorage.setItem('roles', data.role);
  localStorage.setItem('name', data.name);
  localStorage.setItem('brandId', data.brandId);
}
export const getRoles = () => {
  return localStorage.getItem('roles')
}
export const getUserName = () => {
  return localStorage.getItem('name')
}

export const getBrandId = () => {
  return localStorage.getItem('brandId')
}