import React from 'react';
import { Button, Popover } from 'antd';
import InfoImg from '../image/ic_information.svg';

const PopoverInfo = ({ infoContent }) => {
    return (
        <Popover
            placement="right"
            content={infoContent}>
            <Button type="link"> <img src={InfoImg} alt="Info" /></Button>
        </Popover>
    )
}
export default PopoverInfo;