import React, { useState } from 'react';
import { Space, Button, Row, DatePicker, notification } from 'antd';
import moment from 'moment';
import * as ApiProvider from '../../../Api/ApiProvider';
import { formatDate, formatTimeHasSec, addDays } from '../../../Utils/Utils'
import BrandandBranchDropdownList from '../../../Components/BrandandBranchDropdownList';
import { split } from 'lodash';
import { CommonTable } from '../../../Components/Table';
import { BranchSelect } from '../../../Components/BranchSelect';
import { ServicSiteSelect } from '../../../Components/ServiceSiteDropdownList';
const { RangePicker } = DatePicker;
//原廠
export const DeviceOrgReport = (prop) => <DeviceReport {...prop} showBrand={true} />
//品牌
export const DeviceBranchReport = (prop) => <DeviceReport {...prop} showBrand={false} />
const DeviceReport = (prop) => {
    const [reload, setReload] = useState(false);
    //#region  data condition
    const [preDateTime, setPreDateTime] = useState(addDays(new Date(), -7));
    const [postDateTime, setPostDateTime] = useState(new Date());
    const [brandId, setBrandId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [serviceSite, setServiceSite] = useState('');

    //#endregion

    //#region  condition
    const [search, setSearch] = useState({
        brandId: brandId,
        branchId: branchId,
        recipeIndex: prop.recipeIndex,
        preDateTime: preDateTime,
        postDateTime: postDateTime,
    });
    //#endregion

    //#region Column
    const columns = [
        {
            title: 'Device Name',
            dataIndex: 'deviceId',
        },
        {
            title: 'Brand',
            dataIndex: 'brandName',
            display: prop.showBrand
        },
        {
            title: 'Branch',
            dataIndex: 'branchName',
        },
        {
            title: 'Clean Date',
            dataIndex: 'startTime',
            sorter: true,
            render: (Text) => <div>{formatDate(split(Text, '_')[0])}</div>,
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            sorter: true,
            render: (Text) => <div>{formatTimeHasSec(split(Text, '_')[0])}</div>,
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            sorter: true,
            render: (Text) => <div>{formatTimeHasSec(split(Text, '_')[0])}</div>,
        },
        {
            title: 'Device Admin',
            dataIndex: 'deviceAdmin',
            render: (Text) => <div>{Text.join(',')}</div>
        },
    ];
    //#endregion

    //#region table 上方 Row 相關
    function handleSearch(e) {
        if (!preDateTime || !postDateTime) {
            notification.error({
                message: 'Clean Date is required',
            })
            return;
        }
        setSearch({
            brandId: brandId,
            branchId: branchId,
            vendorId: serviceSite,
            recipeIndex: prop.recipeIndex,
            preDateTime: preDateTime,
            postDateTime: postDateTime,
        })
    }

    //日期選擇邏輯 : 不超過今天
    function disabledDate(current) {
        return current > moment().endOf('day');
    }
    function handleChangeDate(dates, dateStrings) {
        setPreDateTime(emptyToNull(dateStrings[0]))
        setPostDateTime(emptyToNull(`${dateStrings[1]}:59`))
    }

    function emptyToNull(value) {
        return (value) ? new Date(value) : null;
    }

    const tableTopRow = (
        <>
            <Row justify="space-between" style={{ marginBottom: '30px' }}>
                <Space>
                    {prop.showBrand ?
                        (prop.isAgent ? <ServicSiteSelect label="Service Site" onChange={(e) => setServiceSite(e.value)} />
                            : <BrandandBranchDropdownList first={d => setBrandId(d)} second={d => setBranchId(d)} />)
                        : <BranchSelect label="Branch" />}
                    <RangePicker
                        defaultValue={[moment(preDateTime), moment(postDateTime)]}
                        placeholder={["Select start date", "Select end date"]}
                        disabledDate={disabledDate}
                        showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [moment('00:00:00', 'HH:mm'), moment('23:59:59', 'HH:mm')],
                        }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={handleChangeDate}
                    />

                    <Button type="primary" onClick={(e) => handleSearch(e)}>Search</Button>

                </Space>
            </Row>
        </>
    )
    //#endregion
    const tableProps = {
        rowKey: 'rowKey',
        ApiProviderUrl: ApiProvider.GetCleanRecordList,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        setReload: setReload,
        setSelectleRow: (e) => { },
    }
    return (
        <div>
            <CommonTable {...tableProps} />
        </div>
    );
};

