import { getBrandId } from './Utils/session';

const version = "20221223.01";

export const ServerSetting = {
    baseURL: process.env.REACT_APP_BASEURL || '/apis/',
    apiKey: process.env.REACT_APP_API_KEY || 'df2499208b4d0876a15aac81b3a81a96f1c772d40b00c196a84360b635036f82',
}

export const FileUrl = {
    importBranchTemplate: "https://rapibrewstorage.blob.core.windows.net/doctemplate/branchImport_template.xlsx",
    importAccountBrandTemplate: "https://rapibrewstorage.blob.core.windows.net/doctemplate/accountImport_template.xlsx",
    importAccountOrgTemplate: "https://rapibrewstorage.blob.core.windows.net/doctemplate/accountImport_template.xlsx",
}

//Grid 分頁設定
export const PaginationSetting = {
    showSizeChanger: false,
    current: 1,
    pageSize: 10,
    sortField: '',
    sortOrder: ''
}


export const PowerBIReportUrl = {
    recipeOrgReport: "https://app.powerbi.com/view?r=eyJrIjoiYzEwMTdlZjQtZTg5YS00OTc0LTk0ZGYtZTlmZTlmZGZhOTMwIiwidCI6ImRlMDc5NWUwLWQ3YzAtNGVlYi1iOWJiLWJjOTRkODk4MGQzYiIsImMiOjEwfQ%3D%3D",
    salesReport: `https://app.powerbi.com/reportEmbed?reportId=25b20f01-96bf-435f-bc83-a204e031ce4b&autoAuth=true&ctid=de0795e0-d7c0-4eeb-b9bb-bc94d8980d3b&config=eyJrIjoiMjBjZWEzMTQtOWFkMC00YjdhLThjODEtNmEwZWYwNThjOWQzIiwidCI6ImRlMDc5NWUwLWQ3YzAtNGVlYi1iOWJiLWJjOTRkODk4MGQzYiIsImMiOjEwfQ%3D%3D&filter=device/brandId eq '${getBrandId()}'`,
    recipeReport: `https://app.powerbi.com/reportEmbed?reportId=e53e8127-439b-46e4-9019-a19ff55b29b5&autoAuth=true&ctid=de0795e0-d7c0-4eeb-b9bb-bc94d8980d3b&config=eyJrIjoiMTAwMTdjYjctMGY2ZS00YjYyLTlkMWItY2MyYWVmM2E3MGU0IiwidCI6ImRlMDc5NWUwLWQ3YzAtNGVlYi1iOWJiLWJjOTRkODk4MGQzYiIsImMiOjEwfQ%3D%3D&filter=recipe/createdBrandId eq '${getBrandId()}'`,
    deviceReport: `https://app.powerbi.com/view?r=eyJrIjoiYTk1MDYwZjEtNWRkNS00NWQxLTk4OGItMGUzMWZkNmQwNDQzIiwidCI6ImRlMDc5NWUwLWQ3YzAtNGVlYi1iOWJiLWJjOTRkODk4MGQzYiIsImMiOjEwfQ%3D%3D`,
}

//多國語系相關設定
export const Localization = {
    showSizeChanger: false,
    current: 1,
    pageSize: 10,
    sortField: '',
    sortOrder: ''
}

//Notification 推播類別設定
export const NotificationType = [
    "新功能通知",
    "產品與行銷",
    "異常通知",
    "系統維護",
    "系統內部使用",
    "其他通知"
];

//Notification 推播語言分頁設定
export const NotificationLanguageTab = [
    {
        key: 'tw',
        tab: '繁體中文',
    },
    {
        key: 'cn',
        tab: '簡體中文',
    },
    {
        key: 'en',
        tab: '英文',
    },
    {
        key: 'jp',
        tab: '日文',
    },
    {
        key: 'kr',
        tab: '韓文',
    },

];

//角色類別
export const AccountType = {
    brand: "brand",
    agent: "agent",
    originalSupplier: "originalSupplier",
    consumer: "consumer"
}

//Account Type [原廠]角色類別設定
export const OriginalAccountTypeList = [
    {
        value: 'Coffee machine brand',
        text: 'Coffee machine brand',
        type: AccountType.brand
    },
    {
        value: 'Coffee shop brand',
        text: 'Coffee shop brand',
        type: AccountType.brand
    },
    {
        value: 'Agent',
        text: 'Agent',
        type: AccountType.agent
    },
    {
        value: 'Maintenance agent',
        text: 'Maintenance agent',
        type: AccountType.brand
    },
    {
        value: 'Original supplier',
        text: 'Original supplier',
        type: AccountType.originalSupplier
    },
    {
        value: 'Consumer',
        text: 'Consumer',
        type: AccountType.consumer
    }
];

const getVersion = () => {
    return (process.env.NODE_ENV !== "development")?
        version:
        `${version}.${process.env.NODE_ENV}`;
}

export const type = ["recipeId", "device", "branch", "createUser"];
export const recipeIndex = ["A", "B", "C"];

export const ReleaseVersion = `V${getVersion()}`;
