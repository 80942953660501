import React, { useState } from 'react';
import { Space, Button, Row, DatePicker, Input } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import { formatDateTimeHasSec } from '../../../Utils/Utils';
import { exportExcel } from '../../../Utils/Export';
import moment from 'moment';
import { CommonTable } from '../../../Components/Table';

const { RangePicker } = DatePicker;

const AbnormalDataTable = () => {
    const dateFormat = 'YYYY-MM-DD';

    //#region condition
    const [sn, setSn] = useState('');
    const [prevDate, setPreDate] = useState(moment(new Date(), dateFormat));
    const [postDate, setPostDate] = useState(moment(new Date(), dateFormat));
    const [response, setResponse] = useState([]);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState({
        filterType: 'ABNORMAL',
        sn: '',
        preDate: '',
        postDate: '',
    });

    //#region Column
    const columns = [
        {
            title: 'sn',
            dataIndex: 'sn',
            textWrap: 'word-break',
        },
        {
            title: 'timestamp',
            dataIndex: 'createdAt',
            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
            render: (text) => {
                return (<div>{ formatDateTimeHasSec(text) }</div>);
            },
        },
        {
            title: 'record',
            dataIndex: 'abnormal',
        },
    ];
    //#endregion

    //日期選擇邏輯 : 不超過今天
    function disabledDate(current) {
        return current > moment().endOf('day');
    }

    function handleChangeDate(dates, dateStrings) {
        setPreDate(dateStrings[0]);
        setPostDate(dateStrings[1]);
    }

    function handleSearch(e) {
        let start_date = moment(prevDate);
        let end_date = (prevDate === postDate)?moment(postDate).add(1, 'day'):moment(postDate);

        console.debug(`preDate: ${start_date.toISOString()}, postDate: ${end_date.toISOString()}`);

        setResponse([]);
        setSearch({
            filterType: 'ABNORMAL',
            sn: sn,
            preDate: String(start_date.toISOString()), // startDate,
            postDate: String(end_date.toISOString()), // endDate,
        });
    }
    function handleExport(e) {
        const exportdobdy = response;
        exportExcel("ExportAbnormalData", exportdobdy)
    }
    //#region table 上方 Row 相關
    const tableTopRow = (
        <>
            <Row style={{ marginBottom: '30px' }}>
                <Space>
                    <Input bordered={false} placeholder='input SN' onChange={(e) => setSn(e.target.value)} />
                    <RangePicker
                        placeholder={["Select start date", "Select end date"]}
                        bordered={false}
                        disabledDate={disabledDate}
                        format={dateFormat}
                        onChange={handleChangeDate}
                        defaultValue={[prevDate, postDate]}
                    />
                    <Button type="primary" onClick={handleSearch}>Search</Button>
                    <Button type="primary" disabled={(response === null || response.length === 0)} onClick={handleExport}>Download</Button>
                </Space>
            </Row>
        </>
    )
    //#endregion
    const tableProps = {
        rowKey: 'createdAt',
        ApiProviderUrl: ApiProvider.GetAbnormalLog,
        search: search,
        tableTopRow: tableTopRow,
        columns: columns,
        reload: reload,
        setReload: setReload,
        setSelectedRows: {},
        setResponse: (e) => setResponse(e)
    }
    return <CommonTable {...tableProps} />
};

export default AbnormalDataTable;
