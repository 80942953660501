import React from 'react';
import { Column, Pie } from '@ant-design/charts';
import { Row, Col, Collapse, Divider } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { type } from '../../../CoffeeCloudConfig';
const { Panel } = Collapse;

export const ChartTitle = ({ text }) => {
    return <p style={{ fontSize: '22px', color: '#342813', letterSpacing: '0px', textAlign: 'center' }}>{text}</p>
}

//柱狀圖
export const SalesColumn = ({ data, xField, yField, onClick }) => {
    var config = {
        data: data,
        xField: "text",
        yField: "value",
        color: '#404040',
        slider: {

        },

    };

    return <>
        <ChartTitle text="　" />
        <Column {...config}
            onEvent={(chart, event) => {
                if (event.type === 'click') {
                    onClick(chart.chart.getTooltipItems(event))
                }
            }}
        />
    </>;
};

//總和的顯示
export const SalesSignboard = ({ count, unit, title, bigTitle }) => {
    const bigTitleStyle = {
        color: '#0A496A',
        fontSize: '22px',
        fontWeight: 'bold'
    }
    const titleStyle = {
        color: '#848484',
        fontSize: '14px',
    }
    const content = {
        fontSize: '36px',
        color: '#404040',
        display: 'inline'
    }
    const contentUnit = {
        fontSize: '18px',
        color: '#404040',
        display: 'inline'
    }
    return <>
        <div style={{ textAlign: 'left', margin: '0 30px' }}>
            <div style={bigTitleStyle}>{bigTitle}</div>
            <div style={titleStyle}>{title}</div>
            <div style={content}>{count}</div>
            <div style={contentUnit}> {unit}</div>
        </div>
    </>
};

//[Report] Device 展開的 Recipe
export const SalesAnalysisCollapse = ({ data, activeKey = [], entrykey }) => {
    function callback(key) {
        console.log(key);
    }
    const defaultOpenActiveKey =  activeKey.length > 0 ? { activeKey: activeKey } : {} ;
    return (
        <div style={{ margin: '60px 0' }}>
            <ChartTitle text="Sale Analysis" />
            <Collapse {...defaultOpenActiveKey} ghost={true} onChange={callback} bordered={false} expandIconPosition='right' expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}>
                <Divider style={{ borderColor: '#BFBFBF' }} />
                {data.map(i => {
                    var ret = reMappingKey(i, entrykey);
                    return (
                        <>
                            <Panel header={
                                <Row style={{width:'100%'}}>
                                    <Col span={10} style={{ color: '#404040', fontSize: '16px', fontWeight: 'bold', margin: '0' }}>{ret.name}</Col>
                                    <Col span={12} style={{ color: '#404040', fontSize: '22px', fontWeight: 'bold', margin: '0', textAlign: 'right' }}>{ret.cups}</Col>
                                    <Col span={2} style={{ color: '#404040', fontSize: '16px', margin: '0' }}>　Cups</Col>
                                </Row>
                            } key={ret.id}>
                                <SalesRecipeTable data={ret.group} entrykey={entrykey}/>
                            </Panel>
                            <Divider style={{ borderColor: '#BFBFBF', margin: '0' }} />
                        </>
                    )
                })}


            </Collapse>
        </div>
    )
}

//[Report] Device 展開的 Recipe
export const SalesRecipeTable = ({ data, entrykey }) => {
    return (
        <div style={{ backgroundColor: '#F2F2F2', padding: '20px' }}>
            {data?.map(i => {
                var ret = reMappingSubGroupKey(i, entrykey);
                return (
                    <Row style={{ marginBottom: '10px', color: '#0A496A', fontSize: '16px' }}>
                        <Col span={22}>{ret.name}</Col>
                        <Col span={2}>{ret.cups} Cups</Col>
                    </Row>
                )
            })}
        </div>
    )
};

//Recipe 圓餅圖整個框框
export const RecipePie = ({ data }) => {
    var config = {
        appendPadding: 5,
        data: data,
        angleField: 'value',
        colorField: 'text',
        radius: 0.9,
        legend: {
            layout: 'vertical',
            position: 'bottom'
        },
        label: false,
        theme: {
            colors10: [
                '#6C1F1F',
                '#0A496A',
                '#C1AA92',
                '#8A4B4B',
                '#894c4ccc',
                '#3b6d87',
                '#e2d6ca',
                '#6b90a4',
                '#e6ccb2',
                '#d4bebd',
            ]
        },
        interactions: [{ type: 'element-active' }],
    };
    return <Pie {...config} />;
}
export const RecipeReportPieBox = ({ data }) => {
    return (
        <div style={{ border: '1px solid #C1AA92', borderRadius: '8px', margin: '0 30px 10px 10px', padding: '10px', height: '70vh' }}>
            <div>
                <SalesSignboard count={data.total} unit="round" title="Total Brew Times" />
            </div>
            <div>
                <RecipePie data={data.data}></RecipePie>
            </div>
        </div>
    )
};

function reMappingKey(data, entrykey) {
    var ret = {};

    if (entrykey === type[0]) {
        ret.name = data.recipeName;
        ret.cups = data.recipeCups;
        ret.id = data.recipeId;
        ret.group = data.branch;
    } else if (entrykey === type[1] || entrykey === type[2]) {
        ret.name = data.deviceName;
        ret.cups = data.deviceCups;
        ret.id = data.deviceId;
        ret.group = data.recipe;
    }

    return ret;
}

function reMappingSubGroupKey(data, entrykey) {
    var ret = {};

    if (entrykey === type[0]) {
        ret.name = data.branchName;
        ret.cups = data.branchNameCups;
    } else if (entrykey === type[1] || entrykey === type[2]) {
        ret.name = data.recipeName;
        ret.cups = data.recipeCups;
    }

    return ret;
}
