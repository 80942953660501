
export const Routes = {
    // pages
    Login:{ path: "/Login" },
    Forgetpassword:{ path: "/forgetpassword" },
    Success:{ path: "/success" },
    Presentation: { path: "/" },
    Recipe: { path: "/Recipe" },
    Device: { path: "/Device" },
    Customer: { path: "/Customer" },
    Branch: { path: "/Branch" },
    Account: { path: "/Account" },
    Report: { path: "/Report" },
    ServiceRequest: { path: "/ServiceRequest" },
    Lab: { path: "/Lab" },
    Setting: { path: "/Setting" },
    NotFound: { path: "/404" },
    ServerBusy: { path: "/502" },
    System: { path: "/System" },
};

