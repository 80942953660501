
import * as ApiProvider from '../Api/ApiProvider';
import { formatDate } from './Utils';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

//DownLoad exportExcel
export const exportExcel = (fileName, exportdobdy) => {
    const exportFileName = `${fileName}_${formatDate(new Date())}.xls`;
    ApiProvider.ExportExcel({ fileName: exportFileName, objList: exportdobdy })
        .then(res => {
            const FileDownload = require('js-file-download');
            //FileDownload(res.data, decodeURIComponent(res.headers['content-disposition'].split("=")[1])) TODO:目前抓不到 res.headers['content-disposition'] 找不到原因，FileName 先寫死QQ
            FileDownload(res.data, exportFileName);
        })
}

//DownLoad exportExcel (暫時還沒想到怎麼分頁)
export const exportPDF = (exportPages, fileName,callback) => {
    html2canvas(exportPages)
        .then((canvas) => {
            var contentWidth = canvas.width;
            var contentHeight = canvas.height;

            //一頁pdf顯示html頁面生成的canvas高度;
            var pageHeight = contentWidth / 592.28 * 841.89;
            //未生成pdf的html頁面高度
            var leftHeight = contentHeight;
            //頁面偏移
            var position = 0;
            //a4紙的尺寸[595.28,841.89]，html頁面生成的canvas在pdf中圖片的寬高
            var imgWidth = 595.28;
            var imgHeight = 592.28 / contentWidth * contentHeight;

            var pageData = canvas.toDataURL('image/jpeg', 1.0);

            var pdf = new jsPDF('', 'pt', 'a4');

            //有兩個高度需要區分，一個是html頁面的實際高度，和生成pdf的頁面高度(841.89)
            //當內容未超過pdf一頁顯示的範圍，無需分頁
            if (leftHeight < pageHeight) {
                pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgWidth);
            } else {
                while (leftHeight > 0) {
                    pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    //避免新增空白頁
                    if (leftHeight > 0) {
                        pdf.addPage();
                    }
                }
            }

            pdf.save(`${fileName}.pdf`);
            callback()
        })
}
