import React, { useState } from 'react';
import { Table, Space, Button, Row, DatePicker, Input } from 'antd';
import * as ApiProvider from '../../../Api/ApiProvider';
import useService from "../../../CustomerHook/useService";
import { formatDateTimeHasSec } from '../../../Utils/Utils';
import { exportExcel } from '../../../Utils/Export';
import moment from 'moment';
import * as Message from '../../../Components/Message';
const { RangePicker } = DatePicker;

const LogDataTable = () => {
    //#region  condition
    const [sn, setsn] = useState('');
    const [sdate, setPreDateTime] = useState('');
    const [edate, setPostDateTime] = useState('');
    const [pageSize, setPageSize] = useState(200);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState({
        edate: edate,
        sdate: sdate,
        sn: sn
    });

    //#endregion
    const { loading: girdLoading = false, response = {} } = useService(ApiProvider.GetLogData, {
        edate: search.edate,
        sdate: search.sdate,
        sn: search.sn
    }, reload, setReload);

    //#region Column
    const columns = [
        {
            title: 'deviceId',
            dataIndex: 'deviceId',
            fixed: 'left',
            width: 100,
            textWrap: 'word-break',
        },
        {
            title: 'timestamp',
            dataIndex: 'timestamp',
            width: 150,
            fixed: 'left',
            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
            render: (text) => {
                return (<div>{formatDateTimeHasSec(text)}</div>)
            }
        },
        {
            title: 'header0',
            dataIndex: 'header0',
            width: 100,
        },
        {
            title: 'header1',
            dataIndex: 'header1',
            width: 100,
        },
        {
            title: 'data_length',
            dataIndex: 'data_length',
            width: 100,
        },
        {
            title: 'command',
            dataIndex: 'command',
            width: 100,
        },
        {
            title: 'machine_status',
            dataIndex: 'machine_status',
            width: 150,
        },

        {
            title: 'recipe_index',
            dataIndex: 'recipe_index',
            width: 100,
        },

        {
            title: 'led_1',
            dataIndex: 'led_1',
            width: 100,
        },
        {
            title: 'led_2',
            dataIndex: 'led_2',
            width: 100,
        },
        {
            title: 'ui_index',
            dataIndex: 'ui_index',
            width: 100,
        },
        {
            title: 'process_step',
            dataIndex: 'process_step',
            width: 150,

        },
        {
            title: 'remaining_time_H',
            dataIndex: 'remaining_time_H',
            width: 150,
        },
        {
            title: 'remaining_time_L',
            dataIndex: 'remaining_time_L',
            width: 150,
        },
        {
            title: 'stir_strength',
            dataIndex: 'stir_strength',
            width: 150,
        },
        {
            title: 'stir_period_hi',
            dataIndex: 'stir_period_hi',
            width: 150,
        },
        {
            title: 'stir_period_low',
            dataIndex: 'stir_period_low',
            width: 150,
        },

        {
            title: 'steeping_period_hi',
            dataIndex: 'steeping_period_hi',
            width: 150,
        },
        {
            title: 'steeping_period_low',
            dataIndex: 'steeping_period_low',
            width: 180,
        },
        {
            title: 'vacuum_pressure',
            dataIndex: 'vacuum_pressure',
            width: 150,
        },
        {
            title: 'stir_PWM_hi',
            dataIndex: 'stir_PWM_hi',
            width: 100,
        },
        {
            title: 'stir_PWM_low',
            dataIndex: 'stir_PWM_low',
            width: 110,
        },
        {
            title: 'vacuum_hi',
            dataIndex: 'vacuum_hi',
            width: 100,
        },
        {
            title: 'vacuum_low',
            dataIndex: 'vacuum_low',
            width: 100,
        },
        {
            title: 'check_sum',
            dataIndex: 'check_sum',
            width: 100,
        },
    ];
    //#endregion
    function handleSearch(e) {
        if (sn === "" || sdate === "" || edate === "") {
            Message.warning("Please enter serial number and date!")
            return;
        }
        var endDate = new Date(edate)
        endDate.setDate(endDate.getDate() + 1);
        setSearch({
            edate: edate,
            sdate: sdate,
            sn: sn
        })
    }
    function handleExport(e) {
        const exportdobdy = response;
        exportExcel("ExportLog",exportdobdy)
    }
    //日期選擇邏輯 : 不超過今天
    function disabledDate(current) {
        return current > moment().endOf('day');
    }
    function handleChangeDate(dates, dateStrings) {
        setPreDateTime(dateStrings[0])
        setPostDateTime(dateStrings[1])
    }
    //#region table 上方 Row 相關
    const tableTopRow = (
        <>
            <Row style={{ marginBottom: '30px' }}>
                <Space>
                    <Input bordered={false} placeholder='input SN' onChange={(e) => setsn(e.target.value)} />
                    <RangePicker
                        placeholder={["Select start date", "Select end date"]}
                        disabledDate={disabledDate}
                        format="YYYY-MM-DD"
                        onChange={handleChangeDate}
                    />
                    <Button type="primary" onClick={handleSearch}>Search</Button>
                    <Button type="primary" disabled={(response===null || response.length=== 0)} onClick={handleExport}>Download</Button>
                </Space>
            </Row>

        </>
    )
    //#endregion
    return (
        <div>
            {tableTopRow}
            <Table
            rowKey="rowKey"
                loading={girdLoading}
                columns={columns}
                dataSource={response}
                showSizeChanger={false}
                pagination={{ pageSize: pageSize, size: "small" ,onChange:(page, pSize)=>setPageSize(pSize)}}
                scroll={{
                    y: '100vw',
                    x: '100vw',
                }}
            />

        </div>
    );
};

export default LogDataTable;

