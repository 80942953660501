import React, { useEffect, useState } from 'react';
import { Menu, Button, Badge, Col, Row, Dropdown } from 'antd';
import { useHistory, NavLink } from 'react-router-dom';
import { ActionContext } from '../../Context/ActionContext';
import { GetMenuItems } from '../../Settings/MenuSetting';
import { getUser } from '../../Utils/session';
import { Routes } from '../../routes';
import RAPIBREW from '../../image/RAPIBREW logo svg.svg';
import CoffeeCloudLogo from '../../image/coffee cloud svg.svg';
import { LockOutlined, LogoutOutlined, UserOutlined, QuestionCircleOutlined, BellOutlined } from '@ant-design/icons';
import { isRoot } from '../../Settings/Roles';

const LayoutHeader = ({ item }) => {
    const { signOut, openWelcome, openRetry, getCount, updateCount } = React.useContext(ActionContext);
    const user = getUser();
    const [count, setCount] = useState(0);
    let history = useHistory();
    useEffect(() => {
        updateCount()
    }, [updateCount])

    useEffect(() => {
        setCount(getCount())
    }, [getCount])

    // //登出
    // const handleLogout = () => {
    //     signOut();
    // }

    const margin2 = {
        style: {
            background: 'transparent',
            margin: '0 10px',
            color: 'white',
            border: 0,
            padding: '0px'
        }
    }

    const logoutButtonStyle = {
        background: 'none',
        border: 'none',
        padding: '0',
    }

    const menu = (
        // <Menu theme="dark">
        //     <Menu.Item key="profile-Name" icon={<UserOutlined />} disabled={true}>{user}</Menu.Item>
        //     <Menu.Item key="profile-Changepassword" icon={<LockOutlined />} >Change password<NavLink to={Routes.Setting.path}></NavLink></Menu.Item>
        //     <Menu.Item key="profile-Logout" icon={<LogoutOutlined />} onClick={handleLogout} >Logout</Menu.Item>
        // </Menu>
        // <Menu
        //     theme="dark"
        //     items={[
        //         {
        //             key: "profile-name", icon: <UserOutlined />, label: (<>{user}</>), disabled: true,
        //         },
        //         {
        //             key: "profile-change_password", icon: <LockOutlined />, label: (<NavLink to={Routes.Setting.path}>Change Password</NavLink>),
        //         },
        //         {
        //             key: "profile-logout", icon: <LogoutOutlined />, label: (<button style={logoutButtonStyle} onClick={signOut}>Logout</button>),
        //         },
        //     ]}
        // />
        {
            "theme": "dark",
            "items": [
                {
                    key: "profile-name", icon: <UserOutlined />, label: (<>{user}</>), disabled: true,
                },
                {
                    key: "profile-change_password", icon: <LockOutlined />, label: (<NavLink to={Routes.Setting.path}>Change Password</NavLink>),
                },
                {
                    key: "profile-logout", icon: <LogoutOutlined />, label: (<button style={logoutButtonStyle} onClick={signOut}>Logout</button>),
                },
            ],
        }
    );
    const leftCol = {
        xs: 0,
        sm: 0,
        md: 0,
        lg: 8,
        xl: 5,
        style: { textAlign: 'left' }
    }
    const centerCol = {
        xs: 12,
        sm: 12,
        md: 12,
        lg: isRoot() ? 8 : 10,
        xl: isRoot() ? 14 : 15,
        style: { textAlign: 'center' }
    }
    const rightCol = {
        xs: 12,
        sm: 12,
        md: 12,
        lg: isRoot() ? 8 : 6,
        xl: isRoot() ? 5 : 3,
        style: { textAlign: 'right' }
    }
    const menuMargin = {
        style: {
            margin: isRoot() ? '0 5px' : '0 30px',
        }
    }

    return (
        <Row justify='space-between'>
            <Col {...leftCol}>
                <span className="logo" onClick={() => history.push(GetMenuItems()[0].HeaterItemPath)}>
                    <img style={{ width: 120, verticalAlign: 'middle' }} src={RAPIBREW} alt="RAPIBREW" />
                    <img style={{ width: 120, verticalAlign: 'middle' }} src={CoffeeCloudLogo} alt="CoffeeCloudLogo" />
                </span>

            </Col>
            <Col {...centerCol}>
                {/* <Menu
                    mode="horizontal"
                    theme="dark"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    defaultSelectedKeys={item}
                >
                    {GetMenuItems().map(i => {
                        return (
                            i.display ?
                            <Menu.Item key={i.HeaterItem} id={`MenuItem-${i.HeaterItem}`} style={{ margin: (isRoot() ? '0 5px' : '0 30px') }}>
                                {i.HeaterItem}
                                <NavLink to={i.HeaterItemPath}></NavLink>
                            </Menu.Item>:null
                        );
                    })}
                </Menu> */}
                <Menu
                    mode="horizontal"
                    theme="dark"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    defaultSelectedKeys={item}
                    items={
                        GetMenuItems().map(i => {
                            return (
                                i.display ?
                                    {key: i.HeaterItem, style: menuMargin, label: (<NavLink to={i.HeaterItemPath}>{i.HeaterItem}</NavLink>)}
                                    :
                                    null
                            );
                        })
                    }
                />
            </Col>
            <Col {...rightCol}>
                {/* 小鈴鐺 */}
                <Badge size="small" offset={[-15, 20]} count={count} >
                    <Button {...margin2} icon={<BellOutlined />} onClick={() => openRetry(true)} />
                </Badge>
                {/* 資訊 */}
                <Button {...margin2} icon={<QuestionCircleOutlined />} onClick={() => openWelcome(true)} />
                {/* 使用者Icon */}
                <Dropdown menu={menu} placement="bottom" arrow>
                    <Button {...margin2} icon={<UserOutlined />} />
                </Dropdown>
                <Button {...margin2} shape="round" style={{ xs: 0, sm: 0, display: (isRoot() ? '' : 'none'), padding: '0 5px', color: "#453539", borderRadius: '8px', background: '#C1B7AD 0% 0% no-repeat padding-box', fontSize: '10px', }}>Original Supplier</Button>
            </Col>
        </Row>
    )
}

export default LayoutHeader;
