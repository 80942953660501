import React from 'react';
import { Input, Form } from 'antd';


export const PasswordInput = (props) => {
    return (
        <Form.Item
            name={props.name}
            label={props.label}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: 'Please enter password!',
                },
            ]}

        >
            <Input.Password />
        </Form.Item>
    );
}

export const NewPasswordInput = (props) => {
    return (
        <Form.Item
            name={props.name}
            label={props.label}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: props.label,
                },
                {
                    message: 'Please enter 8 or more characters, and at least one letter and on number!',
                    //eslint-disable-next-line
                    pattern: new RegExp('(?:[!@#$%^&*()_+-=])[A-Za-z!@#$%^&*()_+-=]{7,}|(?:[!@#$%^&*()_+-=])[A-Za-z\\d][A-Za-z!@#$%^&*()_+-=]{7,}|^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d][A-Za-z!@#$%^&*()_+-=]{7,}$|([!@#$%^&*()_+-=])')
                },
            ]}

        >
            <Input.Password />
        </Form.Item>
    );
}


export const ConfirmPasswordInput = (props) => {
    return (
        <Form.Item
            name={props.name}
            label={props.label}
            hasFeedback
            dependencies={[props.checkname]}
            rules={[
                {
                    required: true,
                    message: 'Please confirm your new password!',
                },
                ({ getFieldValue }) => ({
                    validator(rule, value) {
                        if (!value || getFieldValue(props.checkname) === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match!');
                    },
                }),
            ]}
        >
            <Input.Password />
        </Form.Item>
    );
}

